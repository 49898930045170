import React, { useEffect, useState } from 'react'
import { getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import {
  createReservation,
  getFilterProjects,
  getFilterUnits,
  getProveedores,
  getUsersByBroker,
} from 'src/api'
import { Link } from 'react-router-dom'
import {
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CRow,
} from '@coreui/react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { UI } from 'src/components'

const initialItems = {
  client: null,
  inmobiliaria: null,
  project: null,
  unit: null,
}

const cachedInmobiliarias = localStorage.getItem('filterInmobiliarias')

const initialItemsToSelect = {
  clients: [],
  inmobiliarias: cachedInmobiliarias ? JSON.parse(cachedInmobiliarias) : [],
  projects: [],
  units: [],
}

const ModalReservation = ({ showModal, setShowModal, setReservationCreated, addToast }) => {
  const userEmail = getUserEmail()

  const [loading, setLoading] = useState(true)

  const [itemsSelected, setItemsSelected] = useState(initialItems)
  const [itemsToSelect, setItemsToSelect] = useState(initialItemsToSelect)
  const [stepReservation, setStepReservation] = useState(1)

  const handleSelectItem = (selectedItem, key) => {
    switch (key) {
      case 'inmobiliaria':
        setItemsSelected({ ...itemsSelected, project: null, unit: null, [key]: selectedItem })
        break
      case 'project':
        setItemsSelected({ ...itemsSelected, unit: null, [key]: selectedItem })
        break
      default:
        setItemsSelected({ ...itemsSelected, [key]: selectedItem })
        break
    }
  }

  const handleHideModal = () => {
    setShowModal(false)
    setItemsSelected(initialItems)
    setStepReservation(1)
  }

  const handleConfirmReservation = () => {
    const unit = itemsSelected.unit !== null
    const client = itemsSelected.client !== null
    if (unit && client) {
      setStepReservation(2)
    }
  }

  const fetchClientsAndInmobiliarias = async () => {
    setLoading(true)
    try {
      const params = {
        email: userEmail,
        page_size: 'max',
        page_number: 1,
      }
      const responseClients = await getUsersByBroker(params)
      const parsedClients = responseClients?.data?.results.map((client) => {
        return {
          value: client.uuid,
          data: client,
          label: `${String(client.first_name).toUpperCase()} ${String(
            client.last_name,
          ).toUpperCase()} (${client.identity_document})`,
          name: 'client',
        }
      })

      if (cachedInmobiliarias) {
        setItemsToSelect({
          ...itemsToSelect,
          clients: parsedClients,
        })

        setLoading(false)
        return
      }

      const responseInmobiliarias = await getProveedores()
      const parsedInmobiliarias = responseInmobiliarias?.data?.results.map((inmobiliaria) => {
        return {
          value: inmobiliaria.value,
          data: inmobiliaria,
          label: inmobiliaria.text,
          name: 'inmobiliaria',
        }
      })

      localStorage.setItem('filterInmobiliarias', JSON.stringify(parsedInmobiliarias))
      setItemsToSelect({
        ...itemsToSelect,
        clients: parsedClients,
        inmobiliarias: parsedInmobiliarias,
      })
    } catch (err) {
      const error = err.response?.data?.detail?.error?.message || err.response?.data?.detail?.error
      addToast({
        message: error ?? 'No se encontraron clientes o inmobiliarias',
        color: 'danger',
      })
    }

    setLoading(false)
  }

  const fetchProjects = async () => {
    setLoading(true)
    try {
      // SELECCION MULTIPLE
      // const allProjects = []
      // await Promise.all(
      //   itemsSelected.inmobiliaria.map(async (element) => {
      //     const response = await getFilterProjects(element.value)
      //     const parsedProjects = response?.data?.response?.map((project) => {
      //       return {
      //         value: project.id,
      //         label: project.description,
      //         name: 'project',
      //       }
      //     })
      //     allProjects.push(...parsedProjects)
      //   }),
      // )
      // setItemsToSelect({ ...itemsToSelect, projects: allProjects })

      // SELECCION SIMPLE
      const response = await getFilterProjects(itemsSelected.inmobiliaria?.value)
      const parsedProjects = response?.data?.response?.map((project) => {
        return {
          value: project.id,
          data: project,
          label: project.description,
          name: 'project',
        }
      })

      setItemsToSelect({ ...itemsToSelect, projects: parsedProjects })
    } catch (err) {
      const error = err.response?.data?.detail?.error?.message || err.response?.data?.detail?.error
      addToast({
        message: error ?? 'Error al buscar las proyectos',
        color: 'danger',
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchUnits = async () => {
    setLoading(true)
    try {
      // SELECCION MULTIPLE
      // const allUnits = []
      // await Promise.all(
      //   itemsSelected.project.map(async (element) => {
      //     const response = await getFilterUnits(element.value)
      //     const parsedUnits = response?.data?.stock?.map((unit) => {
      //       return {
      //         value: unit.id,
      //         label: `${unit.tipologia.replaceAll(' ', '')} - UF ${
      //           unit.valor_con_descuento || unit.valor_lista
      //         }`,
      //         name: 'unit',
      //       }
      //     })
      //     allUnits.push(...parsedUnits)
      //   }),
      // )
      // setItemsToSelect({ ...itemsToSelect, units: allUnits })

      // SELECCION SIMPLE
      const response = await getFilterUnits(itemsSelected.project?.value)
      const parsedUnits = response?.data?.stock?.map((unit) => {
        return {
          value: unit.numero,
          data: unit,
          label: `${unit.numero} - ${String(unit.tipologia).replaceAll(' ', '')}`,
          name: 'unit',
        }
      })

      setItemsToSelect({ ...itemsToSelect, units: parsedUnits })
    } catch (err) {
      const error = err.response?.data?.detail?.error?.message || err.response?.data?.detail?.error
      addToast({
        message: error ?? 'Error al buscar las proyectos',
        color: 'danger',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleReserve = () => {
    const client = itemsSelected.client?.data
    const inmobiliaria = itemsSelected.inmobiliaria?.data
    const project = itemsSelected.project?.data
    const units = itemsSelected.unit

    const body = {
      governmentId: client.identity_document?.replaceAll('.', ''),
      projectId: project.id,
      reservationType: 'real_estate',
      clientEmail: client.email,
      clientName: client.first_name + ' ' + client.last_name,
      realEstateName: inmobiliaria.text,
      unitsList: units.map((unit) => {
        return { unitId: String(unit.data.numero) }
      }),
      createdBy: 'broker',
      currency: 'clp',
      pacMandateId: '',
      defaultAgent: '',
      brokerEmail: userEmail,
      linkPayment: '',
      sendMail: false,
    }

    setLoading(true)

    createReservation(body)
      .then((res) => {
        setReservationCreated(true)
        handleHideModal()
        setLoading(false)
        addToast({
          message: 'Pre reserva creada correctamente.',
          color: 'success',
        })
      })
      .catch((err) => {
        handlerSendErrorNotification(err)
        const error = err.response?.data?.detail?.message
        addToast({
          message: error ?? 'Error al crear la pre reserva.',
          color: 'danger',
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchClientsAndInmobiliarias()
  }, [])

  useEffect(() => {
    if (itemsSelected.inmobiliaria) {
      fetchProjects()
    }
  }, [itemsSelected.inmobiliaria])

  useEffect(() => {
    if (itemsSelected.project) {
      fetchUnits()
    }
  }, [itemsSelected.project])

  return (
    <CModal
      backdrop="static"
      visible={showModal}
      onClose={() => handleHideModal()}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Pre reservar unidad</CModalTitle>
      </CModalHeader>
      {stepReservation === 1 ? (
        <CModalBody>
          <p>
            Seleccione el cliente que desea hacer la pre reserva y las unidades que seran pre
            reservadas. Si el cliente no esta registrado, por favor{' '}
            <Link to="/agregar-cliente">registrelo</Link>.
          </p>
          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <Select
                loading={loading}
                placeholder="Cliente"
                options={itemsToSelect.clients}
                menuPortalTarget={document.body}
                value={itemsSelected.client}
                closeMenuOnSelect
                components={makeAnimated()}
                isLoading={loading}
                onChange={(selectedItem) => handleSelectItem(selectedItem, 'client')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </CCol>
          </CRow>

          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <Select
                loading={loading}
                placeholder="Inmobiliaria"
                options={itemsToSelect.inmobiliarias}
                menuPortalTarget={document.body}
                value={itemsSelected.inmobiliaria}
                // isMulti
                components={makeAnimated()}
                isLoading={loading}
                onChange={(selectedItem) => handleSelectItem(selectedItem, 'inmobiliaria')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                closeMenuOnSelect
              />
            </CCol>
          </CRow>

          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <Select
                loading={loading}
                placeholder="Proyecto"
                options={itemsToSelect.projects}
                menuPortalTarget={document.body}
                value={itemsSelected.project}
                // isMulti
                isDisabled={!itemsSelected.inmobiliaria}
                components={makeAnimated()}
                isLoading={loading}
                onChange={(selectedItem) => handleSelectItem(selectedItem, 'project')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                closeMenuOnSelect
              />
            </CCol>
          </CRow>

          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <Select
                loading={loading}
                placeholder="Unidad"
                options={itemsToSelect.units}
                menuPortalTarget={document.body}
                value={itemsSelected.unit}
                isMulti
                isDisabled={!itemsSelected.project}
                components={makeAnimated()}
                isLoading={loading}
                onChange={(selectedItem) => handleSelectItem(selectedItem, 'unit')}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                closeMenuOnSelect={false}
              />
            </CCol>
          </CRow>
        </CModalBody>
      ) : (
        <CModalBody className="d-flex flex-column">
          <p>¿Desea confirmar la pre reserva de las siguientes unidades?</p>
          <ul className="d-flex flex-column">
            {itemsSelected.unit.map((element) => (
              <li key={element.value} className="text-primary fw-semibold mb-1">
                {element.label}
              </li>
            ))}
          </ul>
          <p>
            Para el cliente{' '}
            <span className="text-primary fw-semibold">{itemsSelected.client?.label}</span>.
          </p>
          <p className="mb-0 mt-2 small">
            <span className="fw-semibold">El cliente tendrá 24hs para realizar el pago</span>
          </p>
          <p className="mb-0 mt-2 small">
            <span className="fw-semibold">Nota:</span> Si tu cliente va a pre reservar mas de dos
            unidades por primera vez recomendamos validar cuenta o hcer dos links de transferencia
            por dia
          </p>
          {loading && <UI.Loading />}
        </CModalBody>
      )}
      <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          <CButton
            onClick={() => {
              stepReservation === 1 ? handleConfirmReservation() : handleReserve()
            }}
            color="primary"
            disabled={stepReservation === 1 ? loading || !itemsSelected.unit : loading}
            className="text-white rounded-5"
            size="sm"
          >
            {stepReservation === 1 ? 'Pre reservar' : 'Confirmar la pre reserva'}
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  )
}

ModalReservation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setReservationCreated: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
}

export default ModalReservation
