import React, { useState } from 'react'
import { CCard, CCardBody, CCol } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import * as DatosPerfil from './DatosPerfil'
import * as DatosPerfilLYT from './DatosPerfilLYT'
import * as icon from '@coreui/icons'
import { UI } from 'src/components'

const InfoPerfil = ({ clientData, isLoading, id, client_type }) => {
  const [activeTab, setActiveTab] = useState(0)
  const tabs =
    client_type.toLowerCase() === 'lyt'
      ? [
          {
            title: 'Datos personales',
            icon: icon.cilUser,
            component: <DatosPerfilLYT.DatosPersonales id={id} clientData={clientData} />,
          },
          {
            title: 'Datos del proyecto',
            icon: icon.cilChartLine,
            component: <DatosPerfilLYT.DatosProyecto id={id} projectData={clientData} />,
          },
        ]
      : [
          {
            title: 'Datos personales',
            icon: icon.cilUser,
            component: <DatosPerfil.DatosPersonales id={id} clientData={clientData} />,
          },
          {
            title: 'Datos financieros',
            icon: icon.cilCash,
            component: <DatosPerfil.DatosFinancieros id={id} clientData={clientData} />,
          },
          {
            title: 'Datos crediticios',
            icon: icon.cilCreditCard,
            component: <DatosPerfil.DatosCrediticios id={id} clientData={clientData} />,
          },
          {
            title: 'Datos objetivos',
            icon: icon.cilChartLine,
            component: <DatosPerfil.DatosObjetivos id={id} clientData={clientData} />,
          },
          process.env.REACT_APP_CLIENT_FOLDER_FLAG !== 'false' && {
            title: 'Documentos',
            icon: icon.cilBook,
            component: <DatosPerfil.Documentos id={id} clientData={clientData} />,
          },
        ]

  const cardStyle = {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderTop: 'none',
  }
  return (
    <CCard className="mb-2">
      <CCardBody>
        <CCol>
          {/* Pestañas */}
          <ul className="nav nav-tabs">
            {tabs.map((item) => {
              const isActive =
                activeTab === tabs.indexOf(item) ? 'active text-primary' : 'text-muted'
              return (
                <li key={item.title} className="nav-item">
                  <h3
                    className={`nav-link ${isActive}`}
                    onClick={() => setActiveTab(tabs.indexOf(item))}
                    style={{ cursor: 'pointer' }}
                  >
                    <span>
                      <CIcon icon={item.icon} className={`mx-1 ${isActive}`} />
                    </span>
                    {item.title}
                  </h3>
                </li>
              )
            })}
          </ul>
          {/* Pestaña Activa */}
          <CCard className="mb-2" style={cardStyle}>
            <CCardBody style={{ display: 'flex', flexDirection: 'column' }}>
              {tabs[activeTab].component}
            </CCardBody>
            {isLoading && <UI.Loading />}
          </CCard>
        </CCol>
      </CCardBody>
    </CCard>
  )
}

InfoPerfil.propTypes = {
  clientData: PropTypes.object,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  client_type: PropTypes.string,
}

export default InfoPerfil
