import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Loading = ({ background = true }) => {
  return (
    <React.Fragment>
      <div
        className="loading-container"
        style={!background ? { backgroundColor: 'transparent' } : {}}
      >
        <span className="loader"></span>
      </div>
    </React.Fragment>
  )
}

Loading.propTypes = {
  background: PropTypes.bool,
}

export default Loading
