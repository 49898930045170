import React, { useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { useParams } from 'react-router-dom'
import { getPDF } from 'src/api'
import { downloadFile } from 'src/helpers'
import PropTypes from 'prop-types'

const HeaderPerfil = ({ setIsLoading, clientData }) => {
  const [disablePDF, setDisablePDF] = useState(false)

  const { id } = useParams()

  const handleClickPDF = async () => {
    handleLoading(true)
    const PDF = await getPDF({ uuid: id })
    const pdfName = clientData
      ? `Perfil del cliente ${clientData.first_name} ${clientData.last_name}`
      : 'Perfil del cliente'
    downloadFile(PDF.data, pdfName, 'pdf')
    handleLoading(false)
  }

  const handleLoading = (status) => {
    setIsLoading(status)
    setDisablePDF(status)
  }

  return (
    <CCard className="mb-2">
      <CCardBody>
        <CRow className="d-flex">
          <CCol>
            <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
              <div className="d-lg-block">
                <h4 className="card-title mb-0">Perfil del cliente</h4>
                <p className="small text-medium-emphasis m-0">Detalles sobre la persona</p>
              </div>
            </div>
          </CCol>
          <CCol className="d-flex align-items-end justify-content-end">
            <CButton
              disabled={disablePDF}
              onClick={handleClickPDF}
              className="text-white btn btn-primary btn-md text-white rounded-5"
            >
              Ver PDF
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

HeaderPerfil.propTypes = {
  clientData: PropTypes.object,
  setIsLoading: PropTypes.func.isRequired,
}

export default HeaderPerfil
