import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import { formatInputPrice, parseReservationStatus } from 'src/helpers'

const ReservationDetail = ({ invoiceDetail }) => {
  return (
    <div>
      <div className="mb-4 d-flex flex-column gap-2">
        {invoiceDetail?.map((item, index) => {
          return item.label === 'Reserva' ? (
            <h4 className="mb-4" key={index}>
              Pre reserva -{' '}
              <span className="text-primary fw-bold" style={{ fontSize: '1.5rem' }}>
                {item.value}
              </span>
            </h4>
          ) : item.label === 'Monto' ? (
            <h5 key={index} className="mt-2">
              Monto: <span className="text-primary">${formatInputPrice(item.value)}</span>
            </h5>
          ) : (
            <div key={index} className="d-flex gap-2">
              <p className="m-0 mb-1 fw-semibold">{item.label}: </p>
              <p className="m-0">
                {item.label === 'Estado'
                  ? parseReservationStatus(item.value).toLowerCase()
                  : item.value}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ReservationDetail.propTypes = {
  invoiceDetail: PropTypes.array.isRequired,
}

export default ReservationDetail
