import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { CBadge, CButton, CCard, CCardBody, CCol, CContainer, CRow, CTooltip } from '@coreui/react'
import { capitalizeCombo, formatRoundUF } from 'src/helpers'
import { UI } from 'src/components'
import { useSelector } from 'react-redux'
import ContentEditable from 'react-contenteditable'
import './styles.scss'

const VCHeader = ({
  project,
  minStock,
  activeTab,
  setEditing,
  editing,
  setShowConfirmAlert,
  setEditableChanges,
  editableChanges,
}) => {
  const roles = useSelector((state) => state.roles)
  const availableEdit =
    (roles.includes('ADMIN') || roles.includes('OPS') || roles.includes('EJECUTIVO DE NEGOCIO')) &&
    project.project_type !== 'BUYDEPA'

  const navigate = useNavigate()

  const handleChange = (e, title, value) => {
    if (String(value) !== String(e.target.value)) {
      setEditableChanges({ ...editableChanges, [title]: e.target.value })
    }
  }

  const projectTypeRoutes = {
    SEMINUEVOS: '/seminuevos',
    EEUU: '/eeuu',
    VPSN: '/venta-privada',
  }

  const defaultRoute = '/catalogo'

  const Badges = (obj) => {
    const spacing = { margin: '.3rem' }
    return (
      <Fragment>
        {obj.isOpportunity && (
          <div className="position-absolute top-0 start-0" style={spacing}>
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="success"
            >
              <span
                data-icon="solar:ranking-bold-duotone"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
              TOP
            </CBadge>
          </div>
        )}
        {obj.isHot && (
          <div className="position-absolute top-0 end-0" style={spacing}>
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="danger"
            >
              <span
                data-icon="mdi:fire"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
              Destacado
            </CBadge>
          </div>
        )}
        <div className="position-absolute bottom-0 start-0" style={spacing}>
          <CTooltip content="Tipo de Entrega">
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="info"
            >
              <span
                data-icon="tabler:calendar-bolt"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
              {obj.deliveryType}
            </CBadge>
          </CTooltip>
        </div>
        {obj.deliveryDate && obj.deliveryDate !== 'INMEDIATA' && (
          <div className="position-absolute bottom-0 end-0" style={spacing}>
            {editing ? (
              <CBadge
                className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
                color="info"
              >
                <input
                  type="date"
                  className="estimated-date"
                  defaultValue={obj.deliveryDate}
                  onChange={(e) => handleChange(e, 'fecha_entrega_estimada', obj.deliveryDate)}
                />
              </CBadge>
            ) : (
              <CTooltip content="Fecha de entrega estimada">
                <CBadge
                  className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
                  color="info"
                >
                  <span
                    data-icon="tabler:calendar-time"
                    className="iconify"
                    style={{ width: '16px', height: '16px' }}
                  ></span>
                  {obj.deliveryDate}
                </CBadge>
              </CTooltip>
            )}
          </div>
        )}
      </Fragment>
    )
  }
  return (
    <Fragment>
      <CCard className="mb-2">
        <CCardBody>
          <CContainer className="d-flex flex-row align-items-center">
            <div className="d-flex flex-row align-items-center">
              <CTooltip content="Volver a Catálogo">
                <div>
                  <UI.CircleButton
                    onClick={() => {
                      const route = projectTypeRoutes[project.project_type] || defaultRoute
                      navigate(route)
                    }}
                    buttonClassName="me-2"
                    icon="icon-park-outline:return"
                    height="36px"
                    width="36px"
                  />
                </div>
              </CTooltip>
              <div>
                <h3 className="fs-4 m-0 text-black">
                  {capitalizeCombo(`${project.name} - ${project.supplier}`)}
                </h3>
                <h2 className="fs-6 m-0 text-muted">
                  {project.city &&
                    project.state &&
                    capitalizeCombo(`${project.city} - ${project.state}`)}
                </h2>
              </div>
            </div>
            {minStock && (
              <div className="ms-auto d-none d-md-block">
                <div className="d-flex flex-row align-items-center px-2">
                  <CCol className="text-center" style={{ marginRight: '.4rem' }}>
                    {project?.project_type.toLowerCase() !== 'seminuevos' && (
                      <div className="text-bold" style={{ fontSize: '.6rem' }}>
                        Desde
                      </div>
                    )}
                    <div
                      className="text-lg fs-5 fw-bold text-primary"
                      style={{ marginTop: '-6px', marginBottom: '-4px' }}
                    >
                      {`${project.currency}`}
                    </div>
                  </CCol>
                  <h3 className="fs-3 my-auto text-black">{`${formatRoundUF(
                    minStock?.valorLista,
                  )}`}</h3>
                </div>
              </div>
            )}
          </CContainer>
        </CCardBody>
      </CCard>
      <CCard className="mb-2">
        <CCardBody>
          <CContainer>
            <CRow>
              <CCol md="12" lg="6">
                <div className="position-relative">
                  <img
                    src={project.image}
                    width="100%"
                    height={300}
                    className="rounded"
                    alt="Imágen proyecto inmobiliario"
                    style={{ objectFit: 'cover' }}
                  />
                  {Badges(project)}
                </div>
              </CCol>
              <CCol
                className="text-black d-flex flex-column justify-content-between"
                md="12"
                lg="6"
              >
                <div>
                  {project.description && (
                    <>
                      <h2 className="fs-4">Descripción</h2>
                      {editing ? (
                        <ContentEditable
                          className="content-editable"
                          html={
                            editableChanges['descripcion'] === ''
                              ? ''
                              : editableChanges['descripcion']
                              ? editableChanges['descripcion']
                              : String(project.description)
                          }
                          disabled={false}
                          onChange={(e) => handleChange(e, 'descripcion', project.description)}
                        ></ContentEditable>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: project.description }}></div>
                      )}
                    </>
                  )}
                </div>

                {activeTab === 0 && availableEdit && (
                  <div className="align-self-end">
                    {editing ? (
                      <div className="d-flex gap-2">
                        <CButton
                          color="danger"
                          onClick={() => setEditing(false)}
                          className="text-white px-4"
                        >
                          Cancelar
                        </CButton>
                        <CButton
                          color="success"
                          onClick={() => setShowConfirmAlert(true)}
                          className="text-white px-4"
                        >
                          Confirmar cambios
                        </CButton>
                      </div>
                    ) : (
                      <CButton
                        onClick={() => setEditing(true)}
                        className="text-white align-self-end px-4"
                      >
                        Editar
                      </CButton>
                    )}
                  </div>
                )}
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
    </Fragment>
  )
}

VCHeader.propTypes = {
  project: PropTypes.object,
  minStock: PropTypes.any,
  activeTab: PropTypes.number.isRequired,
  setEditing: PropTypes.func.isRequired,
  setShowConfirmAlert: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  response: PropTypes.object,
  editableChanges: PropTypes.object,
  setEditableChanges: PropTypes.func.isRequired,
}

export default VCHeader
