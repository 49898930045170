import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store'
import 'react-app-polyfill/stable'
import 'core-js'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ErrorBoundary } from 'react-error-boundary'
import Page500 from 'src/views/pages/page500'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

const ErrorFallback = () => {
  return <Page500 />
}

createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </Provider>
  </GoogleOAuthProvider>,
)
