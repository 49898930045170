import { cilCloudDownload, cilCloudUpload, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { UI } from 'src/components'
import { deleteDoc, downloadDoc, getDocs, postDocs } from 'src/api'
import useToast from 'src/hooks/useToast'
import { downloadFile, handlerSendErrorNotification } from 'src/helpers'
import './styles.scss'

const allowedFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-zip-compressed',
  'application/x-rar-compressed',
  'image/jpeg',
  'image/jpg',
  'image/png',
]

const Documentos = ({ clientData, id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [documents, setDocuments] = useState([])

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  const { Toaster, addToast } = useToast()

  const fileInputRef = useRef(null)

  const handleDownloadDoc = async (route, docName, docType) => {
    setLoading(true)
    try {
      const params = {
        url: route,
      }
      const data = (await downloadDoc(params)).data
      downloadFile(data, docName, docType)
      setLoading(false)
    } catch (error) {
      console.log(error)
      addToast({
        color: 'danger',
        message: error?.response?.data?.detail || 'Error al descargar el documento.',
      })
      setLoading(false)
    }
  }

  const handleFileUpload = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (e) => {
    setLoading(true)
    const formData = new FormData()

    for (const file of e?.target?.files) {
      if (!allowedFileTypes.includes(file.type)) {
        addToast({ message: `Tipo de archivo no permitido ${file.type}`, color: 'danger' })
      } else {
        formData.append('archivos', file)
      }
    }

    if (formData.getAll('archivos').length > 0) {
      postDocs(id, formData)
        .then(async (res) => {
          await fetchDocuments()
          addToast({ message: 'Archivos cargados correctamente', color: 'success' })
          setLoading(false)
        })
        .catch((error) => {
          handlerSendErrorNotification(error)
          addToast({ message: 'Error al cargar los archivos', color: 'danger' })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const handleDeleteDoc = async (documentUrl) => {
    setIsLoading(true)
    const params = {
      url: documentUrl,
    }
    await deleteDoc(params)
    await fetchDocuments()
    setModalData({})
    setShowModal(false)
    setIsLoading(false)
  }

  const handleClickDelete = (documentUrl) => {
    setShowModal(true)
    setModalData({
      visible: true,
      title: 'Eliminar documento',
      message: '¿Estás seguro de que deseas eliminar este documento?',
      confirmText: 'Eliminar',
      confirmColor: 'danger',
      cancelText: 'Cancelar',
      cancelColor: 'primary',
      onClose: () => {
        setModalData({})
        setShowModal(false)
      },
      isLoading: isLoading,
      finalAction: () => handleDeleteDoc(documentUrl),
    })
  }

  const fetchDocuments = async () => {
    try {
      setLoading(true)
      const params = {
        uuid: id,
        page_size: 5,
        page_number: page,
      }
      const response = await getDocs(params)

      setDocuments(response.data.documents)
      setTotalPages(response.data.total_pages)
      setLoading(false)
    } catch (error) {
      console.error('Error al obtener los documentos:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [page])

  return (
    <div>
      {Toaster}
      <div className="d-flex justify-content-between mb-4">
        <h4>
          Documentación del cliente {clientData?.first_name} {clientData?.last_name}
        </h4>
        <CButton
          className="text-white ms-3 px-3 btn btn-primary btn-sm text-white rounded-5"
          onClick={handleFileUpload}
          disabled={loading}
        >
          Cargar <CIcon className="ms-1" icon={cilCloudUpload} />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            multiple
          />
        </CButton>
      </div>

      <CTable striped responsive className="mb-4">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell className="medium w-80" style={{ width: '75%' }}>
              Nombre del Archivo
            </CTableHeaderCell>
            <CTableHeaderCell className="medium w-10 text-end">Fecha</CTableHeaderCell>
            <CTableHeaderCell className="medium w-10 text-end">Acciones</CTableHeaderCell>
          </CTableRow>
        </CTableHead>

        {loading && <UI.Loading />}

        <CTableBody>
          {documents?.map((item, index) => {
            let icon
            switch (item.document_type) {
              case 'pdf':
                icon = (
                  <span
                    data-icon="mdi:file-pdf-box"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'zip':
                icon = (
                  <span
                    data-icon="mdi:folder-zip"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'docx':
                icon = (
                  <span
                    data-icon="mdi:file-document"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'jpeg':
                icon = (
                  <span
                    data-icon="material-symbols:image"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'jpg':
                icon = (
                  <span
                    data-icon="material-symbols:image"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'png':
                icon = (
                  <span
                    data-icon="material-symbols:image"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              case 'xlsx':
                icon = (
                  <span
                    data-icon="icon-park-outline:excel"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
              default:
                icon = (
                  <span
                    data-icon="mdi:archive"
                    className="iconify"
                    style={{ width: '20px', height: '20px', marginRight: '.25rem' }}
                  ></span>
                )
                break
            }

            return (
              <CTableRow key={index} style={{ height: '50px' }}>
                <CTableHeaderCell className="w-70">
                  {icon}
                  {item.document_name}
                </CTableHeaderCell>
                <CTableDataCell className="small w-10 text-end">{item.update_date}</CTableDataCell>
                <CTableDataCell className="small w-10 text-end">
                  <CButton
                    onClick={() =>
                      handleDownloadDoc(item.document_url, item.document_name, item.document_type)
                    }
                    className="download-doc me-4"
                    disabled={loading}
                  >
                    <CIcon icon={cilCloudDownload} />
                  </CButton>
                  <CButton
                    onClick={() => handleClickDelete(item.document_url)}
                    className="delete-doc"
                    disabled={loading}
                  >
                    <CIcon icon={cilTrash} />
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            )
          })}
        </CTableBody>
      </CTable>

      <UI.ConfirmPopup
        visible={showModal}
        title={modalData.title}
        message={modalData.message}
        confirmText={modalData.confirmText}
        confirmColor={modalData.confirmColor}
        cancelText={modalData.cancelText}
        cancelColor={modalData.cancelColor}
        onClose={modalData.onClose}
        finalAction={modalData.finalAction}
        isLoading={isLoading}
      />

      <UI.Pagination currentPage={page} totalPages={totalPages} onPageChange={setPage} />
    </div>
  )
}

Documentos.propTypes = {
  clientData: PropTypes.object,
  id: PropTypes.string,
}

export default Documentos
