import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'

const DatosPersonales = ({ personalData, setPersonalData, setShowFinalAlert }) => {
  const datosPersonales = [
    {
      label: 'Email Broker',
      value: '',
      type: 'text',
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Email inválido',
    },
    {
      label: 'Nombres del cliente',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Nombre inválido',
    },
    {
      label: 'Apellidos del cliente',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/,
      errorMessage: 'Apellido inválida',
      legalAge: true,
    },
    {
      label: 'Email del cliente',
      value: '',
      type: 'text',
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: 'Email inválido',
    },
    {
      label: 'Teléfono del cliente',
      value: '',
      type: 'phone',
      pattern: /^(\d{10,18})$/, // De 10 a 18 numeros
      errorMessage: 'Debe ingresar un número telefónico válido (EJ: +XX XXX XXX XXX)',
    },
    {
      label: 'Adjuntar documento PDF de factura de compra de unidad (opcional)',
      value: '',
      type: 'file',
      accept: '.pdf', // Tipo de archivo permitido PDF
      errorMessage: 'Documento inválido',
    },
    {
      label: 'Cantidad de unidades',
      value: '',
      type: 'number',
      pattern: /^[1-9][0-9]*$/, // Expresión regular para números enteros positivos
      errorMessage: 'Cantidad inválida',
    },
    {
      label: 'Cantidad de UF',
      value: '',
      type: 'text', // Cambiado a 'text' para permitir decimales
      pattern: /^[1-9]\d*(\.\d+)?$/, // Expresión regular para números positivos con decimales
      errorMessage: 'Cantidad inválida',
    },
  ]

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: personalData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()
      setPersonalData(formValues)
    }
  }

  const handleClickAceptar = () => {
    console.log(datosPersonales)
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()

      setPersonalData(formValues)

      // Muestra la alerta final antes de crear el cliente
      setShowFinalAlert(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {datosPersonales.map((item, index) => renderField(item, index, control, true, errors))}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton
            type="button"
            onClick={() => {
              handleClickAceptar()
            }}
            color="success"
            className="text-white px-4"
          >
            Enviar solicitud
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosPersonales.propTypes = {
  personalData: PropTypes.object.isRequired,
  setPersonalData: PropTypes.func.isRequired,
  setShowFinalAlert: PropTypes.func.isRequired,
}

export default DatosPersonales
