import React from 'react'
import { CCol, CContainer } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { capitalizeCombo } from 'src/helpers'
import PropTypes from 'prop-types'
import * as icon from '@coreui/icons'

const User = ({ user }) => {
  const whatsAppLink = 'https://api.whatsapp.com/send?phone='
  const handleMailLink = (mail) => {
    const subject = 'Vista Comercial'
    const mailLink = `mailto:${mail}?subject=${subject}`
    return mailLink
  }
  return (
    <CContainer className="fs-6 text-primary fw-semibold mb-3">
      <CCol
        className="mb-3 text-black"
        style={{ borderBottom: '1px solid rgba(44, 56, 74, 0.22)' }}
      >
        <h3 className="fs-5">
          <CIcon icon={icon.cilPin} className="text-primary mx-1" size="lg" />
          <span className="text-black">Contacto</span>
        </h3>
      </CCol>
      <div className="d-flex flex-column gap-3">
        <div>
          <h4 className="fs-6 m-0">Nombre</h4>
          <p className="m-0 text-black fw-normal">{capitalizeCombo(user.name)}</p>
        </div>
        <div>
          <h4 className="fs-6 m-0">Teléfono</h4>
          <p className="m-0 text-black fw-normal">
            <span
              className="iconify"
              data-icon="logos:whatsapp-icon"
              style={{ width: '20px', height: '20px' }}
            ></span>
            <a
              href={`${whatsAppLink}${user.phone}`}
              target="_blank"
              rel="noreferrer"
              className="ms-1"
            >
              {user.phone}
            </a>
          </p>
        </div>
        <div>
          <h4 className="fs-6 m-0">Correo</h4>
          <p className="m-0 text-black fw-normal">
            <span
              className="iconify"
              data-icon="logos:google-gmail"
              style={{ width: '20px', height: '20px' }}
            ></span>
            <a href={handleMailLink(user.mail)} target="_blank" rel="noreferrer" className="ms-1">
              {user.mail}
            </a>
          </p>
        </div>
      </div>
      {(user.namePost || user.phonePost || user.mailPost) && (
        <CCol
          className="mb-3 text-black mt-4"
          style={{ borderBottom: '1px solid rgba(44, 56, 74, 0.22)' }}
        >
          <h3 className="fs-5">
            <CIcon icon={icon.cilPin} className="text-primary mx-1" size="lg" />
            <span className="text-black">Contacto Post Venta</span>
          </h3>
        </CCol>
      )}

      <div className="d-flex flex-column gap-3">
        {user.namePost && (
          <div>
            <h4 className="fs-6 m-0">Nombre</h4>
            <p className="m-0 text-black fw-normal">{capitalizeCombo(user.namePost)}</p>
          </div>
        )}
        {user.phonePost && (
          <div>
            <h4 className="fs-6 m-0">Teléfono</h4>
            <p className="m-0 text-black fw-normal">
              <span
                className="iconify"
                data-icon="logos:whatsapp-icon"
                style={{ width: '20px', height: '20px' }}
              ></span>
              <a
                href={`${whatsAppLink}${user.phonePost}`}
                target="_blank"
                rel="noreferrer"
                className="ms-1"
              >
                {user.phonePost}
              </a>
            </p>
          </div>
        )}
        {user.mailPost && (
          <div>
            <h4 className="fs-6 m-0">Correo</h4>
            <p className="m-0 text-black fw-normal">
              <span
                className="iconify"
                data-icon="logos:google-gmail"
                style={{ width: '20px', height: '20px' }}
              ></span>
              <a
                href={handleMailLink(user.mailPost)}
                target="_blank"
                rel="noreferrer"
                className="ms-1"
              >
                {user.mailPost}
              </a>
            </p>
          </div>
        )}
      </div>
    </CContainer>
  )
}

User.propTypes = {
  user: PropTypes.object,
}

export default User
