import React, { Fragment } from 'react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCarousel,
  CCarouselItem,
  CCol,
  CImage,
  CTooltip,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import * as icon from '@coreui/icons'
import { capitalizeCombo, formatRoundUF } from 'src/helpers'

const Card = ({
  id,
  title,
  name,
  state,
  city,
  images,
  capRate,
  // monthlyFee,
  priceFrom,
  deliveryType,
  deliveryDate,
  isOpportunity,
  // isHot,
}) => {
  const navigate = useNavigate()

  const Badges = () => {
    const spacing = { margin: '.3rem' }

    const formattedDeliveryDate =
      deliveryType.toLowerCase() === 'futura'
        ? Number(deliveryDate.split('/')[1]) > 6
          ? '2° semestre del ' + deliveryDate.split('/')[2]
          : '1° semestre del ' + deliveryDate.split('/')[2]
        : deliveryType.toLowerCase() === 'pronta'
        ? deliveryDate.split('/')[2]
        : deliveryDate

    return (
      <Fragment>
        {isOpportunity && (
          <div className="position-absolute top-0 start-0" style={spacing}>
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="success"
            >
              <span
                data-icon="solar:ranking-bold-duotone"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
              TOP
            </CBadge>
          </div>
        )}
        {/* {isHot && (
          <div className="position-absolute top-0 end-0" style={spacing}>
            <CBadge
              className="d-flex align-items-center rounded-5 p-1 gap-1 fw-semibold"
              color="danger"
            >
              <span
                data-icon="mdi:fire"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
            </CBadge>
          </div>
        )} */}
        <div className="position-absolute bottom-0 start-0" style={spacing}>
          <CTooltip content="Tipo de Entrega">
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="info"
            >
              <span
                data-icon="tabler:calendar-bolt"
                className="iconify"
                style={{ width: '16px', height: '16px' }}
              ></span>
              {deliveryType}
            </CBadge>
          </CTooltip>
        </div>
        {formattedDeliveryDate && (
          <div className="position-absolute bottom-0 end-0" style={spacing}>
            <CTooltip content="Fecha de entrega estimada">
              <CBadge
                className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
                color="info"
              >
                <span
                  data-icon="tabler:calendar-time"
                  className="iconify"
                  style={{ width: '16px', height: '16px' }}
                ></span>
                {formattedDeliveryDate}
              </CBadge>
            </CTooltip>
          </div>
        )}
      </Fragment>
    )
  }

  const location = capitalizeCombo(`${city}, ${state}`)

  return (
    <CCard className="mb-4 position-relative shadow-sm">
      <div className="position-relative">
        <CCarousel interval={false} controls={images.length > 1}>
          {images.map((item) => (
            <CCarouselItem key={item}>
              <CImage
                src={item}
                style={{
                  height: '200px',
                  width: '100%',
                  objectFit: 'cover',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              />
            </CCarouselItem>
          ))}
        </CCarousel>
        <Badges />
      </div>
      <CCardBody>
        <div className="text-center text-capitalize">
          <h4 className="fs-7 m-0 text-muted text-truncate" title={title}>
            {title?.toLowerCase()}
          </h4>
          <h5 className="fs-6 text-truncate m-0" title={name}>
            {name?.toLowerCase()}
          </h5>
          <p className="fs-7 m-0 mb-2 text-muted text-truncate" title={location}>
            {location}
          </p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-column align-items-center justify-content-start">
            <div className="d-flex flex-row align-items-center w-100">
              <CCol className="text-center" style={{ marginRight: '.4rem' }}>
                <div className="text-muted text-bold" style={{ fontSize: '.6rem' }}>
                  Desde
                </div>
                <div className="text-primary text-lg fs-5 fw-bold" style={{ marginTop: '-10px' }}>
                  UF
                </div>
              </CCol>
              <h3 className={`w-100 ${priceFrom ? '' : 'fs-5 text-warning mb-2 mt-2'}`}>
                {priceFrom ? `${formatRoundUF(priceFrom)}` : 'Consultar'}
              </h3>
            </div>

            <div className="fs-7 text-muted">
              {/* <p className="m-0">
              Cuota Mensual: <span className="fw-semibold text-primary">${monthlyFee}</span>
            </p> */}
              <p className="m-0">
                Cap Rate:
                <span className={`fw-semibold ms-1 ${capRate ? 'text-primary' : 'text-warning'}`}>
                  {capRate ? `${capRate}%` : 'Consultar'}
                </span>
              </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <CTooltip content="Ver stock">
              <a
                href={`/#/catalogo/vista-comercial/${id}/stock`}
                rel="noreferrer"
                className="ms-auto"
              >
                <CButton
                  size="sm"
                  onClick={() => navigate(`/catalogo/vista-comercial/${id}/stock`)}
                  className="text-white p-0 d-flex align-items-center justify-content-center"
                  style={{ borderRadius: '100%', height: '30px', width: '30px' }}
                >
                  <span
                    data-icon="icon-park-outline:list"
                    className="iconify"
                    style={{ width: '16px', height: '16px' }}
                  ></span>
                </CButton>
              </a>
            </CTooltip>
            <CTooltip content="Ver detalles">
              <a href={`/#/catalogo/vista-comercial/${id}`} rel="noreferrer" className="ms-auto">
                <CButton
                  size="sm"
                  onClick={() => navigate(`/catalogo/vista-comercial/${id}`)}
                  className="text-white p-0"
                  style={{ borderRadius: '100%', height: '30px', width: '30px' }}
                >
                  <CIcon icon={icon.cilPlus} />
                </CButton>
              </a>
            </CTooltip>
          </div>
        </div>
      </CCardBody>
    </CCard>
  )
}

Card.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  capRate: PropTypes.any,
  monthlyFee: PropTypes.number,
  priceFrom: PropTypes.any,
  deliveryType: PropTypes.string,
  deliveryDate: PropTypes.string,
  isOpportunity: PropTypes.bool,
  isHot: PropTypes.bool,
}

export default Card
