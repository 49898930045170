import React from 'react'
import { CPagination, CPaginationItem } from '@coreui/react'
import PropTypes from 'prop-types'

const Pagination = ({ currentPage, totalPages, onPageChange, maxPages = 8 }) => {
  // Determinar el rango de páginas a mostrar
  let startPage = 1
  let endPage = totalPages
  if (totalPages > maxPages) {
    const halfMaxPagesToShow = Math.floor(maxPages / 2)
    startPage = Math.max(1, currentPage - halfMaxPagesToShow)
    endPage = Math.min(totalPages, startPage + maxPages - 1)
    if (endPage - startPage + 1 < maxPages) {
      startPage = Math.max(1, endPage - maxPages + 1)
    }
  }

  // Generar elementos de paginación
  const pageItems = []
  for (let i = startPage; i <= endPage; i++) {
    pageItems.push(
      <CPaginationItem
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
        style={{ cursor: 'pointer' }}
      >
        {i}
      </CPaginationItem>,
    )
  }

  return (
    <CPagination className="justify-content-center flex-wrap">
      {startPage > 1 && (
        <CPaginationItem style={{ cursor: 'pointer' }} onClick={() => onPageChange(startPage - 1)}>
          <span aria-hidden="true">...</span>
        </CPaginationItem>
      )}
      {pageItems}
      {endPage < totalPages && (
        <CPaginationItem style={{ cursor: 'pointer' }} onClick={() => onPageChange(endPage + 1)}>
          <span aria-hidden="true">...</span>
        </CPaginationItem>
      )}
    </CPagination>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  maxPages: PropTypes.number,
}

export default Pagination
