import React, { Fragment, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import { UI } from 'src/components'
import { Helmet } from 'react-helmet'
import useAuth from 'src/hooks/useAuth'
import routes from 'src/routes'
import Hotjar from '@hotjar/browser'

// En este componente se renderizan las rutas de la aplicación que necesiten disponer del layout de la aplicación.
const AppContent = () => {
  useAuth()

  const { pathname } = useLocation()
  const currentRoute = routes.find((route) => route.path === pathname)?.name ?? ''

  useEffect(() => {
    Hotjar.stateChange(pathname)
  }, [pathname])

  return (
    <CContainer lg>
      <Helmet>
        <title>
          {currentRoute && pathname !== '/'
            ? `${currentRoute} | BackOffice`
            : 'Propital - BackOffice'}
        </title>
      </Helmet>
      <Suspense fallback={<UI.Loading />}>
        <Fragment>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={
                      <div className="fade-in-1">
                        <route.element />
                      </div>
                    }
                  />
                )
              )
            })}
            <Route path="/" element={<Navigate to="Home" replace />} />
          </Routes>
        </Fragment>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
