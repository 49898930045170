// Project Filters
export const initialMinFee = 1
export const initialMaxFee = 36
export const initialMinPrice = 1
export const initialMaxPrice = 10000

export const filtersInitialValues = {
  buscar: '',
  regiones: [],
  comunas: [],
  inmobiliarias: [],
  banco: [],
  banos: [],
  dormitorios: [],
  tipoentrega: [],
  ordenar: '',
  bonopie: '',
  cuotas_min: initialMinFee,
  cuotas_max: initialMaxFee,
  venta_min: initialMinPrice,
  venta_max: initialMaxPrice,
}

export const selectInitialValues = [
  { title: 'regiones', options: [] },
  { title: 'comunas', options: [] },
  { title: 'inmobiliarias', options: [] },
  { title: 'banco', options: [] },
  { title: 'banos', options: [] },
  { title: 'dormitorios', options: [] },
  { title: 'tipoentrega', options: [] },
  { title: 'ordenar', options: [] },
  { title: 'bonopie', options: [] },
]

export const chipsInitialValues = [
  { label: 'BUYDEPA', field: 'pro_type_id', value: false },
  { label: 'TOP', field: 'pro_oportunity', value: false },
]

// Modal Filters
export const showSelects = [
  'regiones',
  'comunas',
  'inmobiliarias',
  'banos',
  'dormitorios',
  'tipoentrega',
  'ordenar',
  'bonopie',
]

export const rowSelects = ['tipoentrega', 'banos', 'dormitorios', 'bonopie']

export const isMultiSelect = {
  regiones: true,
  comunas: true,
  inmobiliarias: true,
  tipoentrega: true,
}

export const titleValues = {
  banos: 'Baños',
  tipoentrega: 'Tipo de Entrega',
  ordenar: 'Ordenar por',
  bonopie: 'Bono pie',
}

// Modal Calculate
export const inputs = [
  {
    key: 'ingreso_liquido',
    text: 'Ingreso Líquido',
    size: '12',
  },
  {
    key: 'ac',
    text: 'Años Crédito',
    size: '4',
  },
  {
    key: 'financiamiento',
    text: 'Financiamiento (%)',
    size: '4',
  },
  {
    key: 'valor_hip',
    text: 'Hipotecario Mensual',
    size: '4',
  },
  {
    key: 'valor_con',
    text: 'Consumo Mensual',
    size: '4',
  },
  {
    key: 'interes_anual',
    text: 'Interés Anual (%)',
    size: '4',
  },
  {
    key: 'valor_uf',
    text: 'Valor UF',
    size: '4',
  },
]

export const communesObject = {
  ANTOFAGASTA: [{ value: 'ANTOFAGASTA', label: 'ANTOFAGASTA' }],
  ARAUCANIA: [
    { value: 'TEMUCO', label: 'TEMUCO' },
    { value: 'PUCÓN', label: 'PUCÓN' },
    { value: 'VILLARRICA', label: 'VILLARRICA' },
    { value: 'ANGOL', label: 'ANGOL' },
  ],
  ATACAMA: [{ value: 'COPIAPÓ', label: 'COPIAPÓ' }],
  ARICA: [{ value: 'ARICA', label: 'ARICA' }],
  'BERNARDO OHIGGINS': [
    { value: 'RANCAGUA', label: 'RANCAGUA' },
    { value: 'LITUECHE', label: 'LITUECHE' },
  ],
  OHIGGINS: [
    { value: 'RANCAGUA', label: 'RANCAGUA' },
    { value: 'LITUECHE', label: 'LITUECHE' },
  ],
  'BIO BIO': [
    { value: 'CONCEPCIÓN', label: 'CONCEPCIÓN' },
    { value: 'CORONEL', label: 'CORONEL' },
    { value: 'PENCO', label: 'PENCO' },
    { value: 'TALCAHUANO', label: 'TALCAHUANO' },
    { value: 'TOMÉ', label: 'TOMÉ' },
    { value: 'LOS ANGELES', label: 'LOS ANGELES' },
    { value: 'LAJA', label: 'LAJA' },
  ],
  'BÍO-BÍO': [
    { value: 'CONCEPCIÓN', label: 'CONCEPCIÓN' },
    { value: 'CORONEL', label: 'CORONEL' },
    { value: 'PENCO', label: 'PENCO' },
    { value: 'TALCAHUANO', label: 'TALCAHUANO' },
    { value: 'TOMÉ', label: 'TOMÉ' },
    { value: 'LOS ANGELES', label: 'LOS ANGELES' },
    { value: 'LAJA', label: 'LAJA' },
  ],
  COQUIMBO: [
    { value: 'LA SERENA', label: 'LA SERENA' },
    { value: 'SERENA', label: 'SERENA' },
    { value: 'COQUIMBO', label: 'COQUIMBO' },
    { value: 'SALAMANCA', label: 'SALAMANCA' },
  ],
  'LOS LAGOS': [
    { value: 'PUERTO MONTT', label: 'PUERTO MONTT' },
    { value: 'PUERTO VARAS', label: 'PUERTO VARAS' },
    { value: 'ANCUD', label: 'ANCUD' },
    { value: 'CHONCHI', label: 'CHONCHI' },
  ],
  'LOS RIOS': [{ value: 'VALDIVIA', label: 'VALDIVIA' }],
  'MAGALLANES Y ANTÁRTICA CHILENA': [{ value: 'PUNTA ARENAS', label: 'PUNTA ARENAS' }],
  MAULE: [{ value: 'PARRAL', label: 'PARRAL' }],
  METROPOLITANA: [
    { value: 'CERRILLOS', label: 'CERRILLOS' },
    { value: 'CONCHALÍ', label: 'CONCHALÍ' },
    { value: 'ESTACION CENTRAL', label: 'ESTACION CENTRAL' },
    { value: 'HUECHURABA', label: 'HUECHURABA' },
    { value: 'INDEPENDENCIA', label: 'INDEPENDENCIA' },
    { value: 'LA CISTERNA', label: 'LA CISTERNA' },
    { value: 'LA FLORIDA', label: 'LA FLORIDA' },
    { value: 'LA REINA', label: 'LA REINA' },
    { value: 'LAS CONDES', label: 'LAS CONDES' },
    { value: 'MACUL', label: 'MACUL' },
    { value: 'MAIPÚ', label: 'MAIPÚ' },
    { value: 'ÑUÑOA', label: 'ÑUÑOA' },
    { value: 'PROVIDENCIA', label: 'PROVIDENCIA' },
    { value: 'PUDAHUEL', label: 'PUDAHUEL' },
    { value: 'QUILICURA', label: 'QUILICURA' },
    { value: 'QUINTA NORMAL', label: 'QUINTA NORMAL' },
    { value: 'RECOLETA', label: 'RECOLETA' },
    { value: 'RENCA', label: 'RENCA' },
    { value: 'SANTIAGO', label: 'SANTIAGO' },
    { value: 'SANTIAGO CENTRO', label: 'SANTIAGO CENTRO' },
    { value: 'SAN JOAQUÍN', label: 'SAN JOAQUÍN' },
    { value: 'SAN MIGUEL', label: 'SAN MIGUEL' },
    { value: 'SAN RAMÓN', label: 'SAN RAMÓN' },
    { value: 'VITACURA', label: 'VITACURA' },
    { value: 'PUENTE ALTO', label: 'PUENTE ALTO' },
    { value: 'COLINA', label: 'COLINA' },
    { value: 'LAMPA', label: 'LAMPA' },
    { value: 'SAN BERNARDO', label: 'SAN BERNARDO' },
  ],
  ÑUBLE: [
    { value: 'BULNES', label: 'BULNES' },
    { value: 'CHILLÁN', label: 'CHILLÁN' },
  ],
  VALPARAISO: [
    { value: 'VALPARAÍSO', label: 'VALPARAÍSO' },
    { value: 'CONCON', label: 'CONCON' },
    { value: 'PUCHUNCAVÍ', label: 'PUCHUNCAVÍ' },
    { value: 'QUINTERO', label: 'QUINTERO' },
    { value: 'VIÑA DEL MAR', label: 'VIÑA DEL MAR' },
    { value: 'PAPUDO', label: 'PAPUDO' },
    { value: 'ZAPALLAR', label: 'ZAPALLAR' },
    { value: 'HIJUELAS', label: 'HIJUELAS' },
    { value: 'ALGARROBO', label: 'ALGARROBO' },
    { value: 'OLMUÉ', label: 'OLMUÉ' },
  ],
}
