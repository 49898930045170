import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CHeader, CHeaderNav, CHeaderToggler, CNavLink, CNavItem } from '@coreui/react'
import UserInfo from 'src/components/App/UserInfo'
import CIcon from '@coreui/icons-react'
import axios from 'axios'
import Marquee from 'react-fast-marquee'
import useLayout from 'src/hooks/useLayout'
import * as icon from '@coreui/icons'
import ModalFeedback from './ModalFeedback'
import useToast from 'src/hooks/useToast'

const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [currencies, setCurrencies] = useState(null)
  const [visible, setVisible] = useState(false)
  const [isError, setIsError] = useState(false)

  const { layout } = useLayout()
  const { Toaster, addToast } = useToast()

  const resolveWidth = {
    sm: 180,
    md: 220,
    lg: 300,
    xl: 460,
  }

  const marqueeWidth = layout.width - resolveWidth[layout.breakpoint]

  const fetchData = async () => {
    try {
      const result = await (await axios('https://mindicador.cl/api')).data
      const items = Object.values(result)
        .filter((item) => {
          const excludedCodes = [
            'tasa_desempleo',
            'euro',
            'ipc',
            'utm',
            'imacec',
            'tpm',
            'libra_cobre',
          ]
          return item.codigo && !excludedCodes.includes(item.codigo)
        })
        .map((item) => ({
          codigo: item.codigo,
          nombre: item.nombre,
          unidad_medida: item.unidad_medida,
          fecha: item.fecha,
          valor: item.valor,
        }))
      setCurrencies(items)
      localStorage.setItem('currencies', JSON.stringify(items))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const storedCurrencies = localStorage.getItem('currencies')
    if (storedCurrencies) {
      const parsedCurrencies = JSON.parse(storedCurrencies)
      setCurrencies(parsedCurrencies)
    } else {
      fetchData()
    }
  }, [])

  const minifiedCurrencies = {
    bitcoin: 'BTC',
    uf: 'UF',
    dolar: 'USD',
    euro: 'EUR',
    tpm: 'TPM',
    ipc: 'IPC',
  }

  return (
    <Fragment>
      {Toaster}
      {visible && (
        <ModalFeedback
          visible={visible}
          setVisible={setVisible}
          isError={isError}
          addToast={addToast}
        />
      )}
      <CHeader position="sticky" className="mb-3">
        <div className="d-flex flex-row align-items-center" style={{ width: '100%' }}>
          <CHeaderToggler
            className="ps-1"
            onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          >
            <CIcon icon={sidebarShow ? icon.cilMenu : icon.cilArrowThickFromLeft} size="lg" />
          </CHeaderToggler>
          <div className="mx-auto" style={{ width: `${marqueeWidth}px`, cursor: 'default' }}>
            <Marquee autoFill pauseOnHover speed={40}>
              {currencies && (
                <div className="d-flex flex-row align-items-center gap-4">
                  {currencies.map((item, index) => (
                    <p
                      key={index}
                      className={`m-0 fw-semibold fs-6 ${
                        index !== currencies.length - 1 ? '' : 'pe-4'
                      }`}
                    >
                      <span className="text-black-50">
                        {minifiedCurrencies[item.codigo] ?? item.nombre}:{' '}
                      </span>
                      <span className="text-primary fw-normal">{item.valor}</span>
                    </p>
                  ))}
                </div>
              )}
            </Marquee>
          </div>
          <CHeaderNav>
            <CNavItem>
              <CNavLink href="#">
                <CIcon icon={icon.cilBell} size="lg" />
              </CNavLink>
            </CNavItem>
            <UserInfo setVisible={setVisible} setIsError={setIsError} />
          </CHeaderNav>
        </div>
      </CHeader>
    </Fragment>
  )
}

export default AppHeader
