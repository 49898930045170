import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'
import { getComunas } from 'src/api'
import { UI } from 'src/components'

const initialValues = [
  {
    label: 'Zona de interés',
    value: '',
    type: 'select',
    options: [],
    errorMessage: 'Zona de interés inválida',
    allowNull: true,
  },
  {
    label: '¿En qué invierte actualmente?',
    value: '',
    type: 'checkbox-group',
    options: [
      { label: 'Fondos Mutuos', value: false },
      { label: 'AFP', value: false },
      { label: 'Cripto', value: false },
      { label: 'Fraccionario', value: false },
      { label: 'Otros', value: false },
    ],
    errorMessage: 'Inversion inválida',
    allowNull: true,
  },
]

const DatosObjetivos = ({ objectiveData, setObjectiveData, setShowFinalAlert }) => {
  const [datosObjetivos, setDatosObjetivos] = useState([])
  const [loading, setLoading] = useState(false)

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: objectiveData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()

      setObjectiveData(formValues)

      // Muestra la alerta final antes de crear el cliente
      setShowFinalAlert(true)
    }
  }

  const fetchCommunes = async () => {
    setLoading(true)
    const objectCommunes = (await getComunas()).data.results
    const filteredCommunes = []
    for (const index in objectCommunes) {
      filteredCommunes.push({
        value: objectCommunes[index].value,
        label: objectCommunes[index].text,
      })
    }

    const finalValues = initialValues.map((item) => {
      if (item.label === 'Zona de interés') {
        item.options = filteredCommunes
      }
      return item
    })

    setDatosObjetivos(finalValues)
    setLoading(false)
  }

  useEffect(() => {
    fetchCommunes()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {loading ? (
          <UI.Loading />
        ) : (
          datosObjetivos.map((item, index) =>
            renderField(item, index, control, true, errors, ' del cliente'),
          )
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton type="submit" color="success" className="text-white px-4 mx-4">
            Agregar cliente
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosObjetivos.propTypes = {
  objectiveData: PropTypes.object,
  setObjectiveData: PropTypes.func,
  setShowFinalAlert: PropTypes.func,
}

export default DatosObjetivos
