import React, { useState } from 'react'
import { CToast, CToastBody, CToastClose, CToastHeader, CToaster } from '@coreui/react'

const useToast = () => {
  const [toasts, setToasts] = useState([])

  const addToast = (toast) => {
    setToasts([
      ...toasts,
      {
        title: toast.title || null,
        message: toast.message || null,
        color: toast.color || 'primary',
        autohide: toast.autohide || true,
        delay: toast.delay || 3000,
        titleClassName: toast.titleStyle || 'text-white',
        messageClassName: toast.messageStyle || 'text-white',
      },
    ])
  }

  const removeToast = (index) => {
    const newToasts = [...toasts]
    newToasts.splice(index, 1)
    setToasts(newToasts)
  }

  const Toaster = (
    <CToaster placement="top-end">
      {toasts.map((item, index) => (
        <div className="mb-2" key={index}>
          <CToast
            autohide={item.autohide}
            visible={true}
            color={item.color}
            onClose={() => removeToast(index)}
            animation
          >
            {item.title && (
              <CToastHeader
                className={`bg-${item.color} fw-semibold`}
                color={item.color}
                closeButton
              >
                <p
                  className={`m-0 me-auto text-black ${item.titleClassName && item.titleClassName}`}
                >
                  {item.title}
                </p>
              </CToastHeader>
            )}
            <CToastBody>
              <div className="d-flex flex-row align-items-center">
                <div
                  className={`text-black me-auto ${item.messageClassName && item.messageClassName}`}
                >
                  {item.message}
                </div>
                {!item.title && <CToastClose white onClose={() => removeToast(index)} />}
              </div>
            </CToastBody>
          </CToast>
        </div>
      ))}
    </CToaster>
  )

  return {
    Toaster,
    addToast,
  }
}

export default useToast
