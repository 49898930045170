import React from 'react'
import L from 'leaflet'
import { CFormSwitch } from '@coreui/react'

export const handleMarker = () => {
  return L.divIcon({
    className: 'marker-base marker-blue',
    iconSize: [33, 33],
    popupAnchor: [0, -10],
  })
}
export const addStreetLayer = (state, setState) => {
  return (
    <div
      className="d-flex gap-2 p-2"
      style={{ position: 'absolute', right: 0, top: 0, zIndex: 999 }}
    >
      <p
        className="m-0 px-2 rounded-5 text-primary fw-semibold bg-white"
        style={{ height: 'fit-content' }}
      >
        Información adicional
      </p>
      <CFormSwitch
        checked={state}
        onChange={() => setState(!state)}
        style={{ transform: 'scale(1.33)', cursor: 'pointer' }}
      />
    </div>
  )
}
