import { createStore, compose } from 'redux'

const session = JSON.parse(localStorage.getItem('userSession'))
const roles = JSON.parse(localStorage.getItem('roles'))
const metrics = JSON.parse(localStorage.getItem('metrics'))
const staticProjects = JSON.parse(localStorage.getItem('staticProjects'))
const seminewProjects = JSON.parse(localStorage.getItem('seminewProjects'))
const landProjects = JSON.parse(localStorage.getItem('landProjects'))
const eeuuProjects = JSON.parse(localStorage.getItem('eeuuProjects'))
const privatesalesProjects = JSON.parse(localStorage.getItem('privatesalesProjects'))
const fracHomeProjects = JSON.parse(localStorage.getItem('fracHomeProjects'))
const staticSelects = JSON.parse(localStorage.getItem('staticSelects'))
const seminewSelects = JSON.parse(localStorage.getItem('seminewSelects'))
const landSelects = JSON.parse(localStorage.getItem('landSelects'))
const eeuuSelects = JSON.parse(localStorage.getItem('eeuuSelects'))
const privatesalesSelects = JSON.parse(localStorage.getItem('privatesalesSelects'))

const initialState = {
  sidebarShow: true,
  user: session || null,
  metrics: metrics || null,
  projects: {
    filters: null,
    results: null,
    static: staticProjects || null,
    selects: staticSelects || null,
    top: null,
  },
  seminews: {
    filters: null,
    results: null,
    static: seminewProjects || null,
    selects: seminewSelects || null,
  },
  land: {
    filters: null,
    results: null,
    static: landProjects || null,
    selects: landSelects || null,
  },
  eeuu: {
    filters: null,
    results: null,
    static: eeuuProjects || null,
    selects: eeuuSelects || null,
  },
  privatesales: {
    filters: null,
    results: null,
    static: privatesalesProjects || null,
    selects: privatesalesSelects || null,
  },
  frachome: {
    filters: null,
    results: null,
    static: fracHomeProjects || null,
  },
  pages: {
    catalogo: null,
    listado: null,
    seminuevos: null,
    terrenos: null,
    eeuu: null,
    ventasprivadas: null,
    frachome: null,
  },
  roles: roles || null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// Validar para habilitar Redux DevTools solo en entorno local
let composeEnhancers = compose

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const store = createStore(changeState, composeEnhancers())

export default store
