import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CButton,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import { Controller, useForm } from 'react-hook-form'
import { formatInputPrice, getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import { UI } from 'src/components'
import { completeReservation } from 'src/api'

export default function ModalCompleteReservation({ showModal, setShowModal, invoiceId, addToast }) {
  const [stepReservation, setStepReservation] = useState(1)
  const [loading, setLoading] = useState(false)

  const { control, errors, getValues } = useForm()

  const userEmail = getUserEmail()

  const handleConfirmReservation = () => {
    setStepReservation(2)
  }
  const handleReserve = () => {
    setLoading(true)
    const amount = getValues()['reservation-amount']
    const finalAmount = amount.replaceAll(',', '')
    const body = {
      reservation_id: invoiceId,
      broker_email: userEmail,
      amount: Number(finalAmount),
    }
    completeReservation(body)
      .then((res) => {
        addToast({ message: 'Monto restante asignado correctamente', color: 'success' })
        setShowModal(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        handlerSendErrorNotification(err)
        addToast({
          message: err?.response?.data?.detail?.message || 'Error al asignar el monto restante',
          color: 'danger',
        })
        setShowModal(false)
        setStepReservation(1)
        setLoading(false)
      })
  }

  const handleHideModal = () => {
    setShowModal(false)
  }

  return (
    <CModal
      backdrop="static"
      visible={showModal}
      onClose={() => handleHideModal()}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Completar reserva</CModalTitle>
      </CModalHeader>
      {stepReservation === 1 ? (
        <CModalBody>
          <p>Ingrese el monto total de la reserva</p>
          <Controller
            name="reservation-amount"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div>
                <CFormInput
                  type="text"
                  placeholder="300,000"
                  autoComplete="off"
                  value={field.value}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      e.target.value = formatInputPrice(e.target.value)
                    }
                    field.onChange(e)
                  }}
                />
                {errors && errors['reservation-amount'] && (
                  <span className="text-danger px-3">Campo requerido</span>
                )}
              </div>
            )}
          />
        </CModalBody>
      ) : (
        <CModalBody className="d-flex flex-column pb-0">
          <p>
            ¿Desea confirmar el monto total de{' '}
            <span className="text-primary fw-semibold">{getValues()['reservation-amount']}</span>?
          </p>
          {loading && <UI.Loading />}
        </CModalBody>
      )}
      <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          <CButton
            onClick={() => {
              stepReservation === 1 ? handleConfirmReservation() : handleReserve()
            }}
            disabled={loading}
            color="primary"
            className="text-white rounded-5"
            size="sm"
          >
            {stepReservation === 1 ? 'Completar reserva' : 'Confirmar'}
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  )
}
ModalCompleteReservation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
}
