import { lazy } from 'react'

const Dashboard = lazy(() => import('./views/inicio/dashboard'))
const Catalogo = lazy(() => import('./views/proyectos/Catalogo'))
const Listado = lazy(() => import('./views/proyectos/Listado'))
const Oportunidades = lazy(() => import('./views/proyectos/Oportunidades'))
const Mapa = lazy(() => import('./views/proyectos/Mapa'))
const VistaComercial = lazy(() => import('./views/proyectos/VistaComercial'))
const Comisiones = lazy(() => import('./views/comisiones/Iframe'))
const Cartera = lazy(() => import('./views/clientes/Cartera'))
const ClientProfile = lazy(() => import('./views/clientes/Perfil'))
const ClientRegister = lazy(() => import('./views/clientes/Register'))
const Simulador = lazy(() => import('./views/simulador'))
const Template = lazy(() => import('./views/_TemplateFolder/_ViewTemplate'))
const Brokerage = lazy(() => import('./views/brokerage'))
const Wiki = lazy(() => import('./views/wiki'))
const WikiDescription = lazy(() => import('./views/wiki/Description'))
const Reserva = lazy(() => import('./views/operaciones/Reserva'))
const Reservas = lazy(() => import('./views/reservas'))
const SemiNuevos = lazy(() => import('./views/proyectos/SemiNuevos'))
const Terrenos = lazy(() => import('./views/proyectos/Terrenos'))
const Eeuu = lazy(() => import('./views/proyectos/Eeuu'))
const VentasPrivadas = lazy(() => import('./views/proyectos/VentasPrivadas'))
const Formulario = lazy(() => import('./views/legaltributario/Formulario'))
const Profile = lazy(() => import('./views/Profile'))
const Operacion = lazy(() => import('./views/operacion/Cartera'))
const Tracking = lazy(() => import('./views/headhunting/Tracking'))
const CreateCandidate = lazy(() => import('./views/headhunting/Create'))
const Cesiones = lazy(() => import('./views/proyectos/Cesiones'))

const routes = [
  { path: '/', name: 'Home', element: Dashboard },
  {
    path: '/profile',
    name: 'Perfil',
    element: String(process.env.REACT_APP_CLIENT_PROFILE_FLAG) === 'false' ? Template : Profile,
  },
  { path: '/catalogo', name: 'Catálogo', element: Catalogo },
  { path: '/clientes', name: 'Clientes', element: Cartera },
  { path: '/clientes/cliente/:id/:client_type', name: 'Perfil Cliente', element: ClientProfile },
  { path: '/agregar-cliente', name: 'Agregar Cliente', element: ClientRegister },
  { path: '/listado', name: 'Listado', element: Listado },
  { path: '/oportunidades', name: 'Oportunidades', element: Oportunidades },
  { path: '/mapa', name: 'Mapa', element: Mapa },
  {
    path: '/catalogo/vista-comercial/:id/:stock?',
    name: 'Vista Comercial',
    element: VistaComercial,
  },
  { path: '/comisiones', name: 'Comisiones', element: Comisiones },
  { path: '/simulador', name: 'Simulador', element: Simulador },
  { path: '/brokerage', name: 'Brokerage', element: Brokerage },
  { path: '/reservas', name: 'Reservas', element: Reservas },
  { path: '/operaciones/reserva/:reservation_id?', element: Reserva },
  { path: '/seminuevos', name: 'SemiNuevos', element: SemiNuevos },
  { path: '/terrenos', name: 'Terrenos', element: Terrenos },
  { path: '/eeuu', name: 'Eeuu', element: Eeuu },
  { path: '/template', name: 'Template', element: Template }, // Test / Development
  { path: '/wiki', name: 'Wiki', element: Wiki },
  { path: '/wiki/:step', name: 'Wiki', element: WikiDescription },
  { path: '/formulario-impuestos', name: 'Formulario', element: Formulario },
  { path: '/lista-clientes', name: 'Operacion', element: Operacion },
  { path: '/venta-privada', name: 'VentasPrivadas', element: VentasPrivadas },
  { path: '/headhunting/listado', name: 'Lista de candidatos', element: Tracking },
  { path: '/headhunting/crear', name: 'Crear candidato', element: CreateCandidate },
  { path: '/cesiones', name: 'Cesiones', element: Cesiones },
]

export default routes
