import { CCard } from '@coreui/react'
import React, { Fragment } from 'react'
import jsonFromWiki from './enlaces.json'

const Body = () => {
  return (
    <Fragment>
      {jsonFromWiki &&
        jsonFromWiki.secciones.map((seccion, index) => (
          <CCard className="card-body my-2" key={index}>
            <h3 className="card-title mt-2">
              <span
                data-icon="material-symbols:book-outline"
                className="iconify"
                style={{ width: '50px', height: '40px', marginRight: '8px' }}
              ></span>
              {seccion.nombre}
            </h3>
            <ul className="list-group m-4 list-unstyled">
              {seccion.items &&
                seccion.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="m-1">
                    <h3 className="mt-2">{item.titulo}</h3>
                    <a href={item.enlace} className="text-decoration-none text-dark">
                      {item.nombre}
                    </a>
                  </li>
                ))}
            </ul>
          </CCard>
        ))}
    </Fragment>
  )
}

export default Body
