import React, { useState } from 'react'
import { CForm, CFormInput, CButton, CFormSelect } from '@coreui/react'
import { Controller } from 'react-hook-form'
import { UI } from 'src/components'
import PropTypes from 'prop-types'

const statusOptions = [
  { label: 'Estado', value: '' },
  { label: 'Pendiente', value: 'pending' },
  { label: 'Pagada', value: 'paid' },
  { label: 'Devuelto al cliente', value: 'refund' },
  { label: 'Anulada', value: 'cancelled' },
  { label: 'Inmobiliaria', value: 'real_estate' },
  { label: 'Reasignada', value: 'reassigned' },
  { label: 'Vencida', value: 'overdue' },
]

const SearchBar = ({
  getValues,
  handleSetFilters,
  handleSubmit,
  control,
  label = 'Buscar...',
  selectOptions = null,
}) => {
  const [disableSubmit, setDisableSubmit] = useState(true)

  const onSubmit = (data) => {
    if (getValues().searchParam || getValues().reservationState) {
      handleSetFilters(data)
    }
  }

  const handleResetForm = () => {
    setDisableSubmit(true)
    handleSetFilters({
      reservationState: '',
      searchParam: '',
    })
  }

  const validateSubmit = () => {
    if (getValues().searchParam || getValues().reservationState) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }

  return (
    <CForm onSubmit={handleSubmit(onSubmit)} className="w-100 d-flex align-items-center">
      <div className="d-flex gap-2" style={{ flex: '1' }}>
        <Controller
          name="searchParam"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CFormInput
              style={{ flex: '2' }}
              size="sm"
              placeholder={label}
              {...field}
              onChange={(e) => {
                field.onChange(e)
                validateSubmit()
              }}
            />
          )}
        />
        <Controller
          name="reservationState"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CFormSelect
              style={{ flex: '1' }}
              size="sm"
              options={selectOptions || statusOptions}
              {...field}
              onChange={(e) => {
                field.onChange(e)
                validateSubmit()
              }}
            />
          )}
        />
      </div>
      <CButton
        size="sm"
        color="primary"
        type="submit"
        disabled={disableSubmit}
        className="btn btn-primary btn-sm px-3 text-white rounded-5"
        style={{ whiteSpace: 'nowrap', marginLeft: '1rem' }}
      >
        Buscar
      </CButton>
      <div style={{ minWidth: '30px', maxWidth: '30px', marginLeft: '.5rem' }}>
        <UI.CircleButton
          type="button"
          onClick={() => {
            handleResetForm()
          }}
          icon="pajamas:clear-all"
        />
      </div>
    </CForm>
  )
}

SearchBar.propTypes = {
  getValues: PropTypes.func.isRequired,
  handleSetFilters: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  selectOptions: PropTypes.array,
}

export default SearchBar
