import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField, handleOnSubmit } from 'src/helpers'
import { CButton } from '@coreui/react'
import useToast from 'src/hooks/useToast'
import './styles.scss'
import { UI } from 'src/components'

const DatosPersonales = ({ clientData, id }) => {
  const [editing, setEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [datosPersonales, setDatosPersonales] = useState([])
  const [auxDatosPersonales, setAuxDatosPersonales] = useState([])

  const [loading, setLoading] = useState(false)

  const { Toaster, addToast } = useToast()

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
  }

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = async (data) => {
    setLoading(true)
    const result = await handleOnSubmit(
      clientData,
      data,
      editing,
      editedData,
      datosPersonales,
      setDatosPersonales,
      setEditing,
      setEditedData,
      'personalData',
      id,
      'investment',
    )

    if (result) {
      addToast({
        message: result.data.message,
        color: result.status === 200 ? 'success' : 'danger',
      })
    }
    setLoading(false)
  }

  // Seteo de la informacion del cliente recuperada por servicio
  const setClientData = () => {
    if (clientData) {
      setDatosPersonales([
        {
          label: 'Nombre',
          value: clientData.first_name || '',
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Nombre inválido',
        },
        {
          label: 'Apellido',
          value: clientData.last_name || '',
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Apellido inválido',
        },
        {
          label: 'Fecha de nacimiento',
          value: clientData.birth_date || '',
          type: 'date',
          pattern: /^(19\d\d|20[01]\d)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, // Formato de fecha (yyyy-mm-dd)
          errorMessage: 'Fecha de nacimiento inválida',
          legalAge: true,
        },
        {
          label: 'RUT',
          value: clientData.identity_document || '',
          type: 'text',
          errorMessage: 'RUT inválido (formato XX.XXX.XXX-X)',
        },
        {
          label: 'Teléfono',
          value: clientData.phone ? clientData.phone?.split('-').join('') : '',
          type: 'phone',
          // pattern: /^\d{11}$/, // 11 numeros
          errorMessage: 'Debe ingresar un número telefónico válido (EJ: +XX XXX XXX XXX)',
          allowNull: true,
        },
        {
          label: 'Email',
          value: clientData.email || '',
          type: 'text',
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          // pattern: /^[a-zA-Z0-9._%+\-\u00F1]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          errorMessage: 'Email inválido',
        },
        {
          label: 'Estado Civil',
          value: clientData.marital_status || '',
          type: 'select',
          options: [
            { value: 'Soltero', label: 'Soltero' },
            { value: 'Casado', label: 'Casado' },
            { value: 'Divorciado', label: 'Divorciado' },
            { value: 'Viudo', label: 'Viudo' },
          ],
        },
        {
          label: 'Profesión',
          value: clientData.profession || '',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
          type: 'text',
        },
        {
          label: 'Ciudad',
          value: clientData.city || '',
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Ciudad inválida',
        },
        {
          label: 'Calle',
          value: clientData.address || '',
          type: 'text',
        },
        {
          label: 'Numero de calle',
          value: clientData.street_number || '',
          pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,100}$/,
          type: 'text',
        },
      ])
    }
  }

  const handleCancelEditing = () => {
    setEditing(false)
    setDatosPersonales(auxDatosPersonales)
    auxDatosPersonales.forEach((item) => {
      const fieldName = item.label?.toLowerCase()
      setValue(fieldName, item.value)
      clearErrors(fieldName)
    })
  }

  useEffect(() => {
    setClientData()
  }, [clientData])

  useEffect(() => {
    if (editing) {
      // Se guardan en estado auxiliar los datos previos a editar
      setAuxDatosPersonales(datosPersonales)
      // Focus en el primer input para ayudar al usuario
      document.getElementsByTagName('input')[0].focus()
    }
  }, [editing])

  return (
    <React.Fragment>
      {Toaster}

      {loading && <UI.Loading />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
          {datosPersonales.map((item, index) =>
            renderField(item, index, control, editing, errors, ' del cliente'),
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>{/* Espacio reservado para el botón */}</div>
          {editing && (
            <div className="ml-auto">
              <CButton
                type="button"
                color="danger"
                className="text-white px-4"
                onClick={() => handleCancelEditing()}
              >
                Cancelar
              </CButton>
              <CButton type="submit" color="success" className="text-white px-4 mx-4">
                Aceptar
              </CButton>
            </div>
          )}
        </div>
      </form>
      {!editing && (
        <CButton
          type="button"
          onClick={() => setEditing(true)}
          color="primary"
          className="text-white px-4 mx-4"
          style={{ alignSelf: 'end' }}
        >
          Editar
        </CButton>
      )}
    </React.Fragment>
  )
}

DatosPersonales.propTypes = {
  clientData: PropTypes.object,
  id: PropTypes.string,
}

export default DatosPersonales
