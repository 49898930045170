import React, { Fragment, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CFormInput, CRow, CTooltip } from '@coreui/react'
import { UI } from 'src/components'
import ModalFilters from './ModalFilters'
import ModalCalculate from './ModalCalculate'
import PropTypes from 'prop-types'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { getComunas, getRegiones, getOrdenar, searchLand } from 'src/api'
import { formatSelectIndexOptions, formatSelectOptions, capitalize } from 'src/helpers'
import useToast from 'src/hooks/useToast'
import {
  filtersInitialValues,
  selectInitialValues,
  initialMinPrice,
  initialMaxPrice,
} from './constants'
import { useDispatch, useSelector } from 'react-redux'

const showSelects = ['regiones', 'comunas']

const ProjectFilters = ({ title, subtitle, cachedProjects, setResults }) => {
  const [filters, setFilters] = useState(filtersInitialValues)

  const [selects, setSelects] = useState(selectInitialValues)
  const [loadingSelects, setLoadingSelects] = useState(true)

  const [modalFilter, setModalFilter] = useState(false)
  const [modalCalculate, setModalCalculate] = useState(false)

  const { Toaster, addToast } = useToast()
  const dispatch = useDispatch()
  const landStore = useSelector((state) => state.land)

  const getParams = () => {
    const params = new FormData()
    filters.buscar && params.append('buscar', filters.buscar)
    if (filters.regiones.length > 0) {
      filters.regiones.forEach((region, index) => {
        params.append(`regiones[${index}]`, region?.value)
      })
    }
    if (filters.comunas.length > 0) {
      filters.comunas.forEach((comuna, index) => {
        params.append(`comunas[${index}]`, comuna?.value)
      })
    }
    // Modal Filters Start
    filters.ordenar?.value && params.append('ordenar', filters.ordenar?.value)
    filters.venta_min &&
      filters.venta_min !== filtersInitialValues.venta_min &&
      params.append('venta_min', filters.venta_min)
    filters.venta_max &&
      filters.venta_max !== filtersInitialValues.venta_max &&
      params.append('venta_max', filters.venta_max)
    // Modal Filters End
    return params
  }

  const searchData = async () => {
    try {
      const response = await searchLand(getParams())
      const data = response.data.results
      if (setResults && data) {
        return setResults(data)
      }
    } catch (error) {
      addToast({ message: 'No se han encontrado proyectos.', color: 'danger' })
      return console.error(error)
    }
  }

  const fetchSelects = async () => {
    try {
      const regiones = (await getRegiones()).data.results
      const comunas = (await getComunas()).data.results
      const ordenar = (await getOrdenar()).data.results
      const selects = [
        { title: 'regiones', options: formatSelectIndexOptions(regiones) },
        { title: 'comunas', options: formatSelectIndexOptions(comunas) },
        { title: 'ordenar', options: formatSelectOptions(ordenar) },
      ]
      setSelects(selects)
      localStorage.setItem('landSelects', JSON.stringify(selects))
      dispatch({ type: 'set', projects: { ...landStore, selects } })
      return setLoadingSelects(false)
    } catch (error) {
      return console.error(error)
    }
  }

  const handleFetch = () => {
    if (landStore.selects) {
      setSelects(landStore.selects)
      return setLoadingSelects(false)
    }
    return fetchSelects()
  }

  useEffect(() => {
    handleFetch()
  }, [])

  const handleSelects = (selectName, selectedOptions) => {
    const updatedValue = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]
    setFilters({
      ...filters,
      [selectName]: updatedValue,
    })
  }

  const resetFilters = () => {
    dispatch({
      type: 'set',
      projects: { ...landStore, filters: null, results: null },
    })
    setFilters(filtersInitialValues)
    setResults(cachedProjects)
  }

  return (
    <Fragment>
      {Toaster}
      {modalFilter && (
        <ModalFilters
          visible={modalFilter}
          setVisible={setModalFilter}
          filters={filters}
          setFilters={setFilters}
          selectsArray={selects}
          searchData={searchData}
          resetFilters={resetFilters}
          minPrice={initialMinPrice}
          maxPrice={initialMaxPrice}
        />
      )}
      {modalCalculate && (
        <ModalCalculate
          visible={modalCalculate}
          setVisible={setModalCalculate}
          filters={filters}
          setFilters={setFilters}
          searchData={searchData}
        />
      )}
      <CCard className="mb-2">
        <CCardBody>
          <CCol>
            <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
              <div className="d-none d-lg-block">
                <h4 className="card-title mb-0">{title}</h4>
                <p className="small text-medium-emphasis m-0">{subtitle}</p>
              </div>
              <CTooltip content="Calcular perfil financiero">
                <div className="ms-auto">
                  <UI.CircleButton
                    active={true}
                    onClick={() => setModalCalculate(!modalCalculate)}
                    icon="solar:calculator-minimalistic-bold"
                  />
                </div>
              </CTooltip>
              <CTooltip content="Filtros">
                <div>
                  <UI.CircleButton
                    active={true}
                    onClick={() => setModalFilter(!modalFilter)}
                    icon="mi:filter"
                  />
                </div>
              </CTooltip>
            </div>
          </CCol>
          <CRow className="mt-4 mb-0 mt-lg-2">
            <CCol className="d-flex flex-column w-full mb-2 mb-lg-0" xs="12" sm="6" md="4" lg="2">
              <CFormInput
                type="text"
                placeholder="Buscar proyecto"
                value={filters.buscar}
                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                onChange={(e) => setFilters({ ...filters, buscar: e.target?.value })}
                onKeyDown={(e) => e.key === 'Enter' && searchData()}
              />
            </CCol>
            {selects &&
              selects.map((item, index) => {
                if (!showSelects.includes(item.title)) return null
                const selectValue = filters?.[item.title]?.[0] ? filters[item.title][0] : null
                return (
                  <CCol key={index} className="mb-2 mb-lg-0" xs="12" sm="6" md="4" lg="2">
                    <Select
                      value={selectValue}
                      placeholder={capitalize(item.title)}
                      closeMenuOnSelect
                      components={makeAnimated()}
                      options={item.options}
                      isLoading={loadingSelects}
                      onChange={(selectedOptions) => handleSelects(item.title, selectedOptions)}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      isClearable
                      // isMulti
                    />
                  </CCol>
                )
              })}
            <CCol className="d-flex flex-row align-items-center gap-2" xs="12" sm="6" md="4" lg="2">
              <CButton
                onClick={() => searchData()}
                className="text-white rounded-5"
                size="sm"
                style={{ width: '100%' }}
                disabled={filters === filtersInitialValues}
              >
                Buscar
              </CButton>
              <div style={{ minWidth: '30px', maxWidth: '30px' }}>
                <UI.CircleButton
                  onClick={() => resetFilters()}
                  active={filters !== filtersInitialValues}
                  icon="pajamas:clear-all"
                />
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </Fragment>
  )
}

ProjectFilters.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cachedProjects: PropTypes.array,
  setResults: PropTypes.func,
}

export default ProjectFilters
