import React, { Fragment, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CFormInput, CRow, CTooltip } from '@coreui/react'
import { UI } from 'src/components'
import ModalFilters from './ModalFilters'
import ModalCalculate from './ModalCalculate'
import PropTypes from 'prop-types'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  searchProjects,
  getComunas,
  getRegiones,
  getProveedores,
  getTipoAprobacion,
  getBaños,
  getDormitorios,
  getTipoEntrega,
  getOrdenar,
  getBonopie,
  getTop,
} from 'src/api'
import { formatSelectIndexOptions, formatSelectOptions, capitalize } from 'src/helpers'
import useToast from 'src/hooks/useToast'
import {
  filtersInitialValues,
  selectInitialValues,
  initialMinFee,
  initialMaxFee,
  initialMinPrice,
  initialMaxPrice,
  chipsInitialValues,
  communesObject,
} from './constants'
import { useDispatch, useSelector } from 'react-redux'

const showSelects = ['regiones', 'comunas', 'inmobiliarias', 'banco']

const ProjectFilters = ({ title, subtitle, cachedProjects, setResults }) => {
  const [filters, setFilters] = useState(filtersInitialValues)

  const [selects, setSelects] = useState(selectInitialValues)
  const [allSelects, setAllSelects] = useState(selectInitialValues)
  const [loadingSelects, setLoadingSelects] = useState(true)

  const [modalFilter, setModalFilter] = useState(false)
  const [modalCalculate, setModalCalculate] = useState(false)

  const { Toaster, addToast } = useToast()
  const dispatch = useDispatch()
  const projectStore = useSelector((state) => state.projects)
  const topProjects = useSelector((state) => state.projects.top)

  const getParams = () => {
    const params = new FormData()
    filters.buscar && params.append('buscar', filters.buscar)
    filters.banco.length > 0 && params.append('aprobacion', filters.banco[0]?.value)
    if (filters.inmobiliarias.length > 0) {
      filters.inmobiliarias.forEach((inmobiliaria, index) => {
        params.append(`proveedor[${index}]`, inmobiliaria?.value)
      })
    }
    if (filters.regiones.length > 0) {
      filters.regiones.forEach((region, index) => {
        params.append(`regiones[${index}]`, region?.value)
      })
    }
    if (filters.comunas.length > 0) {
      filters.comunas.forEach((comuna, index) => {
        params.append(`comunas[${index}]`, comuna?.value)
      })
    }
    if (filters.tipoentrega.length > 0) {
      filters.tipoentrega.forEach((tipoentrega, index) => {
        params.append(`entrega[${index}]`, tipoentrega?.value)
      })
    }
    // Modal Filters Start
    filters.banos?.value && params.append('banos', filters.banos?.value)
    filters.dormitorios?.value && params.append('dormitorios', filters.dormitorios?.value)
    filters.ordenar?.value && params.append('ordenar', filters.ordenar?.value)
    filters.bonopie?.value && params.append('bonopie', filters.bonopie?.value)
    filters.cuotas_min &&
      filters.cuotas_min !== filtersInitialValues.cuotas_min &&
      params.append('cuotas_min', filters.cuotas_min)
    filters.cuotas_max &&
      filters.cuotas_max !== filtersInitialValues.cuotas_max &&
      params.append('cuotas_max', filters.cuotas_max)
    filters.venta_min &&
      filters.venta_min !== filtersInitialValues.venta_min &&
      params.append('venta_min', filters.venta_min)
    filters.venta_max &&
      filters.venta_max !== filtersInitialValues.venta_max &&
      params.append('venta_max', filters.venta_max)
    // Modal Filters End
    return params
  }

  const searchData = async () => {
    try {
      const response = await searchProjects(getParams())
      const data = response.data.results
      if (setResults && data) {
        dispatch({ type: 'set', projects: { ...projectStore, filters, results: data } })
        return setResults(data)
      }
    } catch (error) {
      addToast({ message: 'No se han encontrado proyectos.', color: 'danger' })
      return console.error(error)
    }
  }

  const fetchSelects = async () => {
    try {
      const regiones = (await getRegiones()).data.results
      const comunas = (await getComunas()).data.results
      const inmobiliarias = (await getProveedores()).data.results
      const banco = (await getTipoAprobacion()).data.results
      const banos = (await getBaños()).data.results
      const dormitorios = (await getDormitorios()).data.results
      const tipoentrega = (await getTipoEntrega()).data.results
      const ordenar = (await getOrdenar()).data.results
      const bonopie = (await getBonopie()).data.results
      const selects = [
        { title: 'regiones', options: formatSelectIndexOptions(regiones) },
        { title: 'comunas', options: formatSelectIndexOptions(comunas) },
        { title: 'inmobiliarias', options: formatSelectOptions(inmobiliarias) },
        { title: 'tipoentrega', options: formatSelectOptions(tipoentrega) },
        { title: 'banco', options: formatSelectOptions(banco) },
        { title: 'banos', options: formatSelectOptions(banos) },
        { title: 'dormitorios', options: formatSelectOptions(dormitorios) },
        { title: 'ordenar', options: formatSelectOptions(ordenar) },
        { title: 'bonopie', options: formatSelectOptions(bonopie) },
      ]
      setSelects(selects)
      setAllSelects(selects)
      localStorage.setItem('staticSelects', JSON.stringify(selects))
      dispatch({ type: 'set', projects: { ...projectStore, selects } })
      return setLoadingSelects(false)
    } catch (error) {
      return console.error(error)
    }
  }

  const handleFetch = () => {
    if (projectStore.selects) {
      setSelects(projectStore.selects)
      setAllSelects(projectStore.selects)
      return setLoadingSelects(false)
    }
    return fetchSelects()
  }

  const filterCommunes = () => {
    const communes = []
    for (const region of filters.regiones) {
      communes.push(communesObject[region.value])
    }
    return communes.flat()
  }

  const setCommunes = async () => {
    if (filters.regiones.some((item) => item !== null)) {
      const filteredCommunes = filterCommunes()
      const filteredSelect = selects.map((select) => {
        if (select.title === 'comunas') {
          const newSelect = { ...select, options: filteredCommunes }
          return newSelect
        } else {
          return select
        }
      })
      setSelects(filteredSelect)
    } else {
      setSelects(allSelects)
    }
  }

  useEffect(() => {
    setFilters({ ...filters, comunas: [] })
    setCommunes()
  }, [filters.regiones])

  useEffect(() => {
    handleFetch()
  }, [])

  const [chips, setChips] = useState(chipsInitialValues)

  const handleChips = (chipName) => {
    setChips((chips) =>
      chips.map((item) => ({
        ...item,
        value: item.label === chipName ? !item?.value : false,
      })),
    )
  }

  const filterByChips = (chipName) => {
    const filterProperty = {
      pro_type_id: 'BUYDEPA',
      pro_oportunity: 'TRUE',
    }

    const filteredProjects = cachedProjects?.filter(
      (project) => chipName.value && project[chipName.field] === filterProperty[chipName.field],
    )

    return setResults(filteredProjects)
  }

  useEffect(() => {
    const topChip = chips.find((item) => item?.label === 'TOP')
    const buyDepaChip = chips.find((item) => item?.label === 'BUYDEPA')

    if (topChip?.value) {
      handleTopChip()
    } else if (buyDepaChip?.value) {
      filterByChips(buyDepaChip)
    } else {
      setResults(cachedProjects)
    }
  }, [chips])

  const handleSelects = (selectName, selectedOptions) => {
    const updatedValue = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]
    setFilters({
      ...filters,
      [selectName]: updatedValue,
    })
  }

  const resetFilters = () => {
    dispatch({
      type: 'set',
      projects: { ...projectStore, filters: null, results: null },
    })
    setFilters(filtersInitialValues)
    setChips(chipsInitialValues)
    setResults(cachedProjects)
  }

  const handleTopChip = async () => {
    const topChip = chips.find((item) => item?.label === 'TOP')

    if (topChip?.value) {
      if (!topProjects?.length) {
        try {
          const res = await getTop()
          const top = res.data.results
          setResults(top)
          dispatch({ type: 'set', projects: { ...projectStore, top } })
        } catch (err) {
          addToast({ message: 'Error al buscar el TOP', color: 'danger' })
        }
      } else {
        setResults(topProjects)
      }
    } else {
      setResults(cachedProjects)
    }
  }

  useEffect(() => {
    if (projectStore.filters) {
      return setFilters(projectStore.filters)
    }
  }, [])

  return (
    <Fragment>
      {Toaster}
      {modalFilter && (
        <ModalFilters
          visible={modalFilter}
          setVisible={setModalFilter}
          filters={filters}
          setFilters={setFilters}
          selectsArray={selects}
          searchData={searchData}
          resetFilters={resetFilters}
          minFee={initialMinFee}
          maxFee={initialMaxFee}
          minPrice={initialMinPrice}
          maxPrice={initialMaxPrice}
        />
      )}
      {modalCalculate && (
        <ModalCalculate
          visible={modalCalculate}
          setVisible={setModalCalculate}
          filters={filters}
          setFilters={setFilters}
          searchData={searchData}
        />
      )}
      <CCard className="mb-2">
        <CCardBody>
          <CCol>
            <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
              <div className="d-none d-lg-block">
                <h4 className="card-title mb-0">{title}</h4>
                <p className="small text-medium-emphasis m-0">{subtitle}</p>
              </div>
              {chips.map((item, index) => (
                <UI.Chip
                  key={index}
                  text={item.label}
                  onClick={() => handleChips(item.label)}
                  active={item?.value}
                />
              ))}
              <CTooltip content="Calcular perfil financiero">
                <div className="ms-auto">
                  <UI.CircleButton
                    active={true}
                    onClick={() => setModalCalculate(!modalCalculate)}
                    icon="solar:calculator-minimalistic-bold"
                  />
                </div>
              </CTooltip>
              <CTooltip content="Filtros">
                <div>
                  <UI.CircleButton
                    active={true}
                    onClick={() => setModalFilter(!modalFilter)}
                    icon="mi:filter"
                  />
                </div>
              </CTooltip>
            </div>
          </CCol>
          <CRow className="mt-4 mb-0 mt-lg-2">
            <CCol className="d-flex flex-column w-full mb-2 mb-lg-0" xs="12" sm="6" md="4" lg="2">
              <CFormInput
                type="text"
                placeholder="Buscar proyecto"
                value={filters.buscar}
                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                onChange={(e) => setFilters({ ...filters, buscar: e.target?.value })}
                onKeyDown={(e) => e.key === 'Enter' && searchData()}
              />
            </CCol>
            {selects &&
              selects.map((item, index) => {
                if (!showSelects.includes(item.title)) return null
                const selectValue = filters?.[item.title]?.[0] ? filters[item.title][0] : null
                return (
                  <CCol key={index} className="mb-2 mb-lg-0" xs="12" sm="6" md="4" lg="2">
                    <Select
                      value={selectValue}
                      placeholder={capitalize(item.title)}
                      closeMenuOnSelect
                      components={makeAnimated()}
                      options={item.options}
                      isLoading={loadingSelects}
                      onChange={(selectedOptions) => handleSelects(item.title, selectedOptions)}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      isClearable
                      // isMulti
                    />
                  </CCol>
                )
              })}
            <CCol className="d-flex flex-row align-items-center gap-2" xs="12" sm="6" md="4" lg="2">
              <CButton
                onClick={() => searchData()}
                className="text-white rounded-5"
                size="sm"
                style={{ width: '100%' }}
                disabled={filters === filtersInitialValues}
              >
                Buscar
              </CButton>
              <div style={{ minWidth: '30px', maxWidth: '30px' }}>
                <UI.CircleButton
                  onClick={() => resetFilters()}
                  active={filters !== filtersInitialValues}
                  icon="pajamas:clear-all"
                />
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </Fragment>
  )
}

ProjectFilters.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cachedProjects: PropTypes.array,
  setResults: PropTypes.func,
}

export default ProjectFilters
