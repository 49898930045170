import React from 'react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import {
  CButton,
  CCard,
  CCardBody,
  CCarousel,
  CCarouselItem,
  CCol,
  CImage,
  CTooltip,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import * as icon from '@coreui/icons'
import { capitalizeCombo, formatRoundUF } from 'src/helpers'

const CardEEUU = ({
  id,
  name,
  state,
  city,
  images,
  priceFrom,
  pro_currency,
  typologies,
  stock_arriendo,
}) => {
  const navigate = useNavigate()

  const location = capitalizeCombo(`${city}, ${state}`)

  return (
    <CCard className="mb-4 position-relative shadow-sm">
      <div className="position-relative">
        <CCarousel interval={false} controls={images.length > 1}>
          {images.map((item) => (
            <CCarouselItem key={item}>
              <CImage
                src={item}
                style={{
                  height: '200px',
                  width: '100%',
                  objectFit: 'cover',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              />
            </CCarouselItem>
          ))}
        </CCarousel>
      </div>
      <CCardBody style={{ height: '170px' }}>
        <h6
          className="text-center text-capitalize fs-7 m-0 text-muted text-truncate"
          title={location}
        >
          {location}
        </h6>
        <div className="text-center text-capitalize">
          <h3 className="fs-6 m-0" title={name}>
            {name && typeof name === 'string' ? name.toLowerCase() : ''}
          </h3>
        </div>
        <div className="d-flex align-items-center justify-content-center text-center text-capitalize">
          <span
            data-icon="ion:home-sharp"
            className="iconify text-muted"
            style={{ width: '16px', height: '16px', color: '#6c757d' }}
          ></span>
          <span className="fs-6 m-0 text-muted" title={typologies}>
            {typologies}
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-column align-items-center justify-content-start">
            <div className="d-flex flex-row align-items-center w-100">
              <CCol className="text-center" style={{ marginRight: '.4rem' }}>
                <div className="text-primary text-lg fs-5 fw-bold" style={{ marginTop: '0px' }}>
                  {pro_currency}
                </div>
              </CCol>

              <h3 className={`w-100 ${priceFrom ? '' : 'fs-5 text-warning mb-2 mt-2'}`}>
                {priceFrom ? `${formatRoundUF(priceFrom)}` : 'Consultar'}
              </h3>
            </div>
            <div className="d-flex flex-row align-items-start justify-content-start w-100">
              <p className="text-muted">
                Rent:{stock_arriendo ? `${formatRoundUF(stock_arriendo)}` : 'Consultar'} USD
              </p>
            </div>
          </div>
          <div style={{ marginBottom: '3rem' }} className="d-flex flex-column gap-1">
            <CTooltip content="Ver detalles">
              <a href={`/#/catalogo/vista-comercial/${id}`} rel="noreferrer" className="ms-auto">
                <CButton
                  size="sm"
                  onClick={() => navigate(`/catalogo/vista-comercial/${id}`)}
                  className="text-white p-0"
                  style={{ borderRadius: '100%', height: '30px', width: '30px' }}
                >
                  <CIcon icon={icon.cilPlus} />
                </CButton>
              </a>
            </CTooltip>
          </div>
        </div>
      </CCardBody>
    </CCard>
  )
}

CardEEUU.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  typologies: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  priceFrom: PropTypes.any,
  pro_currency: PropTypes.string,
  stock_arriendo: PropTypes.string,
}

export default CardEEUU
