import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilLockLocked, cilUser, cilBug, cilCommentBubble } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import logo from 'src/assets/brand/logo-mobile.png'
import useAuth from 'src/hooks/useAuth'
import PropTypes from 'prop-types'

const UserInfo = ({ setVisible, setIsError }) => {
  const { user, logout } = useAuth()

  const handleModal = (isError) => {
    setIsError(isError)
    setVisible(true)
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={user?.picture ?? logo} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">{user?.name}</CDropdownHeader>
        <CDropdownItem href="/#/profile">
          <CIcon icon={cilUser} className="me-2" />
          Mi perfil
        </CDropdownItem>
        <CDropdownHeader className="bg-light fw-semibold py-2">Feedback</CDropdownHeader>
        <CDropdownItem className="text-primary cursor-pointer" onClick={() => handleModal(true)}>
          <CIcon icon={cilBug} className="me-2" />
          Reportar error
        </CDropdownItem>
        <CDropdownItem className="text-primary cursor-pointer" onClick={() => handleModal(false)}>
          <CIcon icon={cilCommentBubble} className="me-2" />
          Enviar sugerencia
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={() => logout()} className="text-danger fw-semibold cursor-pointer">
          <CIcon icon={cilLockLocked} className="me-2" />
          <span>Cerrar sesión</span>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

UserInfo.propTypes = {
  setVisible: PropTypes.func,
  setIsError: PropTypes.func,
}

export default UserInfo
