import React, { useCallback, useEffect, useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import './styles.css'

const MultiRangeSlider = ({
  min,
  max,
  currentMin,
  currentMax,
  onChange,
  width,
  indicators,
  disableMin,
  disableMax,
  step = 1,
}) => {
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)

  // Convierte el valor en porcentaje
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  // Función para establecer el ancho y posición del rango
  // Este useCallback se utiliza para memoizar funciones y evitar que se cree una nueva instancia de función cada vez que el componente se re-renderiza.
  const setRangeStyle = useCallback(
    (minValue, maxValue) => {
      const minPercent = getPercent(minValue)
      const maxPercent = getPercent(maxValue)
      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.right = `${maxPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    },
    [getPercent],
  )

  useEffect(() => {
    if (currentMin) {
      setMinVal(currentMin)
    }
    if (currentMax) {
      setMaxVal(currentMax)
    }
  }, [])

  // Establece el ancho del rango para disminuir desde el lado izquierdo
  useEffect(() => {
    setRangeStyle(minVal, maxValRef.current)
  }, [minVal, getPercent])

  // Establece el ancho del rango para disminuir desde el lado derecho
  useEffect(() => {
    setRangeStyle(minValRef.current, maxVal)
  }, [maxVal, getPercent])

  // Obtiene los valores mínimos y máximos cuando su estado cambia
  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal])

  return (
    <div className="r-container">
      {!disableMin && (
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1)
            setMinVal(value)
            minValRef.current = value
          }}
          className="r-thumb r-thumb-left"
          style={{ zIndex: minVal > max - 100 && '5', width: `${width}px` }}
        />
      )}
      {!disableMax && (
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1)
            setMaxVal(value)
            maxValRef.current = value
          }}
          className="r-thumb r-thumb-right"
          style={{ width: `${width}px` }}
        />
      )}
      <div className="r-slider" style={{ width: `${width}px` }}>
        <div className="r-slider-track" />
        <div ref={range} className="r-slider-range" />
        {indicators && (
          <Fragment>
            <div className="r-slider-left-value">{minVal}</div>
            <div className="r-slider-right-value">{maxVal}</div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  currentMin: PropTypes.number,
  currentMax: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  indicators: PropTypes.bool,
  disableMin: PropTypes.bool,
  disableMax: PropTypes.bool,
  step: PropTypes.number,
}

export default MultiRangeSlider
