import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { CCol, CContainer, CRow } from '@coreui/react'
import { capitalize } from 'src/helpers/index.js'

const VCSections = ({ sections }) => {
  const Segment = (array) => {
    if (!array) return null
    return array.map((item, index) => {
      return (
        <CCol
          key={index}
          sm="12"
          className="rounded-3 p-2"
          style={{ background: 'rgba(44, 56, 74, 0.060)' }}
        >
          <p className="m-0 fw-semibold text-truncate text-primary">{item.titulo}</p>
          <div dangerouslySetInnerHTML={{ __html: item.value ?? 'Sin Información' }}></div>
        </CCol>
      )
    })
  }

  return (
    <Fragment>
      {sections?.map((section, index) => {
        const title = section.titulo ? section.titulo : section.titulo_seccion
        if (title === 'PREGUNTAS FRECUENTES') {
          return (
            <CContainer key={index}>
              <CCol
                className="text-black"
                style={{ borderBottom: '1px solid rgba(44, 56, 74, 0.22)' }}
              >
                <h3 className="fs-5">{capitalize(title?.toLowerCase())}</h3>
              </CCol>
              <CContainer className="mt-2">
                <CRow className="gap-2">{Segment(section.contenido)}</CRow>
              </CContainer>
            </CContainer>
          )
        } else {
          return null
        }
      })}
    </Fragment>
  )
}

VCSections.propTypes = {
  sections: PropTypes.array,
}

export default VCSections
