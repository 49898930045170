import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { CCol, CContainer, CRow } from '@coreui/react'
import { capitalize } from 'src/helpers/index.js'

const VCSections = ({ sections }) => {
  const handleCol = (col) => {
    if (typeof col === 'number') {
      return `col-sm-6`
    }
    return col
  }

  const Segment = (array) => {
    if (!array) return null
    return array.map((item, index) => {
      return (
        <div
          key={index}
          className={`rounded-3 p-2 ${handleCol(item.col)}`}
          style={{ background: 'rgba(44, 56, 74, 0.060)' }}
        >
          <p className="m-0 fw-semibold text-truncate text-primary">{item.titulo}</p>
          <div dangerouslySetInnerHTML={{ __html: item.value ?? 'Sin Información' }}></div>
        </div>
      )
    })
  }

  return (
    <Fragment>
      {sections?.map((section, index) => {
        const title = section.titulo ? section.titulo : section.titulo
        if (title === 'FINANCIAMIENTO' || title === 'CONSIDERACIONES COMERCIALES') {
          return (
            <CContainer key={index} className="mb-5">
              <CCol
                className="text-black"
                style={{ borderBottom: '1px solid rgba(44, 56, 74, 0.22)' }}
              >
                <h3 className="fs-5">{capitalize(title?.toLowerCase())}</h3>
              </CCol>
              <CContainer className="mt-2">
                <CRow className="gap-2">{Segment(section.contenido)}</CRow>
              </CContainer>
            </CContainer>
          )
        } else {
          return null
        }
      })}
    </Fragment>
  )
}

VCSections.propTypes = {
  sections: PropTypes.array,
}

export default VCSections
