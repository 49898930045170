import axios from 'axios'

// TODO: Próximamente agregar .env para dev y prod.
const API_URL = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
  baseURL: API_URL,
})

const CLIENTS_API_URL = process.env.REACT_APP_URL_API_CLIENTES
const CLIENTS_API_KEY = process.env.REACT_APP_API_KEY_CLIENTES

const clientsAxiosInstance = axios.create({
  baseURL: CLIENTS_API_URL,
  headers: {
    api_key_header: CLIENTS_API_KEY,
  },
})

const ALTERNATIVE_CLIENTS_API_URL = process.env.REACT_APP_URL_ALTERNATIVE_API_CLIENTES
const ALTERNATIVE_CLIENTS_API_KEY = process.env.REACT_APP_ALTERNATIVE_API_KEY_CLIENTES

const alternativeClientsAxiosInstance = axios.create({
  baseURL: ALTERNATIVE_CLIENTS_API_URL,
  headers: {
    api_key_header: ALTERNATIVE_CLIENTS_API_KEY,
  },
})

const SIMULATOR_API_URL = process.env.REACT_APP_URL_API_SIMULATOR
const SIMULATOR_API_KEY = process.env.REACT_APP_API_KEY_SIMULATOR

const simulatorAxiosInstance = axios.create({
  baseURL: SIMULATOR_API_URL,
  headers: {
    api_key_header: SIMULATOR_API_KEY,
  },
})

const RESERVATION_API_URL = process.env.REACT_APP_URL_API_RESERVATION
const RESERVATION_API_KEY = process.env.REACT_APP_API_KEY_RESERVATION

const reservationAxiosInstance = axios.create({
  baseURL: RESERVATION_API_URL,
  headers: {
    're-api-key': RESERVATION_API_KEY,
  },
})

const METRICS_API_URL = process.env.REACT_APP_API_URL_METRICS
const METRICS_API_KEY = process.env.REACT_APP_API_KEY_METRICS

const metricsAxiosInstance = axios.create({
  baseURL: METRICS_API_URL,
  headers: {
    api_key_header: METRICS_API_KEY,
  },
})

const INDICATORS_API_URL = process.env.REACT_APP_API_URL_INDICATORS
const INDICATORS_API_KEY = process.env.REACT_APP_API_KEY_INDICATORS

const indicatorsAxiosInstance = axios.create({
  baseURL: INDICATORS_API_URL,
  headers: {
    api_key_header: INDICATORS_API_KEY,
  },
})

const TAX_API_URL = process.env.REACT_APP_API_URL_TAX
const TAX_API_KEY = process.env.REACT_APP_API_KEY_TAX

const taxAxiosInstance = axios.create({
  baseURL: TAX_API_URL,
  headers: {
    'x-api-key': TAX_API_KEY,
  },
})

const RBAC_API_URL = process.env.REACT_APP_API_URL_RBAC
const RBAC_API_KEY = process.env.REACT_APP_API_KEY_RBAC

const rbacAxiosInstance = axios.create({
  baseURL: RBAC_API_URL,
  headers: {
    api_key_header: RBAC_API_KEY,
  },
})

const USER_PROFILE_API_URL = process.env.REACT_APP_API_URL_USER_PROFILE
const USER_PROFILE_API_KEY = process.env.REACT_APP_API_KEY_USER_PROFILE

const userProfileAxiosInstance = axios.create({
  baseURL: USER_PROFILE_API_URL,
  headers: {
    api_key_header: USER_PROFILE_API_KEY,
  },
})

const FLOKZU_API_URL = process.env.REACT_APP_API_URL_FLOKZU

const flokzuAxiosInstance = axios.create({
  baseURL: FLOKZU_API_URL,
})

const MAINTAINER_API_URL = process.env.REACT_APP_API_URL_MAINTAINER
const MAINTAINER_API_KEY = process.env.REACT_APP_API_KEY_MAINTAINER

const maintainerAxiosInstance = axios.create({
  baseURL: MAINTAINER_API_URL,
  headers: {
    'header-api-key': MAINTAINER_API_KEY,
  },
})

const CESIONES_API_URL = process.env.REACT_APP_API_URL_CESIONES
const CESIONES_API_KEY = process.env.REACT_APP_API_KEY_CESIONES

const cesionesAxiosInstance = axios.create({
  baseURL: CESIONES_API_URL,
  headers: {
    api_key_header: CESIONES_API_KEY,
  },
})

const HEADHUNTING_API_URL = process.env.REACT_APP_API_URL_HEADHUNTING
const HEADHUNTING_API_KEY = process.env.REACT_APP_API_KEY_HEADHUNTING

const headhuntingApi = axios.create({
  baseURL: HEADHUNTING_API_URL,
  headers: {
    api_key_header: HEADHUNTING_API_KEY,
  },
})

const IS_BROKER_API_URL = process.env.REACT_APP_API_BROKER_STATUS
const IS_BROKER_API_KEY = process.env.REACT_APP_API_KEY_BROKER_STATUS

const isBrokerApi = axios.create({
  baseURL: IS_BROKER_API_URL,
  headers: {
    api_key_header: IS_BROKER_API_KEY,
  },
})

const ERROR_NOTIFICATION_API_URL = process.env.REACT_APP_API_URL_ERROR_NOTIFICATION

const errorNotificationAxiosInstance = axios.create({
  baseURL: ERROR_NOTIFICATION_API_URL,
})

const SLACK_BOT_API_URL = process.env.REACT_APP_API_URL_SLACK_BOT

const slackbot = axios.create({
  baseURL: SLACK_BOT_API_URL,
})

const HOME_API_URL = process.env.REACT_APP_API_URL_HOME
const HOME_API_KEY = process.env.REACT_APP_API_KEY_HOME

const homeAxiosInstance = axios.create({
  baseURL: HOME_API_URL,
  headers: {
    api_key_header: HOME_API_KEY,
  },
})

const FRAC_API_URL = process.env.REACT_APP_API_URL_FRAC

const fracAxiosInstance = axios.create({
  baseURL: FRAC_API_URL,
})

// FIXME: Total refactor of this file and api/index.js, it's a mess.
// TODO: Please start to use folders for each axios instance.
export {
  axiosInstance,
  clientsAxiosInstance,
  alternativeClientsAxiosInstance,
  simulatorAxiosInstance,
  reservationAxiosInstance,
  metricsAxiosInstance,
  indicatorsAxiosInstance,
  rbacAxiosInstance,
  taxAxiosInstance,
  userProfileAxiosInstance,
  maintainerAxiosInstance,
  flokzuAxiosInstance,
  cesionesAxiosInstance,
  headhuntingApi,
  isBrokerApi,
  errorNotificationAxiosInstance,
  slackbot,
  homeAxiosInstance,
  fracAxiosInstance,
}
