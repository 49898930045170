import React from 'react'
import { CCard, CCardBody, CCol } from '@coreui/react'

const Header = () => {
  return (
    <CCard className="mb-2">
      <CCardBody className="d-flex">
        <CCol>
          <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
            <div className="d-lg-block">
              <h4 className="card-title mt-2">
                <span
                  data-icon="ph:book-bold"
                  className="iconify"
                  style={{ width: '50px', height: '40px', marginRight: '8px' }}
                ></span>
                Academy Propital
              </h4>

              <div className="mt-2">
                ¡Bienvenido a nuestra Base de conocimiento! Aquí puedes aprender de como funciona
                todo y familiarízate con nuestros procesos y directorios en nuestros sistemas.
              </div>
            </div>
          </div>
        </CCol>
      </CCardBody>
    </CCard>
  )
}

export default Header
