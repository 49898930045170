import React, { Fragment, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CImage, CRow, CProgress } from '@coreui/react'
import { getFracPublication } from 'src/api'
import { formatFracHomeData, capitalizeCombo, formatRoundUF } from 'src/helpers'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const Frac = () => {
  const [projects, setProjects] = useState(null)
  const dispatch = useDispatch()
  const fachomeStore = useSelector((state) => state.frachome)
  const [loading, setLoading] = useState(true)

  const setResults = (data) => {
    setProjects(formatFracHomeData(data))
  }

  const fetchData = async () => {
    try {
      const array = await getFracPublication()
      if (array && array.data && array.data.success) {
        if (array.data.data && Array.isArray(array.data.data.publications)) {
          const data = array.data.data.publications
          setResults(data)
          localStorage.setItem('fracHomeProjects', JSON.stringify(data))
          dispatch({
            type: 'set',
            projects: { ...fachomeStore, static: data },
          })
          setLoading(false)
        } else {
          console.error('Data does not contain valid publications:', array.data)
        }
      } else {
        console.error('Data is not successful:', array.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const Card = (item, index) => {
    const formatProjectNameForURL = (name) => {
      return name.toLowerCase().replace(/\s+/g, '-')
    }

    const projectNameForURL = formatProjectNameForURL(item.name)

    const porcentajeAvance = (item.currentAmmount / item.ammountGoal) * 100
    const fechaFinal = new Date(item.endDate)
    const fechaActual = new Date()
    const tiempoRestante = Math.ceil((fechaFinal - fechaActual) / (1000 * 60 * 60 * 24))

    return (
      <CCol md="4" className="my-4" key={index}>
        <CCard className="position-relative shadow-sm h-100">
          <div className="position-relative overflow-hidden">
            <CImage
              src={item.images[0]}
              className="w-100"
              style={{
                height: '200px',
                objectFit: 'cover',
              }}
            />
          </div>
          <CCardBody>
            <div className="text-center">
              <h5 className="max-w-full font-semibold text-left text-truncate dark:text-foreground">
                {capitalizeCombo(item.name)}
              </h5>
            </div>

            <div className="d-flex justify-content-between my-3">
              <h6>
                {' '}
                {item.currency.symbol} {formatRoundUF(item.ammountGoal)}
              </h6>
              <h6>Días restantes: {tiempoRestante}</h6>
            </div>
            <CProgress value={porcentajeAvance} className="mb-3" />
            <p className="text-center mb-3">Porcentaje de avance: {porcentajeAvance.toFixed(2)}%</p>
            <div className="flex text-xs text-center text-primary">
              <p>
                <span className="small" style={{ fontSize: '0.75rem' }}>
                  {item.currency.symbol}
                </span>{' '}
                +{' '}
                <span className="font-weight-bold" style={{ fontSize: '1.25rem' }}>
                  {item.percent.conservador}%
                </span>
                <b className="mx-2 text-secondary">-</b>
                <span className="small" style={{ fontSize: '0.75rem' }}>
                  {item.currency.symbol}
                </span>{' '}
                +{' '}
                <span className="font-weight-bold" style={{ fontSize: '1.25rem' }}>
                  {item.percent.optimista}%
                </span>
              </p>
            </div>

            <div className="text-center">
              <CButton
                onClick={() =>
                  window.open(
                    `https://fraccionario.propital.com/proyecto/detalle/${projectNameForURL}`,
                    '_blank',
                  )
                }
                color="primary"
                className="text-white rounded-5 my-3"
                size="md"
              >
                Ver proyecto
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    )
  }

  return !loading ? (
    <Fragment>
      {projects ? (
        <CRow>
          {projects.map((item, index) => (
            <Card {...item} key={index} />
          ))}
        </CRow>
      ) : null}
    </Fragment>
  ) : null
}

Frac.propTypes = {
  loading: PropTypes.bool,
}

export default Frac
