import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const Brochure = ({ project }) => {
  const brochureURL = project.links.brochure

  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        {brochureURL ? (
          <iframe
            src={brochureURL}
            width="290%"
            height="1900"
            title="Título único para el iframe"
            allow="autoplay"
          ></iframe>
        ) : null}
      </div>
    </Fragment>
  )
}

Brochure.propTypes = {
  project: PropTypes.object,
}

export default Brochure
