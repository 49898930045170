import React, { Fragment } from 'react'
import { CButton } from '@coreui/react'
import { UI } from 'src/components'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'
import { capitalizeCombo } from 'src/helpers'

const Location = ({ project }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        {project.city && project.state && (
          <h2 className="fs-5 text-black">
            <CIcon icon={icon.cilLocationPin} className="text-primary mx-1" size="lg" />
            {capitalizeCombo(`${project.city} - ${project.state}`)}
          </h2>
        )}
        {project.links.googleMaps && (
          <CButton
            onClick={() => window.open(project.links.googleMaps)}
            className="text-white"
            color="primary"
            size="sm"
          >
            <CIcon icon={icon.cilMap} size="sm" /> Consultar Manualmente
          </CButton>
        )}
      </div>
      {project.coordinates && (
        <UI.Map
          height="300px"
          scrollWheelZoom={false}
          markers={project.coordinates ? [project.coordinates] : null}
          popUpContent={project.name}
          center={project.coordinates}
          zoom={20}
        />
      )}
    </Fragment>
  )
}

Location.propTypes = {
  project: PropTypes.object,
}

export default Location
