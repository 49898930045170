// TODO: Separar todos los helpers por Sección, no tener todo en un solo file.
// Tomar como referencia la estructura de apps/frontend-customers

import React from 'react'
import { Controller } from 'react-hook-form'
import {
  CFormInput,
  CFormSelect,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CTooltip,
  CFormSwitch,
  CFormTextarea,
  CFormLabel,
} from '@coreui/react'
import { cilOptions } from '@coreui/icons'
import { sendErrorNotification, updateClient, updateUserProfile } from 'src/api'
import { validate } from 'rut.js'
import PhoneInput from 'react-phone-input-2'
import CIcon from '@coreui/icons-react'
import 'react-phone-input-2/lib/style.css'
import './styles.scss'

const defaultImage =
  'https://propital-catalogo.s3.us-west-2.amazonaws.com/assets/images/default-project-enhance.png'

// Entrada: "10000.20", Salida: "10.000,20"
export const formatPrice = (string, decimals = true) => {
  return parseFloat(string).toLocaleString('es-ES', {
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
    useGrouping: true,
    groupSeparator: '.',
    decimalSeparator: ',',
  })
}

// Formatea las opciones de un select con índices para que sean compatibles con react-select
export const formatSelectIndexOptions = (array) => {
  return Object.values(array).map((item) => {
    return {
      value: item?.value,
      label: item?.text,
    }
  })
}

// Formatea las opciones de un select con índices para que sean compatibles con react-select
export const formatSelectOptions = (array) => {
  return array
    .filter((item) => item?.value && item?.text)
    .map((item) => ({
      value: item?.value,
      label: item?.text,
    }))
}

// Copia un string al portapapeles
export const copyToClipboard = (string) => navigator.clipboard.writeText(string)

// Genera un número aleatorio entero entre un rango mínimo y máximo
export const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

// Aplica primera letra mayúscula a un string
export const capitalize = (string) => string?.charAt(0).toUpperCase() + string?.slice(1)

// Aplica la primera letra mayúscula a cada palabra de un string
export const capitalizeCombo = (string) =>
  string
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

export const getUserEmail = () => {
  const userSession = localStorage.getItem('userSession')
  if (userSession) {
    const userData = JSON.parse(userSession)
    return userData.email
  }
  return null
}

// Normaliza los datos de un proyecto para que sean compatibles con los componentes de Catalogo y Listado
export const formatProjectCardsData = (response) => {
  return response?.map((item, index) => ({
    index: index + 1,
    id: item?.pid,
    supplier: item?.supplier_name,
    name: item?.pro_name,
    images: [item?.pro_cover ? item?.pro_cover : defaultImage],
    capRate: item?.pro_caprate,
    monthlyFee: 5000,
    pro_foot_bonus: item.pro_foot_bonus,
    priceFrom: item?.stock_valor_con_descuento
      ? item?.stock_valor_con_descuento
      : item?.stock_price_min,
    deliveryType: capitalize(item?.pro_estimated_delivery_type?.toLowerCase()),
    deliveryDate: item?.pro_estimated_delivery_format,
    isOpportunity: item?.pro_oportunity === 'TRUE' ? true : false,
    isHot: item?.pro_hot === 'TRUE' ? true : false,
    external_id: item?.external_id,
    pro_currency: item?.pro_currency,
    state: item?.pro_state,
    city: item?.pro_city,
    fee: item?.pro_cuotas ?? '-',
    typologies: typeof item?.stock_tipologys === 'string' ? item?.stock_tipologys.split('|') : [],
    stock_arriendo: item?.stock_arriendo,
    updatedAt: item?.pro_lastupdate,
    businessUser: {
      name: item?.pro_ejecutivo_negocios,
      mail: item?.pro_ejecutivo_negocios_mail,
    },
  }))
}

// Normaliza los datos de un proyecto para que sean compatibles con los componentes de CatalogoTop y Listado
export const formatProjectTopCardsData = (response) => {
  return response?.map((item, index) => ({
    index: index + 1,
    id: item?.pid,
    supplier: item?.supplier_name,
    name: item?.pro_name,
    images: [item?.pro_cover ? item?.pro_cover : defaultImage],
    capRate: item?.pro_caprate,
    monthlyFee: 5000,
    priceFrom: item?.stock_valor_con_descuento
      ? item?.stock_valor_con_descuento
      : item?.stock_price_min,
    deliveryType: capitalize(item?.pro_estimated_delivery_type?.toLowerCase()),
    deliveryDate: item?.pro_estimated_delivery_format,
    isOpportunity: item?.pro_oportunity === 'TRUE' ? true : false,
    isHot: item?.pro_hot === 'TRUE' ? true : false,
    external_id: item?.external_id,
    pro_currency: item?.pro_currency,
    state: item?.pro_state,
    city: item?.pro_city,
    fee: item?.pro_cuotas ?? '-',
    typologies: item?.stock_tipologys ? item?.stock_tipologys?.split('|') : [],
    updatedAt: item?.pro_lastupdate,
    businessUser: {
      name: item?.pro_ejecutivo_negocios,
      mail: item?.pro_ejecutivo_negocios_mail,
    },
  }))
}

// Normaliza los datos de un proyecto para que sean compatibles con los componentes de CatalogoBrokerage y Listado
export const formatProjectVpCardsData = (response) => {
  return response?.map((item, index) => ({
    index: index + 1,
    id: item?.pid,
    name: item?.proyecto,
    unit: item?.total_unidades,
    currency: item?.pro_currency,
    priceFrom: item?.stock_valor_con_descuento
      ? item?.stock_valor_con_descuento
      : item?.stock_price_min,
    stockDate: item?.stock_lastupdate,
    images: [item?.pro_cover ? item?.pro_cover : defaultImage],
    typologies: typeof item?.stock_tipologys === 'string' ? item?.stock_tipologys.split('|') : [],
    businessUser: {
      name: item?.pro_ejecutivo_negocios,
      mail: item?.pro_ejecutivo_negocios_mail,
      phone: item?.pro_ejecutivo_negocios_telefono,
    },
  }))
}

// Normaliza los datos de Fraccionario para el home
export const formatFracHomeData = (array) => {
  return array.map((item, index) => ({
    index: index + 1,
    id: item?.pk_publicacion_proyecto_id,
    name: item?.nombre_proyecto,
    startDate: item?.fecha_inicio,
    endDate: item?.fecha_finalizacion,
    stateProject: item?.cro_estados_publicaciones_proyectos.estado_publicacion_proyecto_descripcion,
    ammountGoal: item?.monto_objetivo,
    currentAmmount: item?.recaudacion_actual,
    rentalPeriod: item?.periodo_rentabilidad,
    percent: {
      conservador: item?.porcentaje_conservador,
      optimista: item?.porcentaje_optimista,
    },
    crowdfunding: {
      typeId: item?.cro_tipo_crowdfunding.pk_tipo_crowdfunding_id,
      description: item?.cro_tipo_crowdfunding.tipo_crowdfunding_descripcion,
    },
    currency: {
      description: item?.cro_divisas.divisa_descripcion,
      code: item?.cro_divisas.divisa_iso_4217_codigo,
      symbol: item?.cro_divisas.divisa_simbolo,
    },
    images: [item?.imagen_proyecto ? item?.imagen_proyecto : defaultImage],
  }))
}

// Normaliza los datos de un proyecto para que sean compatibles con los componentes de VistaComercial
export const formatProject = (project) => {
  return {
    id: project.idproyecto,
    name: project.nombre_proyecto,
    project_type: project.tipo_proyecto,
    supplier: project.supplier_name,
    image: project.image_cover ? project.image_cover : defaultImage,
    description: project.descripcion ?? project.description,
    state: project.region ?? '',
    city: project.comuna ?? project.commune,
    deliveryDate: project.fecha_entrega_estimada,
    deliveryType: project.tipo_entrega,
    updatedAt: project.lastupdate,
    currency: project.moneda,
    isOpportunity: project.oportunidad === 1 ? true : false,
    isHot: project.hot === 1 ? true : false,
    requisitoNegocio: project?.requisitos_negocio_ganado ?? null,
    businessUser: {
      name: project?.pro_ejecutivo_negocios ?? 'S/I',
      phone: project?.ejecutivo_telefono ?? 'S/I',
      mail: project?.pro_ejecutivo_negocios_mail ?? 'S/I',
      namePost: project?.postventa_ejecutivo,
      phonePost: project?.postventa_telefono,
      mailPost: project?.postventa_correo,
    },
    links: {
      googleDrive: project?.carpeta_drive ?? '',
      googleMaps: project?.url_mapa ?? '',
      website: project?.url_proyecto ?? '',
      brochure: project?.url_brochure ?? '',
    },
    coordinates: project?.coordinates ?? null,
  }
}

export const formatRoundUF = (str) => {
  const num = Math.round(parseFloat(str))
  const formattedNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return `${formattedNum}`
}

// Normaliza los datos de la tabla Stock
export const formatStockData = (array, handleShowModal, roles) => {
  return array.map((item) => {
    return {
      id: item?.id,
      unidad: item?.numero,
      etapa: item?.etapa,
      valorLista: item?.valor_lista,
      valorListaFormat: item?.valor_lista ? formatRoundUF(item?.valor_lista) : 'S/I',
      valorConDescuento: item?.valor_con_descuento,
      valorConDescuentoFormat: item?.valor_con_descuento
        ? formatRoundUF(item?.valor_con_descuento)
        : 'S/I',
      porcentajeDescuento: item?.porcentaje_descuento ?? 'S/I',
      orientacion: item?.orientacion ?? 'S/I',
      tipo: item?.tipo ?? 'S/I',
      m2total: item?.m2_total,
      m2totalFormat: item?.m2_total ? item?.m2_total.toString().replace('.', ',') : 'S/I',
      m2interior: item?.m2_interior,
      m2interiorFormat: item?.m2_interior ? item?.m2_interior.toString().replace('.', ',') : 'S/I',
      bonopie: item?.bono_pie ?? 'S/I',
      typology: item?.tipologia ?? 'S/I',
      reserva:
        process.env.REACT_APP_RESERVATION_FLAG !== 'false' &&
        (roles.includes('TEST') ||
          roles.includes('BROKERS') ||
          roles.includes('EJECUTIVO DE NEGOCIO') ||
          roles.includes('ADMIN')) ? (
          <CButton className="text-white" onClick={() => handleShowModal(item?.numero)}>
            Pre reservar
          </CButton>
        ) : null,
      _cellProps: { id: { scope: 'row' } },
    }
  })
}

export const formatInputPrice = (input) => {
  if (input === '') return ''

  let number

  if (typeof input === 'string') {
    number = parseFloat(input.replace(/[^\d.-]/g, ''))
  } else if (typeof input === 'number') {
    number = input
  } else {
    return ''
  }

  if (isNaN(number)) {
    return ''
  }

  const formattedNumber = number.toLocaleString('en-US')

  return formattedNumber
}

const formatRut = (input) => {
  if (input) {
    // Elimina cualquier carácter que no sea un dígito ni la letra 'k' o 'K'
    const cleanedRut = input.replace(/[^\dKk]/g, '')

    // Divide el RUT en parte numérica y dígito verificador
    const rutNumber = cleanedRut.slice(0, -1)
    const rutVerifier = cleanedRut.slice(-1).toUpperCase()

    // Formatea la parte numérica con puntos
    const formattedRutNumber = rutNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    // Une la parte numérica formateada con el dígito verificador
    const formattedRut = `${formattedRutNumber}-${rutVerifier}`

    return formattedRut
  } else {
    return ''
  }
}

// Construye las filas de la tabla de clientes con los input correspondientes
export const renderField = (item, index, control, editing, errors, postLabel, unitIndex) => {
  const inputName = unitIndex ? `${item?.label?.toLowerCase()}.${unitIndex}` : null

  let hasError
  if (unitIndex) {
    if (errors[item?.label?.toLowerCase()] && errors[item?.label?.toLowerCase()].length) {
      hasError = errors[item?.label?.toLowerCase()][Number(unitIndex)]
    } else {
      hasError = errors[item?.label?.toLowerCase()]
    }
  } else {
    hasError = errors[item?.label?.toLowerCase()]
  }

  return (
    <div key={index} className="input-container" style={item.size && { width: item.size }}>
      <CFormLabel className="small w-100">
        {item?.label} {!item?.allowNull && '*'}
      </CFormLabel>
      {item?.type === 'phone' ? (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          defaultValue={item?.value}
          rules={{
            required: !item?.allowNull,
            pattern: item?.pattern,
          }}
          render={({ field }) => (
            <div>
              <PhoneInput
                specialLabel={null}
                enableAreaCodes={false}
                disabled={!editing || item?.disabled}
                placeholder="+56 113 363 953"
                containerClass="phone-container"
                inputClass="phone-input"
                onChange={(phone) => {
                  field.onChange(phone)
                }}
                value={field.value}
              />
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      ) : item?.type === 'select' ? (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          defaultValue={item?.value}
          rules={{
            required: !item?.allowNull,
            pattern: item?.pattern,
          }}
          render={({ field }) => (
            <div>
              <CFormSelect
                {...field}
                className="input-client small"
                placeholder={`Seleccionar ${item?.label}`}
                disabled={!editing || item?.disabled}
                style={{ height: '30px' }}
                onChange={(e) => {
                  field.onChange(e)
                  if (item.onChange) {
                    item.onChange(e)
                  }
                }}
              >
                <option value="">Seleccionar</option>
                {item?.options?.map((option, optionIndex) => (
                  <option key={optionIndex} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </CFormSelect>
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      ) : item?.type === 'checkbox-group' ? (
        <div className="d-flex flex-wrap">
          {item?.options.map((option, optionIndex) => (
            <Controller
              key={optionIndex}
              name={`${item?.label?.toLowerCase()}.${option.label?.toLowerCase()}`}
              control={control}
              defaultValue={option.value}
              render={({ field }) => (
                <div className="d-flex align-items-center me-3">
                  <input
                    {...field}
                    type="checkbox"
                    disabled={!editing || item?.disabled}
                    style={{ height: '30px' }}
                    checked={field.value}
                  />
                  <label className="ms-1">{option.label}</label>
                  {errors[`${item?.label?.toLowerCase()}.${option.label?.toLowerCase()}`] && (
                    <span className="error text-danger">
                      {item?.errorMessage || 'Campo requerido'}
                    </span>
                  )}
                </div>
              )}
            />
          ))}
        </div>
      ) : item?.type === 'switch' ? (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          defaultValue={item?.value}
          rules={{
            required: !item?.allowNull,
            pattern: item?.pattern,
          }}
          render={({ field }) => (
            <div className="d-flex align-items-center">
              <CFormSwitch
                {...field}
                color="success"
                shape="pill"
                variant="opposite"
                className="mx-1"
                disabled={!editing || item?.disabled}
              />
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      ) : item?.type === 'file' ? (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          rules={{
            required: !item?.allowNull,
          }}
          render={({ field }) => (
            <div>
              <CFormInput
                {...field}
                type={item?.type}
                className="input-client small mb-2 px-2"
                placeholder={item?.label + (postLabel || '')}
                disabled={!editing || (item?.value && item?.disabled) || item?.hardDisabled}
                style={{ height: '35px' }}
                autoComplete="off"
                name={item?.key || item?.label?.toLowerCase()}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  item.onChange(e)
                }}
              />
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      ) : item.type === 'text-area' ? (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          defaultValue={item?.value}
          rules={{
            required: !item?.allowNull,
            pattern: item?.pattern,
            validate: item?.label?.toLowerCase() === 'rut' ? validateRUT : null,
          }}
          render={({ field }) => (
            <div>
              <CFormTextarea
                {...field}
                type={item?.type}
                className="input-client small"
                placeholder={item?.label + (postLabel || '')}
                disabled={!editing || (item?.value && item?.disabled) || item?.hardDisabled}
                style={{ height: '30px', maxHeight: '10rem' }}
                autoComplete="off"
                name={item?.key || item?.label?.toLowerCase()}
                value={
                  item?.formatPrice
                    ? formatInputPrice(field.value)
                    : item?.label.toLowerCase() === 'rut'
                    ? formatRut(field.value)
                    : field.value
                }
                onBlur={(e) => {
                  field.onBlur(e)
                  if (item?.formatPrice) {
                    if (e.target.value !== '') {
                      e.target.value = formatInputPrice(e.target.value)
                    }
                    if (item?.onBlur) {
                      item?.onBlur(e)
                    }
                  }
                }}
              />
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      ) : (
        <Controller
          name={inputName || item?.label?.toLowerCase()}
          control={control}
          defaultValue={item?.value}
          rules={{
            required: !item?.allowNull,
            pattern: item?.pattern,
            validate: item?.label?.toLowerCase() === 'rut' ? validateRUT : null,
          }}
          render={({ field }) => (
            <div>
              <CFormInput
                {...field}
                type={item?.type}
                className="input-client small"
                placeholder={item?.label + (postLabel || '')}
                disabled={!editing || (item?.value && item?.disabled) || item?.hardDisabled}
                style={{ height: '30px' }}
                autoComplete="off"
                name={item?.key || item?.label?.toLowerCase()}
                value={
                  item?.formatPrice
                    ? formatInputPrice(field.value)
                    : item?.label.toLowerCase() === 'rut'
                    ? formatRut(field.value)
                    : field.value
                }
                onBlur={(e) => {
                  field.onBlur(e)
                  if (item?.formatPrice) {
                    if (e.target.value !== '') {
                      e.target.value = formatInputPrice(e.target.value)
                    }
                    if (item?.onBlur) {
                      item?.onBlur(e)
                    }
                  }
                }}
              />
              {hasError && (
                <span className="error text-danger">{item?.errorMessage || 'Campo requerido'}</span>
              )}
            </div>
          )}
        />
      )}
    </div>
  )
}

function validateRUT(number) {
  if (typeof number !== 'string') {
    return false
  }

  const cleanNumber = number.replace(/[^0-9kK]/g, '')
  if (validate(cleanNumber)) {
    return true
  } else {
    return 'RUT inválido'
  }
}

// Submit client form
export const handleOnSubmit = async (
  clientData,
  data,
  editing,
  editedData,
  datos,
  setDatos,
  setEditing,
  setEditedData,
  dataType,
  id,
  clientType,
) => {
  if (editing) {
    const updatedDatos = datos.map((item) => {
      const label = item?.label
      let newValue = editedData[label] || item?.value

      return { ...item, value: newValue }
    })

    // Actualizar datos con los nuevos valores
    setDatos(updatedDatos)
  }

  // Cambiar entre modo edición y visualización
  setEditing(!editing)

  // Restablecer los datos editados cuando se cambia de modo
  if (!editing) {
    setEditedData({})
  } else {
    const dataUpdateClient =
      clientType.toLowerCase() === 'lyt'
        ? formatUpdateClientLYTData(data, dataType)
        : formatUpdateClientData(data, dataType)

    // Checkear si cambio la info para no llamar a updateClient sin que sea necesario
    if (!areDataEqual(clientData, dataUpdateClient, clientType)) {
      return await updateClient(dataUpdateClient, id, clientType)
    }
  }
}

export const handleOnSubmitUser = async (
  data,
  editing,
  editedData,
  datos,
  setDatos,
  setEditing,
  setEditedData,
  dataType,
  userEmail,
) => {
  if (editing) {
    const updatedDatos = datos.map((item) => {
      const label = item?.label
      let newValue = editedData[label] || item?.value

      return { ...item, value: newValue }
    })

    // Actualizar datos con los nuevos valores
    setDatos(updatedDatos)
  }

  // Cambiar entre modo edición y visualización
  setEditing(!editing)

  // Restablecer los datos editados cuando se cambia de modo
  if (!editing) {
    setEditedData({})
  } else {
    const dataUpdateUser = formatUpdateClientData(data, dataType)

    return await updateUserProfile(dataUpdateUser, userEmail)
  }
}

const areDataEqual = (clientData, dataUpdateClient, clientType) => {
  let areEqual = true

  const compareData = dataUpdateClient.user_data
    ? dataUpdateClient.user_data
    : clientType.toLowerCase() === 'lyt'
    ? dataUpdateClient.iva_data
    : dataUpdateClient.investor_data

  // Verificar si ambos objetos son null o undefined
  if (!clientData || !dataUpdateClient) {
    return true
  }

  // Obtener las claves (propiedades) de ambos objetos
  const keysClientData = Object.keys(clientData)
  const keysDataUpdateClient = Object.keys(compareData)

  // Comprobar si las claves de dataUpdateClient no coinciden con las de clientData
  for (const key of keysDataUpdateClient) {
    if (!keysClientData.includes(key)) {
      continue
    }

    // Si hay una clave que coincide, comparar los valores de ambas claves
    if (compareData[key] !== undefined && clientData[key] !== compareData[key]) {
      areEqual = false
    }
  }

  return areEqual
}

export const formatCreateClientData = (personalData, financialData, creditData, objectiveData) => {
  const transformedData = {}

  if (personalData) {
    // Para enviar el rut solo con "-", sin puntos
    const cleanRut = personalData['rut']?.replaceAll('.', '').replaceAll('-', '')
    const finalRut =
      cleanRut.slice(0, cleanRut.length - 1) +
      '-' +
      cleanRut.slice(cleanRut.length - 1, cleanRut.length)
    transformedData.user_data = {
      first_name: personalData['nombre'],
      last_name: personalData['apellido'],
      age: personalData['edad'],
      birth_date: personalData['fecha de nacimiento'],
      identity_document: finalRut,
      marital_status: personalData['estado civil'],
      profession: personalData['profesión'],
      country: personalData['país'],
      city: personalData['ciudad'],
      address: personalData['calle'],
      street_number: personalData['numero de calle'],
      phone:
        personalData['teléfono'] && !personalData['teléfono'].includes('+')
          ? '+' + personalData['teléfono']
          : personalData['teléfono'] || null,
      email: personalData['email'],
      create_date: new Date(),
      update_date: new Date(),
      last_login_date: null,
      creation_source: 'Backoffice',
      legal_entity: null,
      avatar: null,
    }
  }

  if (financialData && creditData && objectiveData) {
    transformedData.investment_data = {
      employment_place: financialData['lugar de trabajo'] || null,
      contract_type: financialData['contrato'] || null,
      seniority: financialData['antiguedad en meses'] || null,
      bank: financialData['banco'] || null,
      account_type: financialData['tipo de cuenta'] || null,
      net_income: financialData['ingresos líquidos']?.replaceAll(',', '') || null,
      additional_income: financialData['ingresos adicionales']?.replaceAll(',', '') || null,
      savings_capacity: financialData['capacidad de ahorro'] || null,
      savings: financialData['ahorro']?.replaceAll(',', '') || null,
      mortgage_loan: creditData['credito hipotecario'] || null,
      mortgage_payment_year: creditData['años pagado crédito'] || null,
      consumer_loan: creditData['crédito consumo'] || null,
      consumer_payment_year: creditData['años de consumo de crédito'] || null,
      interest_area: objectiveData['zona de interés'] || null,
      where_you_invest_today: JSON.stringify(objectiveData['¿en qué inviertes hoy?']) || null,
    }
  }

  return transformedData
}

export const formatCreateClientLYTData = (personalLYTData, projectData) => {
  const transformedData = {}

  if (personalLYTData) {
    transformedData.user_data = {
      first_name: personalLYTData['nombre'],
      last_name: personalLYTData['apellido'],
      identity_document: personalLYTData['rut'],
      marital_status: personalLYTData['estado civil'],
      profession: personalLYTData['profesión'],
      address: personalLYTData['domicilio'],
      country: personalLYTData['pais'],
      phone: personalLYTData['teléfono'],
      email: personalLYTData['email'],
    }
  }

  if (projectData) {
    transformedData.lyt_data = {
      project_name: projectData['nombre del proyecto'],
      property_quantity: projectData['cantidad de inmuebles'],
      unit_tower_number: projectData['numero de cada unidad y torre'],
      project_location: projectData['ubicación del proyecto'],
      total_amount_uf: projectData['monto total en uf (incluyendo bono pie)'],
      apartment_financing: projectData['financiamiento por dpto (%)'],
      apartment_down_payment_uf: projectData['pie por dpto (sin considerar bono pie) en uf o clp'],
      down_payment_bonus: projectData['% bono pie'],
      structuring_date: projectData['fecha de escritura (o tentativa)'],
    }
  }

  return transformedData
}

export const formatUpdateClientLYTData = (data, dataType) => {
  const transformedData = {}

  if (dataType === 'personalData') {
    transformedData.user_data = {
      first_name: data['nombre'] || null,
      last_name: data['apellido'] || null,
      identity_document: data['rut'] || null,
      marital_status: data['estado civil'] || null,
      profession: data['profesión'] || null,
      address: data['domicilio'] || null,
      country: data['pais'] || null,
      phone: data['teléfono'] || null,
      email: data['email'] || null,
    }
  }

  if (dataType === 'lytData') {
    transformedData.iva_data = {
      project_name: data['nombre del proyecto'] || null,
      property_quantity: data['cantidad de inmuebles'] || null,
      unit_tower_number: data['numero de cada unidad y torre'] || null,
      project_location: data['ubicación del proyecto'] || null,
      total_amount_uf: data['monto total en uf (incluyendo bono pie)'] || null,
      apartment_financing: data['financiamiento por dpto (%)'] || null,
      apartment_down_payment_uf: data['pie por dpto (sin considerar bono pie) en uf o clp'] || null,
      down_payment_bonus: data['% bono pie'] || null,
      structuring_date: data['fecha de escritura (o tentativa)'] || null,
    }
  }

  return transformedData
}

export const formatUpdateClientData = (data, dataType) => {
  const transformedData = {}

  switch (dataType) {
    case 'personalData':
      // Para enviar el rut solo con "-", sin puntos
      const cleanRut = data['rut']?.replaceAll('.', '').replaceAll('-', '')
      const finalRut =
        cleanRut.slice(0, cleanRut.length - 1) +
        '-' +
        cleanRut.slice(cleanRut.length - 1, cleanRut.length)
      transformedData.user_data = {
        first_name: data['nombre'],
        last_name: data['apellido'],
        age: data['edad'],
        birth_date: data['fecha de nacimiento'],
        identity_document: finalRut,
        marital_status: data['estado civil'],
        profession: data['profesión'],
        country: data['país'],
        city: data['ciudad'],
        address: data['calle'],
        street_number: data['numero de calle'],
        phone:
          data['teléfono'] && !data['teléfono']?.includes('+')
            ? '+' + data['teléfono']
            : data['teléfono'] || null,
        email: data['email'],
      }
      break
    case 'financialData':
      transformedData.investor_data = {
        employment_place: data['lugar de trabajo'],
        contract_type: data['contrato'],
        seniority: data['antiguedad en meses'],
        bank: data['banco'],
        account_type: data['tipo de cuenta'],
        net_income: data['ingresos líquidos']?.replaceAll(',', ''),
        additional_income: data['ingresos adicionales']?.replaceAll(',', ''),
        savings_capacity: data['capacidad de ahorro'],
        savings: data['ahorro']?.replaceAll(',', ''),
      }
      break
    case 'creditData':
      transformedData.investor_data = {
        mortgage_loan: data['credito hipotecario'],
        mortgage_payment_year: data['años pagado crédito'],
        consumer_loan: data['crédito consumo'],
        consumer_payment_year: data['años de consumo de crédito'],
      }
      break
    case 'objectiveData':
      transformedData.investor_data = {
        interest_area: data['zona de interés'],
        where_you_invest_today: JSON.stringify(data['¿en qué inviertes hoy?']),
      }
      break
    case 'personalUserData':
      // Para enviar el rut solo con "-", sin puntos
      const cleanUserRut = data['rut']?.replaceAll('.', '').replaceAll('-', '')
      const finalUserRut =
        cleanUserRut.slice(0, cleanUserRut.length - 1) +
        '-' +
        cleanUserRut.slice(cleanUserRut.length - 1, cleanUserRut.length)

      transformedData.first_name = data['nombre']
      transformedData.last_name = data['apellido']
      transformedData.identity_document = finalUserRut
      transformedData.personal_email = data['email personal'] || null
      transformedData.birthdate = data['fecha de nacimiento'] || null
      transformedData.country = data['país'] || null
      transformedData.city = data['ciudad'] || null
      transformedData.phone =
        data['teléfono'] && !data['teléfono'].includes('+')
          ? '+' + data['teléfono']
          : data['teléfono'] || null
      transformedData.address = data['dirección'] || null
      transformedData.postal_code = data['código postal'] || null
      break
    case 'financialUserData':
      transformedData.bank = data['banco'] || null
      transformedData.account_type = data['tipo de cuenta bancaria'] || null
      transformedData.account_number = data['número de cuenta'] || null
      break
    default:
      break
  }

  return transformedData
}

// Legal Tributario

export const createFormatTaxClient = (personalData) => {
  const transformedData = {}

  if (personalData) {
    transformedData.user_data = {
      broker_email: personalData['email broker'],
      client_name: personalData['nombres del cliente'],
      client_lastname: personalData['apellidos del cliente'],
      client_email: personalData['email del cliente'],
      phone_number:
        personalData['teléfono del cliente'] && !personalData['teléfono del cliente'].includes('+')
          ? '+' + personalData['teléfono del cliente']
          : personalData['teléfono del cliente'] || null,
      legal_document:
        personalData['adjuntar documento pdf de factura de compra de unidad (opcional)'],
      quantity_unit: personalData['cantidad de unidades'],
      quantity_uf: personalData['cantidad de uf'],
    }
  }
  return transformedData.user_data
}

// RESERVATION

export const parseReservation = (
  data,
  handleClickLink,
  handleClickID,
  handleClickAction,
  handleCompleteReservation,
  handleNavigate,
  roles,
) => {
  const parsedData = data.map((item) => {
    let reservationIcon
    switch (item?.reservation_state?.toLowerCase()) {
      case 'paid':
        reservationIcon = 'mingcute:check-line'
        break
      case 'cancelled':
        reservationIcon = 'charm:cross'
        break
      case 'overdue':
        reservationIcon = 'ph:warning-bold'
        break
      case 'pending':
        reservationIcon = 'ph:timer-bold'
        break
      case 'reassigned':
        reservationIcon = 'mi:undo'
        break
      case 'real_estate':
        reservationIcon = 'pepicons-pop:house'
        break
      case 'refund':
        reservationIcon = 'mi:repeat'
        break
      default:
        break
    }

    let units = ''

    for (const unit of item?.units_id) {
      units += unit + ' - '
    }

    units = units.slice(0, units.length - 3)

    const brokerName = item?.broker_email?.split('@')[0].split('.')

    const showSellingProcessAction =
      process.env.REACT_APP_SELLING_PRICE_FLAG !== 'false' || roles.includes('ADMIN')

    return {
      fecha: <p style={{ width: '4rem' }}>{item?.created_at?.split(' ')[0]}</p> ?? 'S/I',
      broker: item?.broker_email ? `${brokerName[0] ?? ''} ${brokerName[1] ?? ''}` : 'S/I',
      proyecto: item?.project_name,
      unidades: units ?? 'S/I',
      nombrecliente: item?.client_name ?? 'S/I',
      rutcliente: item?.government_id ?? 'S/I',
      inmobiliaria: item?.real_estate_name ?? 'S/I',
      monto: `${item?.currency?.toUpperCase()} ${formatPrice(item?.amount, false)}`,
      reservationId: (
        <div className="d-flex justify-content-center">
          <CButton
            onClick={() => {
              handleClickID(item?.reservation_id)
            }}
            className="payment-link"
            style={{ width: '3rem', height: '2.5rem' }}
          >
            <span
              data-icon="ph:copy-bold"
              className="text-white iconify"
              style={{ width: '16px', height: '16px' }}
            ></span>
          </CButton>
        </div>
      ),
      link: (
        <div className="d-flex justify-content-center">
          <CButton
            disabled={
              item?.reservation_state?.toLowerCase() !== 'pending' &&
              item?.reservation_state?.toLowerCase() !== 'paid'
            }
            onClick={() => {
              handleClickLink(item?.link_payment)
            }}
            className="payment-link"
            style={{ width: '3rem', height: '2.5rem' }}
          >
            <span
              data-icon="ph:link-bold"
              className="text-white iconify"
              style={{ width: '16px', height: '16px' }}
            ></span>
          </CButton>
        </div>
      ),
      estado: (
        <CTooltip content={parseReservationStatus(item?.reservation_state?.toLowerCase())}>
          <CButton
            className={`state-icon ${item?.reservation_state?.toLowerCase()}`}
            style={{ width: '3rem', height: '2.5rem' }}
          >
            <span
              data-icon={reservationIcon}
              className="text-white iconify"
              style={{ width: '16px', height: '16px' }}
            ></span>
          </CButton>
        </CTooltip>
      ),
      actions: (
        <CDropdown>
          <CDropdownToggle
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0.35rem 0',
            }}
            color="secondary"
          >
            <CIcon icon={cilOptions} />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem
              name="detail"
              onClick={() => {
                handleClickAction(item?.reservation_id)
              }}
            >
              Ver detalle
            </CDropdownItem>
            <CDropdownItem
              name="complete-reservation"
              onClick={() => {
                handleCompleteReservation(item?.reservation_id)
              }}
            >
              Completar pre-reserva
            </CDropdownItem>
            {showSellingProcessAction && (
              <CDropdownItem
                name="complete-reservation"
                onClick={() => {
                  handleNavigate('/proceso-venta')
                }}
              >
                Proceso de venta
              </CDropdownItem>
            )}
          </CDropdownMenu>
        </CDropdown>
      ),
      filterState: item?.reservation_state?.toLowerCase() ?? '',
    }
  })

  return parsedData.flat()
}

export const parseReservationDetail = (data) => {
  if (data) {
    return [
      {
        label: 'Reserva',
        value: data.reservation_id,
      },
      {
        label: 'Proyecto',
        value: data.project_name,
      },
      {
        label: 'Inmobiliaria',
        value: data.real_estate_name,
      },
      {
        label: 'Broker',
        value: data.broker_email,
      },
      {
        label: 'Cliente',
        value: data.client_name,
      },
      {
        label: 'RUT Cliente',
        value: data.government_id,
      },
      {
        label: 'Link de pago',
        value: (
          <a href={data.link_payment} rel="noreferrer" target="_blank">
            payment link
          </a>
        ),
      },
      {
        label: 'Estado',
        value: data.reservation_state,
      },
      {
        label: 'Fecha',
        value: data.created_at?.includes('T') ? data.created_at.replace('T', ' ') : data.created_at,
      },
      {
        label: 'Monto',
        value: data.amount,
      },
    ]
  }
}

export const parseReservationStatus = (status) => {
  const parseOptions = {
    pending: 'Pendiente',
    paid: 'Pagada',
    refund: 'Devuelto al cliente',
    cancelled: 'Anulada',
    real_estate: 'Inmobiliaria',
    reassigned: 'Reasignada',
    overdue: 'Vencida',
  }

  if (status) {
    return parseOptions[status]
  } else {
    return ''
  }
}

export const downloadFile = (data, fileName = 'Documento', docType = 'pdf') => {
  const link = document.createElement('a')
  link.href = `data:application/octet-stream;base64,${data}`
  link.download = fileName + '.' + docType
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const parseCesionesResponse = (data, handleClickReservation) => {
  const finalData = []

  for (const item of data) {
    const parsedItem = { ...item }
    parsedItem.pre_reservation = (
      <CButton
        className="text-white"
        style={{ fontSize: '0.75rem', minWidth: '6rem' }}
        onClick={() => handleClickReservation(item)}
        disabled={item.estado.toLowerCase() !== 'disponible'}
      >
        Pre reservar
      </CButton>
    )

    finalData.push(parsedItem)
  }

  return finalData
}

export const handlerSendErrorNotification = async (error) => {
  try {
    const userEmail = getUserEmail()

    const statusCode = error?.response?.status
    const errorMessage = error?.response?.data?.detail || error?.message
    const errorSection = window.location.href

    const body = { statusCode, errorMessage, errorSection, userEmail }

    await sendErrorNotification(body)
  } catch (error) {
    console.error(error)
  }
}
