import { CCol, CProgress, CRow } from '@coreui/react'
import React from 'react'

const Progress = () => {
  const items = [
    {
      title: 'Ventas realizadas',
      value: '$ 30.000',
      percent: 100,
      color: 'success',
      showPercent: false,
    },
    {
      title: 'Clientes nuevos',
      value: '100 Views',
      percent: 60,
      color: 'primary',
      showPercent: false,
    },
    {
      title: 'Clientes activos',
      value: '80 Users',
      percent: 40,
      color: 'warning',
      showPercent: false,
    },
    {
      title: 'Equipo',
      value: 'Ejecutivo Lider 1',
      percent: 100,
      color: 'danger',
      showPercent: false,
    },
    { title: 'Meta mensual', value: '$ 40.000', percent: 80, color: 'success', showPercent: true },
  ]
  return (
    <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
      {items.map((item, index) => (
        <CCol className="mb-sm-2 mb-0" key={index}>
          <div className="text-medium-emphasis">{item.title}</div>
          <strong>
            {item.value} {item.showPercent && `(${item.percent}%)`}
          </strong>
          <CProgress thin className="mt-2" color={item.color} value={item.percent} />
        </CCol>
      ))}
    </CRow>
  )
}

export default Progress
