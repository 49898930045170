/* eslint-disable prettier/prettier */

export const iconList = {
  1: 'cilMap',
  2: 'cilMoney',
  3: 'cilHandshake',
  4: 'cilGift',
  5: 'cilStorage',
  6: 'cilGraph',
  7: 'cilChartPie',
  8: 'cilSearch',
  9: 'cilInfo',
  10: 'cilGlobeAlt',
}

export const stockColumns = [
  {
    key: 'unidad',
    label: 'Unidad',
    _props: { scope: 'col' },
  },
  {
    key: 'etapa',
    label: 'Etapa',
    _props: { scope: 'col' },
  },
  {
    key: 'typology',
    label: 'Tipología',
    _props: { scope: 'col' },
  },
  {
    key: 'valorListaFormat',
    label: 'Valor Lista',
    _props: { scope: 'col', className: 'text-truncate', style: { minWidth: '120px' } },
  },
  {
    key: 'valorConDescuentoFormat',
    label: 'Valor + Desc.',
    _props: { scope: 'col', className: 'text-truncate', style: { minWidth: '120px' } },
  },
  {
    key: 'porcentajeDescuento',
    label: 'Desc. (%)',
    _props: { scope: 'col', className: 'text-truncate', style: { minWidth: '90px' } },
  },
  {
    key: 'orientacion',
    label: 'Orientación',
    _props: { scope: 'col' },
  },
  {
    key: 'tipo',
    label: 'Tipo',
    _props: { scope: 'col' },
  },
  {
    key: 'm2totalFormat',
    label: 'M² Total',
    _props: { scope: 'col', className: 'text-truncate' },
  },
  {
    key: 'm2interiorFormat',
    label: 'M² Interior',
    _props: { scope: 'col', className: 'text-truncate' },
  },
  {
    key: 'bonopie',
    label: 'Bono Pie',
    _props: { scope: 'col', className: 'text-truncate', style: { minWidth: '90px' } },
  },
  {
    key: 'reserva',
    label: '',
    _props: { scope: 'col' },
  },
]

export const stockLinks = {
  'SANTIAGO 3':
    'https://docs.google.com/spreadsheets/d/1TIg3tEZBikT6B8iX-6RFozel1eiWdb2n/edit#gid=1766787885',

  'DUO CARVAJAL':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=844339839',

  'NUEVA MAIPÚ':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=316250306',

  'EDIFICIO TOCORNAL':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=11418143',

  'INC 129':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=482184879',

  'JAIME REPULLO':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=1813798916',

  'MIRADOR 950':
    'https://docs.google.com/spreadsheets/d/14jUibXFsFi89mTMqtIi_uD-cU0VpEAQ_/edit#gid=115717805',

  'ANDES REPUBLICA':
    'https://docs.google.com/spreadsheets/d/1e28T4WPYxHWfAfbT94Zoo_qxXcefhHiA/edit#gid=2108702915',

  'ANDES MACUL':
    'https://docs.google.com/spreadsheets/d/1K3r5iVrS7-sJMLnllsbN-5ndZL63S_PQ/edit#gid=1775071422',

  'ANDES ÑUÑOA':
    'https://docs.google.com/spreadsheets/d/1APIoqWXeQB3VdQvAaDxa-2lK2KcVBINy/edit#gid=1739248708',

  'ARGOMEDO 461':
    'https://docs.google.com/spreadsheets/d/1ehfHrv8fhafsjdq-h1_afuPivt0j61GC/edit#gid=1190119330',

  'FUENTES DE PRATS':
    'https://docs.google.com/spreadsheets/d/1jy_iUFCVtz7wSSCzQwQVavva5UJ_z96M/edit#gid=849430240',
  'NEW CENTER':
    'https://docs.google.com/spreadsheets/d/1JitHqPTLXZOlblDvY5cLK6MvJCgH7CH0/edit#gid=951728340',
  ROOSEVELT:
    'https://docs.google.com/spreadsheets/d/1Mfr48S2GFPjbsCEH3jaySYJn8M79wcjc/edit#gid=262346113',
  'ROZAS CONDELL':
    'https://docs.google.com/spreadsheets/d/18pOmyopdeLMhn8-_1NYLnW6BVSGC1ngV/edit#gid=617223135',
  'MIRADOR OCEANICO ':
    'https://docs.google.com/spreadsheets/d/1X9YA-hiyG6OT6lqdf5JhgzbgEVNtDIdF/edit#gid=630737101',
  PEUMAYEN:
    'https://docs.google.com/spreadsheets/d/1KLtOmaIXBcekdritTjeVE7YavQj04iJM/edit#gid=862708020',

  'AIRES LA FLORIDA ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'FERROPARQUE 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'LOMAS DE PUYAI 2A':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'LOMAS DE PUYAI 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'LOMAS DE PUYAI 1B':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'NEO AZAPA ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'NEO AZAPA 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'NEO AZAPA 3':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'EDIFICIO HA':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CONECTA HUECHURABA ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CONECTA HUECHURABA 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CONECTA HUECHURABA 3':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CICCARELLI 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'BOSQUEMAR ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'BOSQUEMAR 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'AIRES LA FLORIDA 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'URBAN LA FLORIDA':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'URBAN LA FLORIDA 2 ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CUMBRES DE PEÑUELAS ':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'CUMBRES DE PEÑUELAS 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'VISTA HERRADURA 2':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'URBAN SANTIAGO':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  FERROPARQUE:
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'EDIFICIO BEZANILLA':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  TERRATOLTÉN:
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',
  'ALTO SAN JOAQUIN':
    'https://docs.google.com/spreadsheets/d/1svr0OlXzWGbyEY0aUY4vYD97aHr0-pZz/edit#gid=1803393361',

  'LIVE +':
    'https://docs.google.com/spreadsheets/d/1r3er1Mnjr-EmB_Nqdw0NwSFdfGoYFfX9/edit#gid=1478873603',
  NEOSOLIER:
    'https://docs.google.com/spreadsheets/d/11Ti2QUqbijiyoj_pDXVZVDHYKm96Cf5Z/edit#gid=1970609878',

  'PARQUE GERMANIA':
    'https://docs.google.com/spreadsheets/d/17sNanPVKA5xbJMgiMP93kfV3BHXTvSZC/edit#gid=1510338752',

  'NUEVA ANDRES BELLO 1826':
    'https://docs.google.com/spreadsheets/d/133NdiLu18TKkCH0IwDdDeyMFMaW3DIPQ/edit#gid=1241219226',
  'LIRA 254':
    'https://docs.google.com/spreadsheets/d/1qisnkTWZn8T07-MySW4EBM95LbehKrjV/edit#gid=1141422522',
  'GUILLERMO MANN 1401':
    'https://docs.google.com/spreadsheets/d/1EyxyfviXcV2kFkZoCfHivSoA4xKGWdVx/edit#gid=230670221',
  'ROSAS 1444':
    'https://docs.google.com/spreadsheets/d/1Lu1wDZr2wGJi7Hl_Uxb2mcJCP6NSlImM/edit#gid=545728228',
  'SANTA ELENA 1670':
    'https://docs.google.com/spreadsheets/d/1R9UdiRljvkAj6jc1vjK5pRRnMrW4g5kf/edit#gid=734297305',
  'ALAMEDA 4719':
    'https://docs.google.com/spreadsheets/d/1wdnlHARc9C3qunSTx7YkdHsLsFyTYroV/edit#gid=798561051',
  'VICUÑA MACKENNA 1432':
    'https://docs.google.com/spreadsheets/d/1rcA2pSsP4sQSEhtyWUjN1V3RYPoRLZQX/edit#gid=1622453233',
  'MAPOCHO 3521':
    'https://docs.google.com/spreadsheets/d/10bV_VsTR-dqc2E0mKrrWahkZVfajQgCI/edit#gid=740490405',
  'FROILAN ROA 5731':
    'https://docs.google.com/spreadsheets/d/1addu2HPC8EwRq2e6I4CU_LWCzb4sAz5J/edit#gid=1949426136',
  'INDEPENDENCIA 4745':
    'https://docs.google.com/spreadsheets/d/1DnUDE9xDCGKOD0R_tT5Kt5mlMsSIZ2hD/edit#gid=1721166208',

  ARMONIA:
    'https://docs.google.com/spreadsheets/d/1CY-UTELXWPJCdV7PRWh3jCCt-2koZ6Zj/edit#gid=1095532348',
  VISTAPARK:
    'https://docs.google.com/spreadsheets/d/1ZmntXA2aBnBKP3J7UE5-y2W9TNF_M0p2/edit#gid=778147294',
  MORE: 'https://docs.google.com/spreadsheets/d/1MEwsjiN_02gsoyi7-MPSYFhPYspOTS5a/edit#gid=241916202',
  MOVE: 'https://docs.google.com/spreadsheets/d/1lUJ44463Ztx2Y9MgLw_qLY6Wju63GJFM/edit#gid=1823942487',
  EQUILIBRIO:
    'https://docs.google.com/spreadsheets/d/19Olv6mYnTYCJSllsyI1W1z5SmddxEiw_/edit#gid=1715704970',
  FAN: 'https://docs.google.com/spreadsheets/d/1xQYh8OOhVGI_PdDeE-RtmHAj8opGJYn6/edit#gid=1733080973',
  O2: 'https://docs.google.com/spreadsheets/d/10Dboju5-EHNi2BC65NBXvi0WU8cfoSSU/edit#gid=987624838',
  LIVE: 'https://docs.google.com/spreadsheets/d/19UgNCxXAVtvwUYyB6FqvzzbTNpUGmH2v/edit#gid=1585117818',

  'LOS NARANJOS':
    'https://docs.google.com/spreadsheets/d/1tQ7H551jcYes0BKGM2-QoVKtzqHWhej3LJf9pI-9HzM/edit#gid=0',

  'ECO CERRILLOS':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO FLORIDA II':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO QUILIN II':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO QUILIN I':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO IRARRAZAVAL':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO FLORIDA':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO ESPACIOS':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO ESPAÑA':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO ENCALADA':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO EGAÑA PONIENTE':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=912144488',
  'ECO EGAÑA ORIENTE':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO EGAÑA NORTE':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO ESTACION':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO ARAUCO':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO VALDÉS I':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO VALDÉS II E1':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO VALDÉS II E2':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=1961943184',
  'ECO VALDÉS I E1':
    'https://docs.google.com/spreadsheets/d/1ShVTDiLqwt5gWZSY2LEn5YDdM2nWaazD/edit#gid=912144488',

  'NEW EGAÑA':
    'https://docs.google.com/spreadsheets/d/1p80bb616cCZI-htQr6qQnVfgblO3BVoH/edit#gid=1955787284',
  'FEEL GOOD':
    'https://docs.google.com/spreadsheets/d/1mJ2BSNM6o91wRARVGim1Dsqq9tNT3J4R/edit#gid=1823678294',
  'DISTRITO PARQUE':
    'https://docs.google.com/spreadsheets/d/1W-ukT6chQM_C5DnHIngruoW2WGD7Bo3C/edit#gid=378092228',
  'DISTRITO BUSTAMANTE':
    'https://docs.google.com/spreadsheets/d/150UzEclFV7y92y35sJ5nDP-L4UAaqX34/edit#gid=1629904832',
  TERRA:
    'https://docs.google.com/spreadsheets/d/1PNDa54b4AP1G7_IyA4GQB6QfoIb6uYgc/edit#gid=1790876773',

  'VALLE ORIENTE':
    'https://docs.google.com/spreadsheets/d/1mP-oZFQOyStCT21_JW5a9HmSNUr0Qb8O/edit#gid=1377019025',

  'PARQUE DEL SUR':
    'https://docs.google.com/spreadsheets/d/1bD5Xcg86LRYgEmintl8MW-geC6BKZCwJ/edit#gid=1578062500',
  'SAN NICOLAS':
    'https://docs.google.com/spreadsheets/d/1bD5Xcg86LRYgEmintl8MW-geC6BKZCwJ/edit#gid=1578062500',

  VITRIO:
    'https://docs.google.com/spreadsheets/d/1deB5JFj3XWvUT1zVxsfQs-qgJR6X2_q2/edit#gid=1539811363',
  VERTICE:
    'https://docs.google.com/spreadsheets/d/1dh5EWhQzZDTRuwUFTPGd0BOsW9Ne8qZv/edit#gid=1001100965',
  VERTIENTES:
    'https://docs.google.com/spreadsheets/d/1jt9BgRBi4bbXOBhTmcd4DmFZiB_YtQEt/edit#gid=451474928',
  RODA: 'https://docs.google.com/spreadsheets/d/1Ax4E0y2-hum7y9X0wRYm79_9V0AI7S0x/edit#gid=302966993',
  'MERIDIANO - VIÑA DEL MAR':
    'https://docs.google.com/spreadsheets/d/1WatSGdryrO3Z669Nv6AgV-jWG1Yvd_2J/edit#gid=715955721',
  MAGNO:
    'https://docs.google.com/spreadsheets/d/1Sz7tKy6FKpdqAfYxsettaAxLdN9hhO3t/edit#gid=380717778',
  'LIBERTAD 51':
    'https://docs.google.com/spreadsheets/d/1H3I_BNN9Oy32Z2YfcNWsXiRbDgb-gFRe/edit#gid=222091985',
  EBANO:
    'https://docs.google.com/spreadsheets/d/1Pax77s_kfn1i8F1SkmOzSZBxl_or59Uk/edit#gid=311238616',
  BYTE: 'https://docs.google.com/spreadsheets/d/1PKfnAG8CgGaET8kl72o7OHfCnanAmrh4/edit#gid=16078779',
  AQUA: 'https://docs.google.com/spreadsheets/d/1ZLK2CpRXE9HQjrfbn5P2QHvtTRNHUjip/edit#gid=548917642',
  ANCORA:
    'https://docs.google.com/spreadsheets/d/1y8nMzfKGAdjZ3BUOhe7R6HqHClw1Xlun/edit#gid=139940285',

  BELLAVISTA:
    'https://docs.google.com/spreadsheets/d/1VG8AZkqkjW2dqpf4gk1W33fZrHxeyY_n/edit#gid=1803027217',
  'CENTENARIO 1':
    'https://docs.google.com/spreadsheets/d/1sB9eVkppFif0LXXAu3ORDetvUf2YrhfB/edit#gid=1918529406',
  'CENTENARIO 2':
    'https://docs.google.com/spreadsheets/d/1sMHMTfslKsOQjpIT-o9vDwKqXh-XHerS/edit#gid=783169305',
  'FROILÁN ROA':
    'https://docs.google.com/spreadsheets/d/11Wn7Hx1bXf0wXLvEY4C6Eq-MuPEcqORh/edit#gid=1850285243',
  'LOS ALERCES':
    'https://docs.google.com/spreadsheets/d/1JCyzLFL_JCInTrz1OTlP4ScOXYYqyICY/edit#gid=1658560129',
  'SANTA ISABEL':
    'https://docs.google.com/spreadsheets/d/1ZfX0p2uFLcSeZOit9bvuCuSASfshib79/edit#gid=503353490',
  'SANTA ROSA':
    'https://docs.google.com/spreadsheets/d/1T_LgsUcA_SFd-0rthgA4qxbZfaxK39h9/edit#gid=1060251245',
  TOCORNAL:
    'https://docs.google.com/spreadsheets/d/1TW-36Xr5CqV73PjHQBAc64E7KOmbwxQS/edit#gid=1540305042',
  'VICUÑA MACKENNA':
    'https://docs.google.com/spreadsheets/d/17ZEe-4eTAR-LL7FDJ_j9s57h5OsJgDOU/edit#gid=597081739',
  'NUEVA ANDRES BELLO':
    'https://docs.google.com/spreadsheets/d/19HuS9eQJ3ivMpS_FHq_uAMai98eY-0Gi/edit#gid=1085064463',
  INDEPENDENCIA:
    'https://docs.google.com/spreadsheets/d/1DnUDE9xDCGKOD0R_tT5Kt5mlMsSIZ2hD/edit#gid=1721166208',

  'BULNES 138':
    'https://docs.google.com/spreadsheets/d/1LBN4sDKiZg6ptRVjaXhQgDXuLCEEgbKK/edit#gid=1545886947',
  'MIRADOR DEL BIO BIO':
    'https://docs.google.com/spreadsheets/d/1fnI-U3i8DkX-VlHTW_kwmGHAD505-MIn/edit#gid=954229533',
  'MAIPU 1256':
    'https://docs.google.com/spreadsheets/d/1cIdzVmXvLrIEYE7HuAwqeLJdQVZupDLU/edit#gid=665894757',

  'LAS HIGUERAS':
    'https://docs.google.com/spreadsheets/d/1K0Ue5kGSYbX9XdwFX-3vYRIMOaNyOkjb/edit#gid=1290575192',
  'ALTOS DE COLLAO':
    'https://docs.google.com/spreadsheets/d/1oaqaW1CuujbVpFv4JxZZVMxxxF0FFSMT/edit#gid=1797964553',

  CAPITANES:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=1889342627',
  MATTE:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=167207014',
  OWN: 'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=489655320',
  SUECIA:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=2060060888',
  OKINAWA:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=1823305139',
  INDI: 'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=1395688731',
  'LIVING TOWN':
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=551839329',
  SANTO:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=1736049895',
  MOOD: 'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=151385204',
  ARGOMEDO:
    'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=235625994',
  BOLD: 'https://docs.google.com/spreadsheets/d/1Uke8tSPaSYH_qgZwUGvudnR7f10CFC8I/edit#gid=2063562546',

  'PLAZA QUILICURA':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'VISTA COSTANERA':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'PINTOR CICARELLI II':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'PINTOR CICARELLI I':
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',
  'JARDINES DE ALVARADO':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'APOSTOL SANTIAGO':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'PLAZA CERVANTES I':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'PLAZA CERVANTES II':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  'VISTA LLACOLÉN B':
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',
  'ALTO BUZETA':
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',
  'TRINIDAD III':
    'https://docs.google.com/spreadsheets/d/1auiwnme6jZXCvV_ZwoTWX_h_ScQMEoPH/edit#gid=1697939422',
  CÁCERES:
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',
  'MIRADOR MAPOCHO':
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',
  'VISTA RELONCAVÍ':
    'https://docs.google.com/spreadsheets/d/1WD3AxwlxUB10w361wqq3yYbiYoS8mc89/edit#gid=1314342537',

  'ALTOS DE COCHOLGUE II':
    'https://docs.google.com/spreadsheets/d/1GBvGFPc9uYJJjALyGZxCDSto111OBlpx/edit#gid=81565953',
  'ALTOS DE COCHOLGUE':
    'https://docs.google.com/spreadsheets/d/1xTIDU9VkG_Tm7-VyfUDw9HPvm4Kbgw6L/edit#gid=411718231',

  'PLAZA CEPPI ':
    'https://docs.google.com/spreadsheets/d/1LS5je0Mmh5-tfC567CRv4ENEdTWiY3qL/edit#gid=1558508639',
  'MIRADOR SAN PABLO':
    'https://docs.google.com/spreadsheets/d/1wDLpzTHrLs81altZjP1k9pEmphXSpjG_/edit#gid=1104016532',

  'VISTA BULNES':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  IRARRAZAVAL:
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'EDIFICIO IRARRAZAVAL':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'VERGARA DOMEYKO':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'SAN PABLO':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  QUINTAVISTA:
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'PRAT 631':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'PARQUE QUINTA':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'PARQUE PANAMA':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'PARQUE CURICO':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'PARQUE ANDINO':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  ECUADOR:
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'CORTE SUPREMA 196':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  CORRIENTES:
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'ATELIER PRAT':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'BORDE VIVO':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  'SANTA ELVIRA ':
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',
  ALBORA:
    'https://docs.google.com/spreadsheets/d/13A8fp4jDUY-fArNgThjuYw6UFqjfmxve/edit#gid=1926406561',

  'PLAZA VICTORIA':
    'https://docs.google.com/spreadsheets/d/1mDkdSGOHZ5MBcy3-ldBmiw9fOe0GPcAf/edit#gid=212050483',

  'GUILLERMO MANN':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=1730004068',
  'LO OVALLE':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=1475627333',
  'CENT URBANO LA FLORIDA TORRE B':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=1990431102',
  'ÑUBLE B':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=938251699',
  'ÑUBLE A':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=285006590',
  'RODRIGUEZ VELASCO ETAPA A':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=683934190',
  RUPANCO:
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=740700361',
  'CENT URBANO SANTOS DUMONT C':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=1199559854',
  'CENT URBANO LA FLORIDA TORRE A':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=1387749655',
  'CENTRO URBANO SANTOS DUMONT B':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=212451883',
  'IND OFICINA':
    'https://docs.google.com/spreadsheets/d/1rZVXN3xTVe_EUCXgjba7aDqsgWGZ_the/edit#gid=998456256',

  'LAS HERAS / INMEDIATA':
    'https://docs.google.com/spreadsheets/d/1-8QnFjvYVqtnDoAYhxQB3-bOurb1XZWZ/edit#gid=404977207',
  'CONDOMINIO LOS PEUMO / FUTURA':
    'https://docs.google.com/spreadsheets/d/1DWm96L1HBzxubBlltb117Ssqq1GkkyHQ/edit#gid=1860664907',
  'EDIFICIO ROZAS 677 / FUTURA':
    'https://docs.google.com/spreadsheets/d/1J0b2D-b0WneKmNlHOCgLa8fV7EDZvaki/edit#gid=193489537',

  'CIUDADANO PEZOA':
    'https://docs.google.com/spreadsheets/d/1xO63ZgRF0t3qm_fJt1y8M3QroihLy3NH/edit#gid=76613077',
  'ARQ. TOESCA':
    'https://docs.google.com/spreadsheets/d/1WlI85h4lRBC6eWYi4AABlXS1nKVLvX2B/edit#gid=472171250',

  'VALLE LAS LENGAS III - PTA AREN':
    'https://docs.google.com/spreadsheets/d/1jfArPWXEX8h_3-wQksiXK1UC7ZkpDd-_/edit#gid=630706337',
  'TERRAZA MIRADOR II - PMO':
    'https://docs.google.com/spreadsheets/d/1jfArPWXEX8h_3-wQksiXK1UC7ZkpDd-_/edit#gid=662857520',
  'EDIFICIO VERTICE - TEMUCO':
    'https://docs.google.com/spreadsheets/d/1jfArPWXEX8h_3-wQksiXK1UC7ZkpDd-_/edit#gid=202931420',

  'RODRIGUEZ PINTO':
    'https://docs.google.com/spreadsheets/d/15hUIlbBDUN0tCEQLv4Sb-IbkmQfVhBo_/edit#gid=1945567663',

  'ALTO DEL VALLE':
    'https://docs.google.com/spreadsheets/d/1lv1bjPN-vBsuCDlLVuN7AxUDrzSMMnnv/edit#gid=1566389855',

  'ESTACION RECREO':
    'https://docs.google.com/spreadsheets/d/16B68dZv_BU6VMJMDc2OB7nMjjkvWjj2B/edit#gid=1349552750',
  'EDIFICIO CONEXION BUZETA':
    'https://docs.google.com/spreadsheets/d/14Gw4IYzOmaB-pT9EJkZfRHUnQFgNXaPB/edit#gid=361599683',

  URUGUAY:
    'https://docs.google.com/spreadsheets/d/1rckv044Fyawc_rNHyWfdGoXgNzAComHx/edit#gid=555122182',
  'GREGORIO DE LA FUENTE':
    'https://docs.google.com/spreadsheets/d/1rckv044Fyawc_rNHyWfdGoXgNzAComHx/edit#gid=581038693',
  'JARDINES DE RAMON CRUZ':
    'https://docs.google.com/spreadsheets/d/1rckv044Fyawc_rNHyWfdGoXgNzAComHx/edit#gid=1353932071',

  'SAN ANDRÉS PARK':
    'https://docs.google.com/spreadsheets/d/1KUNv9Ui_AacXsdUk-qpIGrSos0FQoD7I/edit#gid=1555983488',

  'QUILIN 4811':
    'https://docs.google.com/spreadsheets/d/16y_xDG4MygqFy6tcoQpU0FCdTg1G0Jcx8bbiNettZ_M/edit#gid=0',

  'ECO COIHUIN':
    'https://docs.google.com/spreadsheets/d/1sSHs4YhSy9qziXbOcUbEjc7jsMybnvH22dan_HruHp0/edit#gid=0',

  LUMINITY:
    'https://docs.google.com/spreadsheets/d/1qdV955kzl2F_PkCJOJjLGK9v8pDuPCym/edit#gid=1935290523',

  'CONCEPTO BEST SITE':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',
  'CONCEPTO BEST TOO':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',
  'CONCEPTO SMART CONCEPCION':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',
  'CONCEPTO SMART INDEPENDENCIA':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',
  'CONCEPTO SMART LA FLORIDA':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',
  'CONCEPTO SMART MONTEMAR':
    'https://docs.google.com/spreadsheets/d/1uVYY34J1xCcV7iDQqpnRnrfJRbgmqHOTbsI5s8Fee6M/edit#gid=1974314501',

  GEOMETRICA:
    'https://docs.google.com/spreadsheets/d/1qN0fi4Z_gcbYSFy-p3Id_vh7SFT4OU5Q/edit#gid=580590670',
}
