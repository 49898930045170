import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CNavItem, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './SidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import logoDesktop from 'src/assets/brand/logo-desktop-white.png'
import logoMobile from 'src/assets/brand/logo-mobile.png'

// Sidebar Items
import navigation from 'src/_nav'
import useLayout from 'src/hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { getUserEmail } from 'src/helpers'
import { getRolesAndModules } from 'src/api'
import { UI } from 'src/components'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'

const defaultNavItems = [
  {
    component: CNavItem,
    name: 'Inicio',
    to: '/',
    icon: <CIcon icon={icon.cilHome} customClassName="nav-icon" />,
  },
]

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const navigate = useNavigate()
  const currentUrl = window.location.href

  const { layout } = useLayout()

  const [loadingSidebar, setLoadingSidebar] = useState(false)
  const [navItems, setNavItems] = useState(defaultNavItems)

  const handleNavItems = (modules) => {
    const filteredNavigation = [...defaultNavItems]

    for (const navGroup of navigation) {
      // Si es un modulo sin items, se pushea (Inicio y Wiki)
      if (!navGroup.items && navGroup.key) {
        const moduleValues = modules[navGroup.key.toUpperCase()]
        if (navGroup.to === moduleValues?.pathname) {
          filteredNavigation.push(navGroup)
        }
      } else if (navGroup.items) {
        let validatedNavGroup = { ...navGroup, items: [] }

        // Si es un modulo con items, tenemos que validarlos en los modulos de la api
        const moduleValues = modules[navGroup.key.toUpperCase()]

        if (moduleValues && moduleValues.length) {
          // Corroboramos que nav items pushear, buscando si el pathname esta en el modules recibido por la api
          const navItems = []
          for (const navItem of navGroup.items) {
            if (navItem.to === '/reservas' || navItem.to === '/operaciones/reserva') {
              const showReservation = process.env.REACT_APP_RESERVATION_FLAG !== 'false'
              const showOperations = process.env.REACT_APP_OPERATION_FLAG !== 'false'
              if (
                navItem.to === '/reservas' &&
                showReservation &&
                moduleValues?.some((moduleItem) => moduleItem.pathname === navItem.to)
              ) {
                navItems.push(navItem)
              } else if (
                navItem.to === '/operaciones/reserva' &&
                showOperations &&
                moduleValues?.some((moduleItem) => moduleItem.pathname === navItem.to)
              ) {
                navItems.push(navItem)
              }
            } else {
              if (moduleValues?.some((moduleItem) => moduleItem.pathname === navItem.to)) {
                navItems.push(navItem)
              }
            }
          }
          // Si tiene al menos un nav item, lo pusheamos al nav group
          if (navItems.length) {
            validatedNavGroup.items = navItems
            filteredNavigation.push(validatedNavGroup)
          }
        }
      }
    }
    setNavItems(filteredNavigation)
  }

  const checkRolesAndModules = async () => {
    try {
      const cachedModules = JSON.parse(localStorage.getItem('cachedModules'))
      if (cachedModules) {
        return handleNavItems(cachedModules)
      } else {
        setLoadingSidebar(true)
        const { data } = await getRolesAndModules({ email: getUserEmail() })
        const { modules, role } = data.BackOffice
        localStorage.setItem('cachedModules', JSON.stringify(modules))
        localStorage.setItem('roles', JSON.stringify(role))
        dispatch({ type: 'set', roles: role })
        handleNavItems(modules)
        setLoadingSidebar(false)
      }
    } catch (error) {
      console.log(error)
      setLoadingSidebar(false)
    }
  }

  useEffect(() => {
    checkRolesAndModules()
  }, [])

  const goHome = () => (currentUrl !== '/' ? navigate('/') : null)

  const automaticSidebar = () => {
    if (layout.breakpoint === 'xl') {
      dispatch({ type: 'set', sidebarShow: true })
      dispatch({ type: 'set', sidebarUnfoldable: false })
    }
    if (layout.breakpoint === 'lg') {
      dispatch({ type: 'set', sidebarShow: true })
      dispatch({ type: 'set', sidebarUnfoldable: true })
    }
  }

  useEffect(() => {
    automaticSidebar()
  }, [layout.breakpoint])

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      {unfoldable && (
        <CSidebarBrand to="/">
          <img onClick={() => goHome()} src={logoMobile} alt="Propital Logo" height={30} />
        </CSidebarBrand>
      )}
      {sidebarShow && !unfoldable && (
        <CSidebarBrand to="/">
          <img onClick={() => goHome()} src={logoDesktop} alt="Propital Logo" height={30} />
        </CSidebarBrand>
      )}
      <CSidebarNav style={{ overflowY: 'hidden' }}>
        {loadingSidebar && <UI.Loading background={false} />}
        <SimpleBar>
          <AppSidebarNav items={navItems} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
