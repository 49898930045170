import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

// Galería de íconos: https://icon-sets.iconify.design

const CircleButton = ({
  icon,
  height = '30px',
  width = '30px',
  color = 'white',
  active = true,
  disabled = false,
  buttonClassName = '',
  iconClassName = '',
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      className={`ui-button ${active && !disabled && 'active'} ${buttonClassName}`}
      style={{ height, width, color }}
      {...rest}
    >
      <span className={`iconify w-100 h-100 ${iconClassName}`} data-icon={icon}></span>
    </button>
  )
}

CircleButton.propTypes = {
  icon: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
}

export default CircleButton
