import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

const Chip = ({ text, active, height = '30px', width = 'auto', ...rest }) => {
  return (
    <button
      className={`ui-chip rounded-5 px-3 ${active && 'active'}`}
      style={{ height, width }}
      {...rest}
    >
      <span>{text}</span>
    </button>
  )
}

Chip.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default Chip
