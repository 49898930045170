import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField, handleOnSubmit } from 'src/helpers'
import { CButton } from '@coreui/react'
import useToast from 'src/hooks/useToast'
import { UI } from 'src/components'
import { getComunas } from 'src/api'
import './styles.scss'

const DatosObjetivos = ({ clientData, id }) => {
  const [editing, setEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [datosObjetivos, setDatosObjetivos] = useState([])
  const [auxDatosObjetivos, setAuxDatosObjetivos] = useState([])

  const [loading, setLoading] = useState(false)

  const { Toaster, addToast } = useToast()

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
  }

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = async (data) => {
    setLoading(true)
    const result = await handleOnSubmit(
      clientData,
      data,
      editing,
      editedData,
      datosObjetivos,
      setDatosObjetivos,
      setEditing,
      setEditedData,
      'objectiveData',
      id,
      'investment',
    )

    if (result) {
      addToast({
        message: result.data.message,
        color: result.status === 200 ? 'success' : 'danger',
      })
    }
    setLoading(false)
  }

  const setClientData = async () => {
    setLoading(true)
    if (clientData) {
      const objectCommunes = (await getComunas()).data.results
      const filteredCommunes = []
      for (const index in objectCommunes) {
        filteredCommunes.push({
          value: objectCommunes[index].value,
          label: objectCommunes[index].text,
        })
      }
      let checkboxValues
      if (clientData.where_you_invest_today) {
        checkboxValues = JSON.parse(clientData.where_you_invest_today)
      }
      setDatosObjetivos([
        {
          label: 'Zona de interés',
          value: clientData.interest_area ?? '',
          type: 'select',
          options: filteredCommunes || [],
          errorMessage: 'Zona de interés inválida',
          allowNull: true,
          size: '47.5%',
        },
        {
          label: '¿En qué inviertes hoy?',
          type: 'checkbox-group',
          options: [
            {
              label: 'Fondos Mutuos',
              value: checkboxValues ? checkboxValues['fondos mutuos'] : '',
            },
            { label: 'AFP', value: checkboxValues ? checkboxValues['afp'] : '' },
            { label: 'Cripto', value: checkboxValues ? checkboxValues['cripto'] : '' },
            { label: 'Fraccionario', value: checkboxValues ? checkboxValues['fraccionario'] : '' },
            { label: 'Otros', value: checkboxValues ? checkboxValues['otros'] : '' },
          ],
          errorMessage: 'Inversion inválida',
          allowNull: true,
          size: '47.5%',
        },
      ])
    }
    setLoading(false)
  }

  const handleCancelEditing = () => {
    setEditing(false)
    setDatosObjetivos(auxDatosObjetivos)
    auxDatosObjetivos.forEach((item) => {
      const fieldName = item.label?.toLowerCase()
      setValue(fieldName, item.value)
      clearErrors(fieldName)
    })
  }

  useEffect(() => {
    if (editing) {
      // Se guardan en estado auxiliar los datos previos a editar
      setAuxDatosObjetivos(datosObjetivos)
      // Focus en el primer input para ayudar al usuario
      document.getElementsByTagName('input')[0].focus()
    }
  }, [editing])

  useEffect(() => {
    setClientData()
  }, [clientData])

  return (
    <React.Fragment>
      {Toaster}

      {loading && <UI.Loading />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
          {datosObjetivos.map((item, index) => {
            return renderField(item, index, control, editing, errors, ' del cliente')
          })}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>{/* Espacio reservado para el botón */}</div>
          {editing && (
            <div className="ml-auto">
              <CButton
                type="button"
                color="danger"
                className="text-white px-4"
                onClick={() => handleCancelEditing()}
              >
                Cancelar
              </CButton>
              <CButton type="submit" color="success" className="text-white px-4 mx-4">
                Aceptar
              </CButton>
            </div>
          )}
        </div>
      </form>
      {!editing && (
        <CButton
          type="button"
          onClick={() => setEditing(true)}
          color="primary"
          className="text-white px-4 mx-4"
          style={{ alignSelf: 'end' }}
        >
          Editar
        </CButton>
      )}
    </React.Fragment>
  )
}

DatosObjetivos.propTypes = {
  clientData: PropTypes.object,
  id: PropTypes.string,
}

export default DatosObjetivos
