import React, { useEffect, useState } from 'react'
import { getAllUserData } from 'src/api'
import { capitalizeCombo, getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import { CTable, CSpinner } from '@coreui/react'
import useToast from 'src/hooks/useToast'
import PropTypes from 'prop-types'

const ClientsTable = ({
  page,
  setPage,
  setTotalPages,
  searchTerm,
  extraColumn = {},
  extraValueColumn = () => {},
}) => {
  const userEmail = getUserEmail()

  const { Toaster, addToast } = useToast()

  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [cachedPages, setCachedPages] = useState({})

  const columns = [
    {
      key: 'nombreApellido',
      label: 'Nombre completo',
      _props: { scope: 'col' },
    },
    {
      key: 'documento',
      label: 'Documento',
      _props: { scope: 'col' },
    },
    {
      key: 'client_type',
      label: 'Tipo de cliente',
      _props: { scope: 'col' },
    },
    {
      key: 'broker_name',
      label: 'Broker',
      _props: { scope: 'col' },
    },
    extraColumn,
  ]

  const getClients = () => {
    setLoading(true)

    const params = {
      email: userEmail,
      page_size: 10,
      page_number: page,
    }

    if (searchTerm) {
      params.search_param = searchTerm
      params.page_number = 1
      setCachedPages({})
    }

    if (cachedPages[page] && !searchTerm) {
      const parsedData = parseUsersData(cachedPages[page])
      setItems(parsedData)
      setLoading(false)
    } else {
      getAllUserData(params)
        .then((res) => {
          const parsedData = parseUsersData(res.data.results)
          setItems(parsedData)
          setTotalPages(res.data.total_pages)

          searchTerm ? setPage(1) : setCachedPages({ ...cachedPages, [page]: res.data.results })

          setLoading(false)
        })
        .catch((err) => {
          handlerSendErrorNotification(err)
          addToast({ message: 'No se encontraron usuarios', color: 'warning' })
          setItems([])
          setTotalPages(0)
          setLoading(false)
        })
    }
  }

  const parseUsersData = (data) => {
    return data.map((item) => {
      return {
        nombreApellido: `${capitalizeCombo(item.first_name)} ${capitalizeCombo(item.last_name)}`,
        documento: item.identity_document.split(',').join(''),
        client_type: item.client_type,
        broker_name: item.broker_name,
        [extraColumn.key]: extraValueColumn(item),
        _cellProps: { id: { scope: 'row' } },
      }
    })
  }

  useEffect(() => {
    getClients()
  }, [page, searchTerm])

  return (
    <React.Fragment>
      {Toaster}

      {loading ? (
        <div className="w-100 d-grid place-items-center">
          <CSpinner color="primary" style={{ margin: '7.2rem auto' }} />
        </div>
      ) : items.length ? (
        <CTable columns={columns} items={items} hover responsive="md" />
      ) : (
        <h4 className="py-4 text-center">El cliente que está buscando no existe</h4>
      )}
    </React.Fragment>
  )
}

ClientsTable.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  extraColumn: PropTypes.object,
  extraValueColumn: PropTypes.func,
}

export default ClientsTable
