import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'

const DatosFinancieros = ({ financialData, setFinancialData, setActiveTab }) => {
  const datosFinancieros = [
    {
      label: 'Lugar de trabajo',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Lugar de trabajo inválido',
      allowNull: true,
    },
    {
      label: 'Contrato',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Contrato inválido',
      allowNull: true,
    },
    {
      label: 'Antiguedad en meses',
      value: '',
      type: 'number',
      pattern: /^[0-9]+$/, // Números enteros positivos
      errorMessage: 'Antiguedad inválida',
      allowNull: true,
    },
    {
      label: 'Banco',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Banco inválido',
      allowNull: true,
    },
    {
      label: 'Tipo de cuenta',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Tipo de cuenta inválido',
      allowNull: true,
    },
    {
      label: 'Ingresos líquidos',
      value: '',
      type: 'text',
      pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
      errorMessage: 'Ingresos líquidos inválidos',
      formatPrice: true,
      allowNull: true,
    },
    {
      label: 'Ingresos adicionales',
      value: '',
      type: 'text',
      pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
      errorMessage: 'Ingresos adicionales inválidos',
      formatPrice: true,
      allowNull: true,
    },
    {
      label: 'Capacidad de ahorro',
      value: '',
      type: 'select',
      options: [
        { value: 'Baja', label: 'Baja' },
        { value: 'Media', label: 'Media' },
        { value: 'Alta', label: 'Alta' },
      ],
      allowNull: true,
    },
    {
      label: 'Ahorro',
      value: '',
      type: 'text',
      pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
      errorMessage: 'Ahorro inválido',
      formatPrice: true,
      allowNull: true,
    },
  ]

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: financialData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()
      setFinancialData(formValues)

      // Cambia a la siguiente pestaña
      setActiveTab(2)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {datosFinancieros.map((item, index) =>
          renderField(item, index, control, true, errors, ' del cliente'),
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton type="submit" color="primary" className="text-white px-4 mx-4">
            Siguiente
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosFinancieros.propTypes = {
  financialData: PropTypes.object,
  setFinancialData: PropTypes.func,
  setActiveTab: PropTypes.func,
}

export default DatosFinancieros
