export const markers = [
  // Inmobiliaria Aconcagua
  {
    //name: 'Duo Carvajal',
    id: 7949,
    lat: -33.5148544,
    lng: -70.6656079,
    type: 'marker_salmon',
  },
  {
    //name: 'Inc 129',
    id: 7970,
    lat: -33.486507,
    lng: -70.622087,
    type: 'marker_salmon',
  },
  {
    //name: 'Tocornal',
    id: 7991,
    lat: -33.4514051,
    lng: -70.6394398,
    type: 'marker_salmon',
  },
  {
    //name: 'Nueva Maipú',
    id: 8692,
    lat: -36.8216104,
    lng: -73.0457864,
    type: 'marker_salmon',
  },
  {
    //name: 'Mirador 950',
    id: 441,
    lat: -33.511751,
    lng: -70.600838,
    type: 'marker_salmon',
  },
  {
    //name: 'Jaime Repullo',
    id: 439,
    lat: -36.760598,
    lng: -73.086158,
    type: 'marker_salmon',
  },

  // Inmobiliaria Andes
  {
    //name: 'Andes Republica',
    id: 2512,
    lat: -33.448747,
    lng: -70.665611,
    type: 'marker_black',
  },
  {
    //name: 'Andes Macul',
    id: 8788,
    lat: -33.4843,
    lng: -70.60053,
    type: 'marker_black',
  },
  {
    //name: 'Andes Ñuñoa',
    id: 8789,
    lat: -33.4529267,
    lng: -70.6041577,
    type: 'marker_black',
  },

  // Inmobiliaria Aqua
  {
    //name: 'Argomedo 461',
    id: 7595,
    lat: -33.450578,
    lng: -70.640238,
    type: 'marker_black',
  },

  // Inmobiliaria Armas
  {
    //name: 'Santiago 3',
    id: 7461,
    lat: -33.45342,
    lng: -70.653427,
    type: 'marker_black',
  },

  // Inmobiliaria Ciss
  {
    //name: 'Peumayen',
    id: 7995,
    lat: -36.8489071,
    lng: -73.0957844,
    type: 'marker_brown',
  },
  {
    //name: 'Fuentes De Prats',
    id: 568,
    lat: -37.014263,
    lng: -73.157951,
    type: 'marker_brown',
  },
  {
    //name: 'Mirador Oceanico',
    id: 659,
    lat: -36.848724,
    lng: -73.095606,
    type: 'marker_brown',
  },
  {
    //name: 'Rozas Condell',
    id: 480,
    lat: -36.817246,
    lng: -73.042644,
    type: 'marker_brown',
  },
  {
    //name: 'New Center',
    id: 535,
    lat: -36.822831,
    lng: -73.045082,
    type: 'marker_brown',
  },
  {
    //name: 'Roosevelt',
    id: 511,
    lat: -36.821463,
    lng: -73.035958,
    type: 'marker_brown',
  },

  // Inmobiliaria Ecasa
  {
    //name: 'Lomas De Puyai 2',
    id: 7466,
    lat: -32.498928,
    lng: -71.434901,
    type: 'marker_mulberry',
  },
  {
    //name: 'Alto San Joaquin',
    id: 7459,
    lat: -29.922913,
    lng: -71.238899,
    type: 'marker_mulberry',
  },
  {
    //name: 'Ferroparque',
    id: 585,
    lat: -38.72805,
    lng: -72.57259,
    type: 'marker_mulberry',
  },
  {
    //name: 'Neo Azapa 2',
    id: 593,
    lat: -18.494083,
    lng: -70.287982,
    type: 'marker_mulberry',
  },
  {
    //name: 'Edificio HA',
    id: 504,
    lat: -29.95228,
    lng: -71.33717,
    type: 'marker_mulberry',
  },
  {
    //name: 'Lomas De Puyai 2a',
    id: 506,
    lat: -32.498928,
    lng: -71.434901,
    type: 'marker_mulberry',
  },
  {
    //name: 'Lomas De Puyai 1b',
    id: 595,
    lat: -32.498928,
    lng: -71.434901,
    type: 'marker_mulberry',
  },
  {
    //name: 'Cumbres de Peñuelas',
    id: 442,
    lat: -29.950978,
    lng: -71.281714,
    type: 'marker_mulberry',
  },
  {
    //name: 'Cumbres de Peñuelas 2',
    id: 586,
    lat: -29.950978,
    lng: -71.281714,
    type: 'marker_mulberry',
  },
  {
    //name: 'Neo Azapa',
    id: 592,
    lat: -18.494083,
    lng: -70.287982,
    type: 'marker_mulberry',
  },
  {
    //name: 'Neo Azapa 3',
    id: 507,
    lat: -18.494083,
    lng: -70.287982,
    type: 'marker_mulberry',
  },
  {
    //name: 'Bosquemar',
    id: 591,
    lat: -32.94038,
    lng: -71.518711,
    type: 'marker_mulberry',
  },
  {
    //name: 'Bosquemar 2',
    id: 498,
    lat: -32.94038,
    lng: -71.518711,
    type: 'marker_mulberry',
  },
  {
    //name: 'Vista Herradura 2',
    id: 451,
    lat: -29.979253,
    lng: -71.346467,
    type: 'marker_mulberry',
  },
  {
    //name: 'Conecta Huechuraba',
    id: 589,
    lat: -33.365284,
    lng: -70.681713,
    type: 'marker_mulberry',
  },
  {
    //name: 'Conecta Huechuraba 2',
    id: 590,
    lat: -33.365284,
    lng: -70.681713,
    type: 'marker_mulberry',
  },
  {
    //name: 'Conecta Huechuraba 3',
    id: 502,
    lat: -33.365284,
    lng: -70.681713,
    type: 'marker_mulberry',
  },
  {
    //name: 'Urban Santiago',
    id: 465,
    lat: -33.450976,
    lng: -70.663527,
    type: 'marker_mulberry',
  },
  {
    //name: 'Cicarelli 2',
    id: 501,
    lat: -33.478628,
    lng: -70.630315,
    type: 'marker_mulberry',
  },
  {
    //name: 'Aires La Florida',
    id: 587,
    lat: -33.51303,
    lng: -70.605224,
    type: 'marker_mulberry',
  },
  {
    //name: 'Aires La Florida 2',
    id: 491,
    lat: -33.512977,
    lng: -70.604781,
    type: 'marker_mulberry',
  },
  {
    //name: 'Urban La Florida',
    id: 467,
    lat: -33.520472,
    lng: -70.602283,
    type: 'marker_mulberry',
  },
  {
    //name: 'Urban La Florida 2',
    id: 594,
    lat: -33.520472,
    lng: -70.602283,
    type: 'marker_mulberry',
  },
  {
    //name: 'Edificio Bezanilla',
    id: 609,
    lat: -33.417767,
    lng: -70.657455,
    type: 'marker_mulberry',
  },
  {
    //name: 'Terratoltén',
    id: 651,
    lat: -39.282012,
    lng: -72.23078,
    type: 'marker_mulberry',
  },

  // Inmobiliaria Eje
  {
    //name: 'Live +',
    id: 430,
    lat: -33.448187,
    lng: -70.663339,
    type: 'marker_black',
  },
  {
    //name: 'Neosolier',
    id: 682,
    lat: -33.481864,
    lng: -70.690202,
    type: 'marker_black',
  },

  // Inmobiliaria Esepe
  {
    //name: 'Parque Germania',
    id: 580,
    lat: -41.471322,
    lng: -72.93394,
    type: 'marker_black',
  },

  // Inmobiliaria Euro
  {
    //name: 'Nueva Andres Bello',
    id: 671,
    lat: -33.417094,
    lng: -70.664635,
    type: 'marker_brown',
  },
  {
    //name: 'Rosas 1444',
    id: 484,
    lat: -33.435843,
    lng: -70.657699,
    type: 'marker_brown',
  },
  {
    //name: 'Lira 254',
    id: 527,
    lat: -33.446477,
    lng: -70.639784,
    type: 'marker_brown',
  },
  {
    //name: 'Guillermo Mann 1401',
    id: 526,
    lat: -33.47114,
    lng: -70.618473,
    type: 'marker_brown',
  },
  {
    //name: 'Santa Elena 1670',
    id: 474,
    lat: -33.465901,
    lng: -70.628215,
    type: 'marker_brown',
  },
  {
    //name: 'Alameda 4719',
    id: 604,
    lat: -33.454357,
    lng: -70.69355,
    type: 'marker_brown',
  },
  {
    //name: 'Vicuña Mackenna 1432',
    id: 605,
    lat: -33.460886,
    lng: -70.627652,
    type: 'marker_brown',
  },
  {
    //name: 'Mapocho 3521',
    id: 606,
    lat: -33.432922,
    lng: -70.68255,
    type: 'marker_brown',
  },
  {
    //name: 'Froilan Roa 5731',
    id: 607,
    lat: -33.510055,
    lng: -70.602264,
    type: 'marker_brown',
  },
  {
    //name: 'Independencia 4745',
    id: 608,
    lat: -33.385668,
    lng: -70.679093,
    type: 'marker_brown',
  },

  // Inmobiliaria Fai
  {
    //name: 'O2',
    id: 524,
    lat: -33.454677,
    lng: -70.695554,
    type: 'marker_light_green',
  },
  {
    //name: 'Move',
    id: 520,
    lat: -33.471604,
    lng: -70.6297,
    type: 'marker_light_green',
  },
  {
    //name: 'Armonia',
    id: 440,
    lat: -33.513025,
    lng: -70.666686,
    type: 'marker_light_green',
  },
  {
    //name: 'Equilibrio',
    id: 521,
    lat: -33.517734,
    lng: -70.651502,
    type: 'marker_light_green',
  },
  {
    //name: 'Fan',
    id: 522,
    lat: -33.489536,
    lng: -70.600217,
    type: 'marker_light_green',
  },
  {
    //name: 'Live',
    id: 530,
    lat: -33.473643,
    lng: -70.602286,
    type: 'marker_light_green',
  },
  {
    //name: 'More',
    id: 519,
    lat: -33.527443,
    lng: -70.586722,
    type: 'marker_light_green',
  },
  {
    //name: 'Vista Park',
    id: 448,
    lat: -33.520594,
    lng: -70.610091,
    type: 'marker_light_green',
  },

  // Inmobiliaria Fourcade
  {
    //name: 'Los Naranjos',
    id: 548,
    lat: -37.485725,
    lng: -72.355268,
    type: 'marker_black',
  },

  // Inmobiliaria Fundamenta
  {
    //name: 'Eco Estación',
    id: 233,
    lat: -33.455859,
    lng: -70.694526,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Florida',
    id: 481,
    lat: -33.518785,
    lng: -70.593185,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Florida II',
    id: 487,
    lat: -33.518785,
    lng: -70.593185,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco España',
    id: 477,
    lat: -33.456165,
    lng: -70.668071,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Espacios',
    id: 479,
    lat: -33.448228,
    lng: -70.633305,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Arauco',
    id: 416,
    lat: -33.472701,
    lng: -70.648052,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Irarrazaval',
    id: 483,
    lat: -33.45383,
    lng: -70.57375,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Egaña Norte',
    id: 463,
    lat: -33.453684,
    lng: -70.571834,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Egaña Poniente',
    id: 466,
    lat: -33.454544,
    lng: -70.572502,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Egaña Oriente',
    id: 464,
    lat: -33.454412,
    lng: -70.571275,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Cerrillos',
    id: 509,
    lat: -33.494272,
    lng: -70.67638,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Quilin I',
    id: 485,
    lat: -33.488621,
    lng: -70.601577,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Quilin II',
    id: 486,
    lat: -33.488621,
    lng: -70.601577,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Vicente Valdes I',
    id: 641,
    lat: -33.526548,
    lng: -70.591087,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Vicente Valdes II Etapa 1',
    id: 488,
    lat: -33.528508,
    lng: -70.599409,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Vicente Valdes II Etapa 2',
    id: 489,
    lat: -33.528508,
    lng: -70.599409,
    type: 'marker_dark_orange',
  },
  {
    //name: 'Eco Encalada',
    id: 469,
    lat: -33.457697,
    lng: -70.670451,
    type: 'marker_dark_orange',
  },

  // Inmobiliaria Gespania
  {
    //name: 'Distrito Parque',
    id: 562,
    lat: -36.833561,
    lng: -73.053046,
    type: 'marker_blue',
  },
  {
    //name: 'Distrito Bustamente',
    id: 561,
    lat: -33.445078,
    lng: -70.632997,
    type: 'marker_blue',
  },
  {
    //name: 'New Egaña',
    id: 564,
    lat: -33.456107,
    lng: -70.571835,
    type: 'marker_blue',
  },
  {
    //name: 'Feel Good',
    id: 563,
    lat: -33.4983,
    lng: -70.661316,
    type: 'marker_blue',
  },
  {
    //name: 'Terra',
    id: 560,
    lat: -33.467401,
    lng: -70.598593,
    type: 'marker_blue',
  },

  // Grupo Hogares
  {
    //name: 'Quilin 4811',
    id: 7460,
    lat: -33.488366,
    lng: -70.583695,
    type: 'marker_black',
  },

  // Inmobiliaria Icuadra
  {
    //name: 'Parque del Sur',
    id: 533,
    lat: -33.471215,
    lng: -70.661086,
    type: 'marker_black',
  },
  {
    //name: 'San Nicolas',
    id: 476,
    lat: -33.502121,
    lng: -70.640564,
    type: 'marker_black',
  },

  // Imagina
  {
    //name: 'Best Site Santiago',
    id: 8685,
    lat: -33.435533,
    lng: -70.654674,
    type: 'marker_dark_blue',
  },
  {
    //name: 'Best Too Santiago',
    id: 8686,
    lat: -33.450173,
    lng: -70.650516,
    type: 'marker_dark_blue',
  },
  {
    //name: 'Smart Concepcion',
    id: 8687,
    lat: -36.823042,
    lng: -73.046881,
    type: 'marker_dark_blue',
  },
  {
    //name: 'Smart Independencia',
    id: 8688,
    lat: -33.411602,
    lng: -70.656649,
    type: 'marker_dark_blue',
  },
  {
    //name: 'Smart La Florida',
    id: 8689,
    lat: -33.516144,
    lng: -70.602861,
    type: 'marker_dark_blue',
  },
  {
    //name: 'Smart Montemar',
    id: 8690,
    lat: -32.948613,
    lng: -71.542107,
    type: 'marker_dark_blue',
  },

  // Inmobiliaria Ingevec
  {
    //name: 'Bellavista',
    id: 642,
    lat: -33.518876,
    lng: -70.601074,
    type: 'marker_yellow',
  },
  {
    //name: 'Centenario I',
    id: 643,
    lat: -33.476403,
    lng: -70.648482,
    type: 'marker_yellow',
  },
  {
    //name: 'Centenario II',
    id: 644,
    lat: -33.476403,
    lng: -70.648482,
    type: 'marker_yellow',
  },
  {
    //name: 'Froilán Roa',
    id: 645,
    lat: -33.51115,
    lng: -70.601428,
    type: 'marker_yellow',
  },
  {
    //name: 'Los Alerces',
    id: 646,
    lat: -33.47075,
    lng: -70.59762,
    type: 'marker_yellow',
  },
  {
    //name: 'Santa Isabel',
    id: 647,
    lat: -33.449019,
    lng: -70.637385,
    type: 'marker_yellow',
  },
  {
    //name: 'Santa Rosa',
    id: 648,
    lat: -33.447215,
    lng: -70.644868,
    type: 'marker_yellow',
  },
  {
    //name: 'Tocornal',
    id: 649,
    lat: -33.451821,
    lng: -70.639432,
    type: 'marker_yellow',
  },
  {
    //name: 'Vicuña Mackenna',
    id: 650,
    lat: -33.46699,
    lng: -70.625628,
    type: 'marker_yellow',
  },

  // Inmobiliaria Inmobilia
  {
    //name: 'Vertientes',
    id: 455,
    lat: -33.572651,
    lng: -70.539358,
    type: 'marker_dark_green',
  },
  {
    //name: 'Vertice',
    id: 458,
    lat: -33.409576,
    lng: -70.666826,
    type: 'marker_dark_green',
  },
  {
    //name: 'Ancora',
    id: 494,
    lat: -33.04524,
    lng: -71.60867,
    type: 'marker_dark_green',
  },
  {
    //name: 'Meridiano',
    id: 510,
    lat: -33.028293,
    lng: -71.546464,
    type: 'marker_dark_green',
  },
  {
    //name: 'Libertad 51',
    id: 549,
    lat: -33.446635,
    lng: -70.673308,
    type: 'marker_dark_green',
  },
  {
    //name: 'Ebano',
    id: 553,
    lat: -33.479419,
    lng: -70.601058,
    type: 'marker_dark_green',
  },
  {
    //name: 'Magno',
    id: 565,
    lat: -33.417174,
    lng: -70.561079,
    type: 'marker_dark_green',
  },
  {
    //name: 'Roda',
    id: 657,
    lat: -33.520649,
    lng: -70.655704,
    type: 'marker_dark_green',
  },
  {
    //name: 'Aqua',
    id: 658,
    lat: -29.915386,
    lng: -71.275051,
    type: 'marker_dark_green',
  },

  // Inmobiliaria Itrio
  {
    //name: 'Mirador del Bio Bio III',
    id: 567,
    lat: -36.817009,
    lng: -73.079899,
    type: 'marker_orange',
  },
  {
    //name: 'Maipu 1256',
    id: 596,
    lat: -36.821475,
    lng: -73.043962,
    type: 'marker_orange',
  },
  {
    //name: 'Bulnes 138',
    id: 499,
    lat: -36.82345,
    lng: -73.06064,
    type: 'marker_orange',
  },

  // Inmobiliaria Las Palmas
  {
    //name: 'Las Higueras',
    id: 421,
    lat: -36.744494,
    lng: -73.097312,
    type: 'marker_black',
  },
  {
    //name: 'Altos de Collao',
    id: 493,
    lat: -36.819629,
    lng: -73.017016,
    type: 'marker_black',
  },

  // Inmobiliaria Leben
  {
    //name: 'Bold',
    id: 449,
    lat: -39.813949,
    lng: -73.240792,
    type: 'marker_purple',
  },
  {
    //name: 'Indi',
    id: 515,
    lat: -33.4069,
    lng: -70.66601,
    type: 'marker_purple',
  },
  {
    //name: 'Santo',
    id: 513,
    lat: -33.4503,
    lng: -70.6502,
    type: 'marker_purple',
  },
  {
    //name: 'Living Town',
    id: 514,
    lat: -33.451587,
    lng: -70.642634,
    type: 'marker_purple',
  },
  {
    //name: 'Argomedo',
    id: 496,
    lat: -33.450202,
    lng: -70.638143,
    type: 'marker_purple',
  },
  {
    //name: 'Matte',
    id: 573,
    lat: -33.439272,
    lng: -70.62563,
    type: 'marker_purple',
  },
  {
    //name: 'Capitanes',
    id: 574,
    lat: -33.444649,
    lng: -70.612798,
    type: 'marker_purple',
  },
  {
    //name: 'Suecia',
    id: 517,
    lat: -33.440726,
    lng: -70.601502,
    type: 'marker_purple',
  },
  {
    //name: 'Own',
    id: 518,
    lat: -33.457642,
    lng: -70.62721,
    type: 'marker_purple',
  },
  {
    //name: 'Mood',
    id: 512,
    lat: -33.515095,
    lng: -70.591895,
    type: 'marker_purple',
  },
  {
    //name: 'Okinawa',
    id: 516,
    lat: -33.521254,
    lng: -70.588445,
    type: 'marker_purple',
  },

  // Inmobiliaria Leon
  {
    //name: 'Uruguay',
    id: 5329,
    lat: -33.536223,
    lng: -70.583435,
    type: 'marker_cyan',
  },
  {
    //name: 'Gregorio De La Fuente',
    id: 669,
    lat: -33.478143,
    lng: -70.601091,
    type: 'marker_cyan',
  },
  {
    //name: 'Jardines De Ramon Cruz',
    id: 670,
    lat: -33.485634,
    lng: -70.585638,
    type: 'marker_cyan',
  },

  // Inmobiliaria Maestra
  {
    //name: 'Plaza Quilicura',
    id: 523,
    lat: -33.37213,
    lng: -70.728509,
    type: 'marker_light_green',
  },
  {
    //name: 'Jardines De Alvarado',
    id: 381,
    lat: -33.404058,
    lng: -70.673508,
    type: 'marker_light_green',
  },
  {
    //name: 'Vista Costanera',
    id: 452,
    lat: -33.422011,
    lng: -70.677147,
    type: 'marker_light_green',
  },
  {
    //name: 'Apostol Santiago',
    id: 600,
    lat: -33.525683,
    lng: -70.672042,
    type: 'marker_light_green',
  },
  {
    //name: 'Pintor Cicarelli II',
    id: 426,
    lat: -33.476011,
    lng: -70.629899,
    type: 'marker_light_green',
  },
  {
    //name: 'Plaza Cervantes I',
    id: 601,
    lat: -33.525683,
    lng: -70.672042,
    type: 'marker_light_green',
  },
  {
    //name: 'Plaza Cervantes II',
    id: 602,
    lat: -33.525683,
    lng: -70.672042,
    type: 'marker_light_green',
  },
  {
    //name: 'Vista Llacolén B',
    id: 581,
    lat: -36.826366,
    lng: -73.062477,
    type: 'marker_light_green',
  },
  {
    //name: 'Trinidad III',
    id: 603,
    lat: -33.539866,
    lng: -70.683181,
    type: 'marker_light_green',
  },
  {
    //name: 'Alto Buzeta',
    id: 661,
    lat: -33.481675,
    lng: -70.691112,
    type: 'marker_light_green',
  },
  {
    //name: 'Cáceres',
    id: 662,
    lat: -34.167615,
    lng: -70.737336,
    type: 'marker_light_green',
  },
  {
    //name: 'Mirador Mapocho',
    id: 663,
    lat: -33.43234,
    lng: -70.689032,
    type: 'marker_light_green',
  },
  {
    //name: 'Vista Reloncaví',
    id: 664,
    lat: -41.471168,
    lng: -72.928094,
    type: 'marker_light_green',
  },

  // Inmobiliaria Metrica
  {
    //name: 'Geometrica',
    id: 8684,
    lat: -33.415633,
    lng: -70.666505,
    type: 'marker_black',
  },

  // Inmobiliaria Mmc Spa
  {
    //name: 'Altos De Cocholgue',
    id: 333,
    lat: -45.568041,
    lng: -72.07468,
    type: 'marker_black',
  },

  // Inmobiliaria Nihuel
  {
    //name: 'Plaza Ceppi',
    id: 525,
    lat: -33.514145,
    lng: -70.669514,
    type: 'marker_yellow',
  },
  {
    //name: 'Mirador San Pablo',
    id: 569,
    lat: -33.435255,
    lng: -70.676429,
    type: 'marker_yellow',
  },

  // Inmobiliaria Norte Verde
  {
    //name: 'Parque Panama',
    id: 542,
    lat: -33.433828,
    lng: -70.668383,
    type: 'marker_dark_green',
  },
  {
    //name: 'San Pablo',
    id: 475,
    lat: -33.43443,
    lng: -70.657914,
    type: 'marker_dark_green',
  },
  {
    //name: 'Quintavista',
    id: 537,
    lat: -33.439129,
    lng: -70.686935,
    type: 'marker_dark_green',
  },
  {
    //name: 'Parque Quinta',
    id: 540,
    lat: -33.439101,
    lng: -70.688434,
    type: 'marker_dark_green',
  },
  {
    //name: 'Ecuador',
    id: 551,
    lat: -33.452241,
    lng: -70.694168,
    type: 'marker_dark_green',
  },
  {
    //name: 'Corte Suprema 196',
    id: 554,
    lat: -33.448948,
    lng: -70.655907,
    type: 'marker_dark_green',
  },
  {
    //name: 'Vergara Domeyko',
    id: 461,
    lat: -33.450773,
    lng: -70.651305,
    type: 'marker_dark_green',
  },
  {
    //name: 'Prat 631',
    id: 538,
    lat: -33.45298,
    lng: -70.648618,
    type: 'marker_dark_green',
  },
  {
    //name: 'Borde Vivo',
    id: 571,
    lat: -33.450544,
    lng: -70.643057,
    type: 'marker_dark_green',
  },
  {
    //name: 'Parque Curico',
    id: 544,
    lat: -33.444301,
    lng: -70.63576,
    type: 'marker_dark_green',
  },
  {
    //name: 'Corrientes',
    id: 555,
    lat: -33.436224,
    lng: -70.598058,
    type: 'marker_dark_green',
  },
  {
    //name: 'Albora',
    id: 576,
    lat: -33.470638,
    lng: -70.661606,
    type: 'marker_dark_green',
  },
  {
    //name: 'Atelier Prat',
    id: 570,
    lat: -33.469065,
    lng: -70.646527,
    type: 'marker_dark_green',
  },
  {
    //name: 'Santa Elvira',
    id: 572,
    lat: -33.457868,
    lng: -70.6303,
    type: 'marker_dark_green',
  },
  {
    //name: 'Irarrazaval',
    id: 459,
    lat: -33.45337,
    lng: -70.610553,
    type: 'marker_dark_green',
  },
  {
    //name: 'Parque Andino',
    id: 545,
    lat: -33.507736,
    lng: -70.649981,
    type: 'marker_dark_green',
  },
  {
    //name: 'Vista Bulnes',
    id: 454,
    lat: -33.450773,
    lng: -70.651305,
    type: 'marker_dark_green',
  },

  // Inmobiliaria Parcelas
  {
    //name: 'Eco Coihuin',
    id: 7947,
    lat: -42.7505584,
    lng: -73.6852576,
    type: 'marker_black',
  },

  // Inmobiliaria Parque Avellano
  {
    //name: 'Luminity',
    id: 7992,
    lat: -37.4430795,
    lng: -72.3394669,
    type: 'marker_black',
  },

  // Inmobiliaria Pilares
  {
    //name: 'Conde Maule III',
    id: 425,
    lat: -33.453531,
    lng: -70.694592,
    type: 'marker_salmon',
  },
  {
    //name: 'Ñuble Torre A-B',
    id: 432,
    lat: -33.465901,
    lng: -70.628215,
    type: 'marker_salmon',
  },
  {
    //name: 'Lo Ovalle',
    id: 428,
    lat: -33.514956,
    lng: -70.621764,
    type: 'marker_salmon',
  },
  {
    //name: 'Guillermo Mann',
    id: 427,
    lat: -33.471201,
    lng: -70.619391,
    type: 'marker_salmon',
  },
  {
    //name: 'Rupanco',
    id: 436,
    lat: -33.52,
    lng: -70.60024,
    type: 'marker_salmon',
  },
  {
    //name: 'Ctro. Urbano La Florida A',
    id: 300,
    lat: -33.52099,
    lng: -70.60076,
    type: 'marker_salmon',
  },
  {
    //name: 'Ctro. Urbano La Florida B',
    id: 429,
    lat: -33.52099,
    lng: -70.60076,
    type: 'marker_salmon',
  },
  {
    //name: 'Ctro. Urbano Santos Dumont C',
    id: 492,
    lat: -33.421523,
    lng: -70.650657,
    type: 'marker_salmon',
  },
  {
    //name: 'Rodriguez Velazco',
    id: 435,
    lat: -33.524459,
    lng: -70.5987,
    type: 'marker_salmon',
  },
  {
    //name: 'Ctro. Urbano Santos Dumont B',
    id: 597,
    lat: -33.421523,
    lng: -70.650657,
    type: 'marker_salmon',
  },
  {
    //name: 'Ind Oficina',
    id: 598,
    lat: -33.421218,
    lng: -70.655478,
    type: 'marker_salmon',
  },

  // Inmobiliaria PY
  {
    //name: 'Edificio Rozas 677',
    id: 7495,
    lat: -36.8212989,
    lng: -73.0550425,
    type: 'marker_black',
  },
  {
    //name: 'Condominio Los Peumo',
    id: 583,
    lat: -36.74095,
    lng: -72.998066,
    type: 'marker_black',
  },

  // Inmobiliaria Regenera
  {
    //name: 'Ciudadano Pezoa',
    id: 450,
    lat: -33.45523,
    lng: -70.7036,
    type: 'marker_dark_purple',
  },
  {
    //name: 'Arquitecto Toesca',
    id: 683,
    lat: -33.455554,
    lng: -70.671804,
    type: 'marker_dark_purple',
  },

  // Inmobiliaria Socovesa
  {
    //name: 'Vertice',
    id: 457,
    lat: -38.741467,
    lng: -72.598058,
    type: 'marker_blue',
  },
  {
    //name: 'Casas en Valle las Lengas',
    id: 462,
    lat: -53.145814,
    lng: -70.944127,
    type: 'marker_blue',
  },
  {
    //name: 'Terraza Mirador',
    id: 470,
    lat: -41.468917,
    lng: -72.941136,
    type: 'marker_blue',
  },

  // Inmobiliaria Sp Group
  {
    //name: 'San Andrés Park',
    id: 673,
    lat: -33.4828333,
    lng: -70.6892064,
    type: 'marker_black',
  },

  // Inmobiliaria Surco
  {
    //name: 'Rodriguez Pinto',
    id: 438,
    lat: -36.816775,
    lng: -73.055808,
    type: 'marker_black',
  },

  // Inmobiliaria Valle Verde
  {
    //name: 'Alto Del Valle',
    id: 588,
    lat: -36.816662,
    lng: -73.038791,
    type: 'marker_black',
  },
]
