import React from 'react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <ul className="nav nav-tabs">
      {tabs.map((item) => {
        if (!item.visible) return null
        const isActive = activeTab === tabs.indexOf(item) ? 'active text-primary' : 'text-muted'
        return (
          <li key={item.title} className="nav-item">
            <h3
              className={`nav-link ${isActive}`}
              onClick={() => setActiveTab(tabs.indexOf(item))}
              style={{ cursor: 'pointer' }}
            >
              <span>
                <CIcon icon={item.icon} className={`mx-1 ${isActive}`} />
              </span>
              {item.title}
            </h3>
          </li>
        )
      })}
    </ul>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default Tabs
