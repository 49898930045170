import React from 'react'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { CButton, CListGroup, CListGroupItem } from '@coreui/react'
import { cilCalculator } from '@coreui/icons'

const Result = ({ result, setResult }) => {
  const handleResetResult = () => {
    setResult(null)
  }

  return (
    <React.Fragment>
      <div className="result-container mb-4">
        <CListGroup className="data-container" flush>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Valor propiedad CLP</span>{' '}
            {result['credito_clp']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Valor propiedad UF</span>{' '}
            {result['credito_uf']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Valor UF hoy</span> {result['valor_uf']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Financiamiento</span>{' '}
            {result['financiamiento_porcentaje']}%
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">CAE (Tasa)</span> {result['cae']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Tipo de interés efectivo</span>{' '}
            {result['tipo_interes_activo']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Duración en años</span>{' '}
            {result['duracion_anios']}
          </CListGroupItem>
          <CListGroupItem>
            <span className="text-primary fw-semibold">Número total de pagos</span>{' '}
            {result['numero_total_pagos']}
          </CListGroupItem>
        </CListGroup>

        <CListGroup className="data-container" flush>
          <CListGroupItem>
            <span className="fw-semibold">Monto a financiar</span>{' '}
            <p className="fw-semibold text-primary">{result['valor_casa']}</p>
          </CListGroupItem>
          <CListGroupItem>
            <span className="fw-semibold">Monto del pie</span>{' '}
            <p className="fw-semibold text-primary">{result['monto_pie']}</p>
          </CListGroupItem>
          <CListGroupItem>
            <span className="fw-semibold">Dividendo</span>{' '}
            <p className="fw-semibold text-primary">{result['dividendo']}</p>
          </CListGroupItem>
          <CListGroupItem>
            <span className="fw-semibold">Renta requerida</span>{' '}
            <p className="fw-semibold text-primary">{result['renta_requerida']}</p>
          </CListGroupItem>
        </CListGroup>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <div className="ml-auto">
          <CButton
            type="button"
            color="primary"
            className="d-flex align-items-center text-white px-4 mx-4"
            onClick={() => {
              handleResetResult()
            }}
          >
            <CIcon icon={cilCalculator} size="lg" className="me-1" />{' '}
            <span>Calcular otra simulación</span>
          </CButton>
        </div>
      </div>
    </React.Fragment>
  )
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  setResult: PropTypes.func.isRequired,
}

export default Result
