import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { CCol, CContainer, CRow, CTooltip } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { capitalize, copyToClipboard } from 'src/helpers/index.js'
import { iconList } from './constants.js'
import useToast from 'src/hooks/useToast.js'
import * as icon from '@coreui/icons'
import ContentEditable from 'react-contenteditable'
import './styles.scss'

const VCEditSections = ({ sections, project, editableChanges, setEditableChanges }) => {
  const { Toaster, addToast } = useToast()

  let indicator = 0
  const SelectType = (type, value, title) => {
    const editValue =
      editableChanges[title] === ''
        ? ''
        : editableChanges[title]
        ? editableChanges[title]
        : String(value)

    const disabledField =
      title === 'comuna' || title === 'region' || title === 'pais' || title === 'direccion'
    const types = {
      TEXT:
        title === 'pre_aprobacion' ? (
          <select
            className="maintainer-select"
            defaultValue={value}
            onChange={(e) => handleChange(e, title, value)}
          >
            <option value="NO REQUIERE">NO REQUIERE</option>
            <option value="ONLINE O EJECUTIVO">ONLINE O EJECUTIVO</option>
            <option value="EJECUTIVO">EJECUTIVO</option>
            <option value="APROBACIÓN">APROBACIÓN</option>
          </select>
        ) : title === 'bonopie' ? (
          <select
            className="maintainer-select"
            defaultValue={value}
            onChange={(e) => handleChange(e, title, value)}
          >
            <option value="NO">NO</option>
            <option value="SI">SI</option>
          </select>
        ) : (
          <ContentEditable
            className="content-editable"
            html={editValue}
            disabled={disabledField || false}
            onChange={(e) => handleChange(e, title, value)}
          ></ContentEditable>
        ),
      URL: (
        <div className="d-flex align-items-center fs-6">
          <CTooltip content="Copiar enlace">
            <div
              onClick={() => {
                copyToClipboard(value)
                addToast({
                  message: 'Enlace copiado al portapapeles',
                  type: 'info',
                })
              }}
            >
              <span
                data-icon="uil:copy"
                className="iconify text-muted"
                style={{ cursor: 'pointer', width: '20px', height: '20px' }}
              ></span>
            </div>
          </CTooltip>
          <a href={value} target="_blank" rel="noreferrer" className="ms-1">
            Link
          </a>
        </div>
      ),
    }
    return types[type] ?? types.TEXT
  }

  const Segment = (array) => {
    if (!array) return null
    return array.map((item, index) => {
      return (
        <CCol
          key={index}
          sm="12"
          lg={handleLargeGrid(item.tipo, item.value)}
          className="rounded-3 p-2 editable-container"
          style={{ background: 'rgba(44, 56, 74, 0.060)' }}
        >
          <p className="m-0 fw-semibold text-truncate text-primary">{item.titulo}</p>
          {SelectType(item.tipo, item.value, item.campo || item.titulo)}
        </CCol>
      )
    })
  }

  const handleChange = (e, title, value) => {
    if (String(value) !== String(e.target.value)) {
      setEditableChanges({ ...editableChanges, [title]: e.target.value })
    }
  }

  const handleLargeGrid = (type, value) => {
    if (type === 'URL') return '2'
    const isFullWidthLg = value?.length > 30
    return isFullWidthLg ? '12' : '2'
  }

  return (
    <Fragment>
      {Toaster}
      {sections?.map((section, index) => {
        const isLastItem = index !== sections?.length - 1 ? 'mb-4' : ''
        const isFirstItem = index === 0 ? 'mt-2' : ''
        const title = section.titulo ? section.titulo : section.titulo_seccion

        if (
          (project.project_type === 'PI' && title === 'GENERAL') ||
          title === 'PREGUNTAS FRECUENTES'
        ) {
          return null
        }
        indicator++

        return (
          <CContainer key={index} className={`${isFirstItem} ${isLastItem}`}>
            <CCol
              className="text-black"
              style={{ borderBottom: '1px solid rgba(44, 56, 74, 0.22)' }}
            >
              <h3 className="fs-5">
                <span className="text-muted">{indicator}</span>
                <CIcon
                  icon={icon[iconList[section.orden]] ?? icon.cilPin}
                  className="text-primary mx-1"
                  size="lg"
                />
                {capitalize(title?.toLowerCase())}
              </h3>
            </CCol>
            <CContainer className="mt-2">
              <CRow className="gap-2">{Segment(section.contenido)}</CRow>
            </CContainer>
          </CContainer>
        )
      })}
    </Fragment>
  )
}

VCEditSections.propTypes = {
  sections: PropTypes.array,
  project: PropTypes.any,
  editableChanges: PropTypes.object.isRequired,
  setEditableChanges: PropTypes.func.isRequired,
}

export default VCEditSections
