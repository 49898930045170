import React, { useState } from 'react'
import {
  CButton,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { sendMessage } from 'src/api/SlackBot'
import useAuth from 'src/hooks/useAuth'

const ModalFeedback = ({ visible, setVisible, isError = false, addToast }) => {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { user } = useAuth()

  const handleSlack = async (type = 'Error') => {
    setLoading(true)
    const payload = {
      title: `${type === 'Error' ? ':feature-bug:' : ':rocket:'} *${
        isError ? 'Reporte' : 'Sugerencia'
      } de un usuario*`,
      channel: 'github-logs',
      ref: 'BackOffice',
      content: [
        {
          text: `*Usuario*: ${user.email}`,
        },
        {
          text: `*${type}*: ${message}`,
        },
        {
          text: `${
            type === 'Error' ? ':debug:' : ':info:'
          } El usuario ha reportado desde el modal de Feedback.`,
        },
      ],
    }
    try {
      await sendMessage(payload)
      setLoading(false)
      addToast({
        message: `Hemos recibido tu ${isError ? 'reporte' : 'sugerencia'} exitosamente!`,
        color: 'success',
      })
      return setVisible(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
      return addToast({
        message: 'Ocurrió un error al enviar tu reporte, intenta nuevamente.',
        color: 'danger',
      })
    }
  }

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      alignment="center"
    >
      <CModalHeader
        className={isError ? 'text-danger pb-0' : 'text-primary pb-0'}
        style={{ borderBottom: 'none' }}
      >
        <CModalTitle>{isError ? 'Reportar error' : 'Enviar sugerencia'}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {isError ? (
          <span>
            Por favor, <b>describe</b> el error que encontraste para que podamos solucionarlo lo
            antes posible. 🛠️
            <ul className="mt-1 m-0">
              <li>¿Qué estabas haciendo cuando ocurrió?</li>
              <li>¿En qué parte o módulo de la aplicación se produjo?</li>
            </ul>
          </span>
        ) : (
          <span>
            Tus comentarios son muy <b>importantes</b> para nosotros, por favor, nos gustaría saber
            tus ideas y opiniones para <b>mejorar</b> la aplicación. Queremos brindarte las{' '}
            <b>mejores</b> herramientas! 🚀
          </span>
        )}
        <p className="m-0 my-2 fw-semibold">Escribe tu {isError ? 'reporte' : 'sugerencia'}:</p>
        <CFormTextarea rows={3} onChange={(e) => setMessage(e.target.value)}></CFormTextarea>
      </CModalBody>
      <CModalFooter className="w-100 pt-0" style={{ borderTop: 'none' }}>
        <div className="d-flex w-100 mt-2 gap-2 justify-content-center justify-content-md-end">
          <CButton
            color={isError ? 'danger' : 'primary'}
            className="rounded-5"
            variant="outline"
            disabled={loading}
            onClick={() => setVisible(false)}
          >
            Cancelar
          </CButton>
          <CButton
            color={isError ? 'danger' : 'primary'}
            className="text-white rounded-5"
            style={{ minWidth: '90px' }}
            disabled={!message || loading}
            onClick={() => handleSlack(isError ? 'Error' : 'Sugerencia')}
          >
            {isError ? 'Reportar' : 'Enviar'}
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  )
}

ModalFeedback.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  isError: PropTypes.bool,
  addToast: PropTypes.func,
}

export default ModalFeedback
