import React, { Fragment } from 'react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCarousel,
  CCarouselItem,
  CCol,
  CImage,
  CTooltip,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import * as icon from '@coreui/icons'
import { capitalizeCombo, formatRoundUF } from 'src/helpers'

const CardT = ({ id, name, state, city, images, priceFrom, pro_currency, external_id }) => {
  const navigate = useNavigate()
  const firstWordOfName = name?.split(' ')[0]
  const modifiedName = name?.replace(/^[\w]+\sEn\sVenta\sEn\s/i, '')

  const Badges = () => {
    const spacing = { margin: '.3rem' }

    return (
      <Fragment>
        <div className="position-absolute bottom-0 start-0" style={spacing}>
          <CTooltip content="Id tarjeta">
            <CBadge
              className="d-flex align-items-center rounded-5 px-2 gap-1 fw-semibold"
              color="info"
            >
              ID: {external_id}
            </CBadge>
          </CTooltip>
        </div>
      </Fragment>
    )
  }

  const location = capitalizeCombo(`${city}, ${state}`)

  return (
    <CCard className="mb-4 position-relative shadow-sm">
      <div className="position-relative">
        <CCarousel interval={false} controls={images.length > 1}>
          {images.map((item) => (
            <CCarouselItem key={item}>
              <CImage
                src={item}
                style={{
                  height: '200px',
                  width: '100%',
                  objectFit: 'cover',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              />
            </CCarouselItem>
          ))}
        </CCarousel>

        <Badges />
      </div>
      <CCardBody style={{ height: '170px' }}>
        <div className="text-center text-capitalize">
          <h4 className="fs-7 m-0 text-muted text-truncate" title={firstWordOfName}>
            {firstWordOfName?.toLowerCase()}
          </h4>
          <h5 className="fs-6 m-0" title={name}>
            {modifiedName?.toLowerCase()}
          </h5>
          <p className="fs-7 m-0 mb-2 text-muted text-truncate" title={location}>
            {location}
          </p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-column align-items-center justify-content-start">
            <div className="d-flex flex-row align-items-center w-100">
              <CCol className="text-center" style={{ marginRight: '.4rem' }}>
                <div className="text-primary text-lg fs-5 fw-bold" style={{ marginTop: '0px' }}>
                  {pro_currency}
                </div>
              </CCol>

              <h3 className={`w-100 ${priceFrom ? '' : 'fs-5 text-warning mb-2 mt-2'}`}>
                {priceFrom ? `${formatRoundUF(priceFrom)}` : 'Consultar'}
              </h3>
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <CTooltip content="Ver detalles">
              <a href={`/#/catalogo/vista-comercial/${id}`} rel="noreferrer" className="ms-auto">
                <CButton
                  size="sm"
                  onClick={() => navigate(`/catalogo/vista-comercial/${id}`)}
                  className="text-white p-0"
                  style={{ borderRadius: '100%', height: '30px', width: '30px' }}
                >
                  <CIcon icon={icon.cilPlus} />
                </CButton>
              </a>
            </CTooltip>
          </div>
        </div>
      </CCardBody>
    </CCard>
  )
}

CardT.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  priceFrom: PropTypes.any,
  external_id: PropTypes.string,
  pro_currency: PropTypes.string,
}

export default CardT
