import React from 'react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import { CCard, CCardBody } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { cilZoom } from '@coreui/icons'
import { Table } from 'src/components/Clientes'

const ListadoCartera = ({ searchTerm, page, setPage, setTotalPages }) => {
  const navigate = useNavigate()

  const extraColumn = {
    key: 'detalle',
    label: 'Detalle',
    _props: { scope: 'col' },
  }

  const extraValueColumn = (client) => {
    return (
      <CIcon
        className="text-primary"
        icon={cilZoom}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/clientes/cliente/${client.uuid}/${client.client_type}`)}
      />
    )
  }

  return (
    <CCard className="mb-2">
      <CCardBody>
        <Table
          page={page}
          setPage={setPage}
          setTotalPages={setTotalPages}
          searchTerm={searchTerm}
          extraColumn={extraColumn}
          extraValueColumn={extraValueColumn}
        />
      </CCardBody>
    </CCard>
  )
}

ListadoCartera.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  page: PropTypes.number.isRequired,
  setTotalPages: PropTypes.func.isRequired,
}

export default ListadoCartera
