import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'

const DatosCrediticios = ({ creditData, setCreditData, setActiveTab }) => {
  const datosCrediticios = [
    {
      label: 'Credito hipotecario',
      value: '',
      type: 'select',
      options: [
        { value: 'SI', label: 'SI' },
        { value: 'NO', label: 'NO' },
      ],
      errorMessage: 'Crédito hipotecario inválido',
      allowNull: true,
    },
    {
      label: 'Años pagados crédito hipotecario',
      value: '',
      type: 'number',
      pattern: /^[0-9]\d*$/, // Números enteros positivos
      errorMessage: 'Años pagado crédito inválido',
      allowNull: true,
    },
    {
      label: 'Crédito consumo',
      value: '',
      type: 'select',
      options: [
        { value: 'SI', label: 'SI' },
        { value: 'NO', label: 'NO' },
      ],
      errorMessage: 'Crédito consumo inválido',
      allowNull: true,
    },
    {
      label: 'Años pendientes de crédito de consumo',
      value: '',
      type: 'number',
      pattern: /^[0-9]\d*$/, // Números enteros positivos
      errorMessage: 'Años de consumo de crédito',
      allowNull: true,
    },
  ]

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: creditData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()
      setCreditData(formValues)

      // Cambia a la siguiente pestaña
      setActiveTab(3)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {datosCrediticios.map((item, index) =>
          renderField(item, index, control, true, errors, ' del cliente'),
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton type="submit" color="primary" className="text-white px-4 mx-4">
            Siguiente
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosCrediticios.propTypes = {
  creditData: PropTypes.object,
  setCreditData: PropTypes.func,
  setActiveTab: PropTypes.func,
}

export default DatosCrediticios
