import React from 'react'
import PropTypes from 'prop-types'
import { CButton, CCol, CFormCheck } from '@coreui/react'
import { Controller, useForm } from 'react-hook-form'
import useToast from 'src/hooks/useToast'

export default function SelectClientType({ setStep, setClientType }) {
  const { control, handleSubmit } = useForm()
  const { Toaster, addToast } = useToast()

  const handleRadioChange = (data) => {
    if (!data || !data?.clientType) {
      addToast({ message: 'Debe seleccionar un tipo de cliente', color: 'danger' })
    } else {
      setClientType(data?.clientType)
      setStep(2)
    }
  }

  return (
    <CCol>
      {Toaster}

      <h5 className="text-primary mb-3">Selecciona el tipo de cliente</h5>

      <form className="d-flex flex-column" onSubmit={handleSubmit(handleRadioChange)}>
        <Controller
          name="clientType"
          control={control}
          render={({ field }) => (
            <div style={{ width: 'fit-content' }}>
              <CFormCheck
                type="radio"
                label="Investment"
                id="investment"
                value="investment"
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                defaultChecked={field.value === 'investment'}
              />
            </div>
          )}
        />
        <Controller
          name="clientType"
          control={control}
          render={({ field }) => (
            <div style={{ width: 'fit-content' }}>
              <CFormCheck
                type="radio"
                label="Legal y Tributario"
                id="lyt"
                value="lyt"
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                defaultChecked={field.value === 'lyt'}
              />
            </div>
          )}
        />

        <CButton
          className="btn btn-primary btn-md px-3 text-white rounded-5 mt-2 align-self-end"
          type="submit"
        >
          Continuar
        </CButton>
      </form>
    </CCol>
  )
}

SelectClientType.propTypes = {
  setStep: PropTypes.func.isRequired,
  setClientType: PropTypes.func.isRequired,
}
