import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { CButton } from '@coreui/react'
import { UI } from 'src/components'
import { stockColumns, stockLinks } from './constants'
import PropTypes from 'prop-types'
import Filters from './Filters'
import * as icon from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const initialFilters = {
  minValue: '',
  maxValue: '',
  m2min: '',
  m2max: '',
  bonopie: '',
  etapa: '',
  typology: '',
}

const sortByKey = (array, sortBy, order) => {
  const keys = {
    valorListaFormat: 'valorLista',
    m2totalFormat: 'm2total',
    m2interiorFormat: 'm2interior',
    valorConDescuentoFormat: 'valorConDescuento',
  }
  const key = keys[sortBy] || sortBy

  array.sort((a, b) => {
    if (key === 'orientacion') {
      if (order === 'asc') {
        return b[key].localeCompare(a[key])
      } else {
        return a[key].localeCompare(b[key])
      }
    } else if (key === 'typology') {
      if (order === 'asc') {
        return b[key].localeCompare(a[key], undefined, { numeric: true })
      } else {
        return a[key].localeCompare(b[key], undefined, { numeric: true })
      }
    } else {
      if (order === 'asc') {
        return a[key] - b[key]
      } else {
        return b[key] - a[key]
      }
    }
  })
}

const VCStock = ({ project, stock = [] }) => {
  const [filters, setFilters] = useState(initialFilters)
  const [filteredStock, setFilteredStock] = useState(stock)
  const [showArrows, setShowArrows] = useState({})

  const stockPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(filteredStock.length / stockPerPage)

  const [orden, setOrden] = useState('asc')
  const [claveOrden, setClaveOrden] = useState('unidad')

  const filterStock = useCallback(() => {
    const { minValue, maxValue, m2min, m2max, bonopie, etapa } = filters
    const filtered = stock.filter((item) => {
      return (
        (!minValue || item.valorLista >= minValue) &&
        (!maxValue || item.valorLista <= maxValue) &&
        (!m2min || item.m2total >= m2min) &&
        (!m2max || item.m2total <= m2max) &&
        (!bonopie || item.bonopie === bonopie) &&
        (!etapa || item.etapa === etapa) &&
        (!filters.typology || item.typology === filters.typology)
      )
    })
    let ordered = [...filtered]
    if (claveOrden) {
      sortByKey(ordered, claveOrden, orden)
    }
    setFilteredStock(ordered)
    setCurrentPage(1)
  }, [filters, stock, claveOrden, orden])

  const onPageChange = useCallback((newPage) => {
    setCurrentPage(newPage)
  }, [])

  const indexOfLastProject = currentPage * stockPerPage
  const indexOfFirstProject = indexOfLastProject - stockPerPage

  const stockItems = filteredStock.slice(indexOfFirstProject, indexOfLastProject)

  useEffect(() => {
    filterStock()
  }, [filters, filterStock])

  const validateUrlStock = stockLinks[project.name]
    ? stockLinks[project.name]
    : project.links.googleDrive

  const cambiarOrden = (clave) => {
    if (clave === claveOrden) {
      setOrden(orden === 'asc' ? 'desc' : 'asc')
    } else {
      setClaveOrden(clave)
      setOrden('asc')
    }
  }

  const handleMouseEnter = (columnKey) => {
    setShowArrows({ [columnKey]: true })
  }

  const handleMouseLeave = (columnKey) => {
    setShowArrows({ [columnKey]: false })
  }
  return (
    <Fragment>
      <Filters
        filters={filters}
        setFilters={setFilters}
        clearFilters={() => setFilters(initialFilters)}
        stock={stock}
      />
      <table className="table">
        <thead>
          <tr>
            {stockColumns.map((column) => {
              const arrow = orden === 'asc' ? icon.cilArrowBottom : icon.cilArrowBottom
              const swap =
                orden === 'asc'
                  ? { marginTop: '5px' }
                  : { transform: 'scaleY(-1)', marginTop: '5px' }
              return (
                <th
                  key={column.key}
                  onClick={() => cambiarOrden(column.key)}
                  onMouseEnter={() => handleMouseEnter(column.key)}
                  onMouseLeave={() => handleMouseLeave(column.key)}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  {column.label}
                  {(showArrows[column.key] || claveOrden === column.key) && (
                    <CIcon
                      className="ms-1 text-primary position-absolute end-0"
                      icon={arrow}
                      style={swap}
                    />
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {stockItems?.length > 0 ? (
            stockItems.map((item) => (
              <tr key={item.unidad}>
                {stockColumns.map((column) => (
                  <td key={column.key}>{item[column.key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={stockColumns.length} className="text-center mt-4">
                No se han encontrado resultados
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <UI.Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      {project.links.googleDrive && (
        <div className="d-flex flex-column mx-auto" style={{ width: 'fit-content' }}>
          <CButton color="link" size="sm" onClick={() => window.open(validateUrlStock)}>
            Consultar Manualmente
          </CButton>
        </div>
      )}
    </Fragment>
  )
}

VCStock.propTypes = {
  project: PropTypes.object,
  stock: PropTypes.array,
}

export default VCStock
