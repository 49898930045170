import React from 'react'
import { CCard, CCardBody, CCol } from '@coreui/react'

const HeaderTax = () => {
  return (
    <CCard className="mb-2">
      <CCardBody>
        <CCol>
          <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
            <div className="d-lg-block">
              <h4 className="card-title mb-0">Solicitud de Recuperación de IVA</h4>
              <p className="small text-medium-emphasis m-0">
                Formulario de Solicitud de Recuperación de IVA
              </p>
            </div>
          </div>
        </CCol>
      </CCardBody>
    </CCard>
  )
}

export default HeaderTax
