import React from 'react'
import {
  CCol,
  CPlaceholder,
  // CProgress,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { formatPrice } from 'src/helpers'
import PropTypes from 'prop-types'

const Ranking = ({ rankings, loading }) => {
  // const findMaxPR = (arr) => {
  //   const maxPR = arr.reduce((max, current) => {
  //     if (current.PR > max) {
  //       return current.PR
  //     } else {
  //       return max
  //     }
  //   }, -Infinity)
  //   return maxPR
  // }

  // const findMaxNG = (arr) => {
  //   const maxNG = arr.reduce((max, current) => {
  //     if (current.NG > max) {
  //       return current.NG
  //     } else {
  //       return max
  //     }
  //   }, -Infinity)
  //   return maxNG
  // }

  // const calculatePercent = (number, reference) => {
  //   return (number / reference) * 100
  // }

  const RenderTable = (rankings) => {
    const icon = (
      <span data-icon="fluent:trophy-24-regular" className="iconify text-primary fs-5"></span>
    )

    // const maxPR = findMaxPR(rankings)
    // const maxNG = findMaxNG(rankings)

    // const progressColor = (value) => {
    //   if (value === 100) return 'success'
    //   if (value >= 70) return 'warning'
    //   return 'danger'
    // }
    return (
      <CTable align="middle" className="mb-0 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell className="text-center" style={{ minWidth: '50px' }}>
              {icon}
            </CTableHeaderCell>
            <CTableHeaderCell style={{ width: '40%' }}>Nombre</CTableHeaderCell>
            <CTableHeaderCell style={{ width: '30%' }}>Pre Reservas</CTableHeaderCell>
            <CTableHeaderCell style={{ width: '30%' }}>Negocios Ganados</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {rankings.slice(0, 10).map((item, index) => (
            <CTableRow key={index}>
              <CTableDataCell className="text-center">
                <div className={`${index < 1 ? 'text-primary fw-bold' : 'text-muted fw-semibold'}`}>
                  {index + 1}
                </div>
              </CTableDataCell>
              <CTableDataCell>
                <div>{item.user}</div>
              </CTableDataCell>
              <CTableDataCell>
                <div>{formatPrice(item?.PR, false)}</div>
                {/* Se quitan las progress bar a pedido de Guzman */}
                {/* <CProgress thin color={'info'} value={calculatePercent(item.PR, maxPR)} /> */}
              </CTableDataCell>
              <CTableDataCell>
                <div>{formatPrice(item?.NG, false)}</div>
                {/* <CProgress thin color={'success'} value={calculatePercent(item.NG, maxNG)} /> */}
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    )
  }

  return !loading ? (
    <CCol>{RenderTable(rankings)}</CCol>
  ) : (
    <CPlaceholder animation="glow">
      <CPlaceholder xs={12} />
      <CPlaceholder xs={12} />
      <CPlaceholder xs={12} />
      <CPlaceholder xs={12} />
      <CPlaceholder xs={12} />
      <CPlaceholder xs={12} />
    </CPlaceholder>
  )
}

Ranking.propTypes = {
  loading: PropTypes.bool,
  rankings: PropTypes.any,
}

export default Ranking
