import React from 'react'
import { CCard, CCardBody, CSpinner, CTable } from '@coreui/react'
import { columns } from './constants'
import PropTypes from 'prop-types'
import './styles.scss'

const Body = ({ items, loading }) => {
  return (
    <CCard className="mb-2">
      <CCardBody style={{ minHeight: '24rem', overflowX: 'auto' }}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '38rem' }}
          >
            <CSpinner color="primary" />
          </div>
        ) : (
          <CTable
            style={{ fontSize: '0.75rem' }}
            columns={columns}
            items={items}
            hover
            responsive="md"
          />
        )}
      </CCardBody>
    </CCard>
  )
}

Body.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default Body
