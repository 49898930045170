import React from 'react'
import PropTypes from 'prop-types'
import { CFormInput, CFormLabel, CRow, CFormSwitch, CCol, CFormCheck } from '@coreui/react'
import { Controller } from 'react-hook-form'
import { UI } from 'src/components'

const creditYears = [5, 10, 15, 20, 25, 30]

const Form = ({ interest, setInterest, pieCurrency, setPieCurrency, control, errors }) => {
  return (
    <React.Fragment>
      <CRow>
        <CCol className="d-flex justify-content-center align-items-end mb-2">
          <div className="d-flex flex-column me-4" style={{ width: '12rem' }}>
            <CFormLabel className="text-primary fw-semibold" htmlFor="property_value">
              Valor de la propiedad
            </CFormLabel>
            <Controller
              name="property_value"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <CFormInput
                  type="number"
                  className="small"
                  placeholder="3000"
                  autoComplete="off"
                  value={field.value}
                  onChange={field.onChange}
                  style={
                    errors?.property_value
                      ? { height: '35px', borderColor: 'red', color: 'red' }
                      : { height: '35px' }
                  }
                />
              )}
            />
          </div>
          <Controller
            name="currency_property_value"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <div className="d-flex align-items-center" style={{ height: '35px' }}>
                <span>CLP</span>
                <CFormSwitch
                  className="mx-2"
                  id="currency_switch"
                  variant="3d"
                  color="info"
                  checked={field.value}
                  onChange={field.onChange}
                />
                <span>UF</span>
              </div>
            )}
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol className="d-flex justify-content-center align-items-end mb-4">
          <div className="d-flex flex-column me-4" style={{ width: '12rem' }}>
            <CFormLabel className="text-primary fw-semibold" htmlFor="pie_value">
              Valor del pie
            </CFormLabel>
            <Controller
              name="pie_value"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: pieCurrency ? /^(100|[1-9][0-9]?)$/ : null,
              }}
              render={({ field }) => (
                <CFormInput
                  type="number"
                  className="small"
                  placeholder="3000"
                  autoComplete="off"
                  value={field.value}
                  onChange={field.onChange}
                  style={
                    errors?.pie_value
                      ? { height: '35px', borderColor: 'red', color: 'red' }
                      : { height: '35px' }
                  }
                />
              )}
            />
          </div>
          <Controller
            name="currency_pie_value"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <div className="d-flex align-items-center" style={{ height: '35px' }}>
                <span>CLP</span>
                <CFormSwitch
                  className="mx-2"
                  id="currency_switch"
                  variant="3d"
                  color="info"
                  checked={field.value}
                  onChange={() => {
                    field.onChange(!field.value)
                    setPieCurrency(!field.value)
                  }}
                />
                <span>%</span>
              </div>
            )}
          />
        </CCol>
      </CRow>

      <CRow className="d-flex justify-content-center align-items-end my-4">
        <CFormLabel className="text-center text-primary fw-semibold" htmlFor="pie_value">
          Plazo del credito en años
        </CFormLabel>
        {creditYears.map((year) => (
          <Controller
            key={year}
            name="credit_years"
            control={control}
            defaultValue={''}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div style={{ width: 'fit-content' }}>
                <CFormCheck
                  type="radio"
                  label={`${year} años`}
                  id={`years_${year}`}
                  value={year}
                  checked={field.value === year}
                  onChange={() => field.onChange(year)}
                  style={errors?.credit_years && { borderColor: 'red', color: 'red' }}
                />
              </div>
            )}
          />
        ))}
      </CRow>

      <CRow className="d-flex flex-column justify-content-center align-items-end my-4">
        <p className="mb-2 text-center text-primary fw-semibold fs-6">Tasa de interés anual</p>
        <UI.MultiRangeSlider
          min={0}
          max={8}
          currentMax={interest}
          step={0.1}
          width={300}
          indicators={false}
          onChange={({ max }) => setInterest(max)}
          disableMin
        />
        <p className="m-0 text-center text-primary fw-semibold fs-6">{interest}%</p>
      </CRow>
    </React.Fragment>
  )
}

Form.propTypes = {
  interest: PropTypes.number.isRequired,
  setInterest: PropTypes.func.isRequired,
  pieCurrency: PropTypes.bool.isRequired,
  setPieCurrency: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.object.isRequired,
}

export default Form
