import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import localeData from 'dayjs/plugin/localeData'
import { capitalize } from 'src/helpers'

const useDate = () => {
  dayjs.locale(es)
  dayjs.extend(localeData)

  const months = dayjs.months()
  const weekDays = dayjs.weekdays()

  const getMonths = () => {
    return months.map((item) => capitalize(item))
  }

  const getWeekDays = () => {
    return weekDays.map((item) => capitalize(item))
  }

  return {
    getMonths,
    getWeekDays,
  }
}

export default useDate
