// Project Filters
export const initialMinPrice = 1
export const initialMaxPrice = 10000

export const filtersInitialValues = {
  buscar: '',
  regiones: [],
  comunas: [],
  banos: [],
  dormitorios: [],
  ordenar: '',
  venta_min: initialMinPrice,
  venta_max: initialMaxPrice,
}

export const selectInitialValues = [
  { title: 'regiones', options: [] },
  { title: 'comunas', options: [] },
  { title: 'banos', options: [] },
  { title: 'dormitorios', options: [] },
  { title: 'ordenar', options: [] },
]

// Modal Filters
export const showSelects = ['regiones', 'comunas', 'banos', 'dormitorios', 'ordenar']

export const rowSelects = ['banos', 'dormitorios']

export const isMultiSelect = {
  regiones: true,
  comunas: true,
}

export const titleValues = {
  banos: 'Baños',
  ordenar: 'Ordenar por',
}

// Modal Calculate
export const inputs = [
  {
    key: 'ingreso_liquido',
    text: 'Ingreso Líquido',
    size: '12',
  },
  {
    key: 'ac',
    text: 'Años Crédito',
    size: '4',
  },
  {
    key: 'financiamiento',
    text: 'Financiamiento (%)',
    size: '4',
  },
  {
    key: 'valor_hip',
    text: 'Hipotecario Mensual',
    size: '4',
  },
  {
    key: 'valor_con',
    text: 'Consumo Mensual',
    size: '4',
  },
  {
    key: 'interes_anual',
    text: 'Interés Anual (%)',
    size: '4',
  },
  {
    key: 'valor_uf',
    text: 'Valor UF',
    size: '4',
  },
]
