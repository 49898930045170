import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
import { UI } from './components'
import useAuth from './hooks/useAuth'
import Hotjar from '@hotjar/browser'
import './scss/style.scss'

const DefaultLayout = lazy(() => import('./layout/DefaultLayout'))
const Login = lazy(() => import('./views/pages/login/Login'))
const Register = lazy(() => import('./views/pages/register/Register'))
const Page404 = lazy(() => import('./views/pages/page404/Page404'))
const Page500 = lazy(() => import('./views/pages/page500'))

// Hotjar
const siteId = 3660760
const hotjarVersion = 6
Hotjar.init(siteId, hotjarVersion)

// En este componente se renderizan las rutas de la aplicación que no necesiten disponer del layout de la aplicación.
const App = () => {
  const { user } = useAuth()

  return (
    <HashRouter>
      <Suspense fallback={<UI.Loading />}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Inicio" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
      {user && <UI.Chat />}
    </HashRouter>
  )
}

export default App
