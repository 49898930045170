import './styles.scss'

const columns = [
  {
    key: 'fecha',
    label: 'Fecha',
    _props: { scope: 'col' },
  },
  {
    key: 'broker',
    label: 'Broker',
    _props: { scope: 'col' },
  },
  {
    key: 'proyecto',
    label: 'Proyecto',
    _props: { scope: 'col' },
  },
  {
    key: 'nombrecliente',
    label: 'Nombre del cliente',
    _props: { scope: 'col' },
  },
  {
    key: 'rutcliente',
    label: 'RUT del cliente',
    _props: { scope: 'col' },
  },
  {
    key: 'inmobiliaria',
    label: 'Inmobiliaria',
    _props: { scope: 'col' },
  },
  {
    key: 'monto',
    label: 'Monto',
    _props: { scope: 'col' },
  },
  {
    key: 'reservationId',
    label: 'ID',
    _props: { scope: 'col' },
  },
  {
    key: 'link',
    label: 'Link de pago',
    _props: { scope: 'col' },
  },
  {
    key: 'estado',
    label: 'Estado',
    _props: { scope: 'col' },
  },
  {
    key: 'actions',
    label: '',
    _props: { scope: 'col' },
  },
]

export { columns }
