import React, { useEffect } from 'react'
import { CCard, CCardBody, CCol } from '@coreui/react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Search } from 'src/components/Clientes'

const HeaderCartera = ({ searchTerm, setSearchTerm }) => {
  const { handleSubmit, control, setValue } = useForm()

  const clearSearchTerm = () => {
    setValue('searchTerm', '')
  }

  useEffect(() => {
    if (!searchTerm) {
      clearSearchTerm()
    }
  }, [searchTerm])

  return (
    <CCard className="mb-2">
      <CCardBody>
        <CCol>
          <h4 className="card-title mb-0">Cartera de clientes</h4>
        </CCol>
        <CCol className="mt-2 mb-md-0" xs="12" sm="12" md="6" lg="4">
          <Search handleSubmit={handleSubmit} control={control} setSearchTerm={setSearchTerm} />
        </CCol>
      </CCardBody>
    </CCard>
  )
}

HeaderCartera.propTypes = {
  setSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
}

export default HeaderCartera
