import React, { useEffect, useState } from 'react'
import { CCol, CFormInput, CRow } from '@coreui/react'
import { UI } from 'src/components'
import Select from 'react-select'
import PropTypes from 'prop-types'

const Filters = ({ filters, setFilters, clearFilters, stock }) => {
  const [selectOptions, setSelectOptions] = useState([])

  const getSelectOptions = (array) => {
    const uniqueTypologies = [...new Set(array.map((item) => item.typology))]
    const options = uniqueTypologies.map((typology) => ({
      label: typology,
      value: typology,
    }))
    return setSelectOptions(options)
  }
  useEffect(() => {
    if (!stock) return
    getSelectOptions(stock)
  }, [stock])

  return (
    <CRow className="my-2">
      <CCol xs="12" sm="6" md="4" lg="2" className="mb-2 mb-lg-0">
        <Select
          value={selectOptions.find((option) => option.value === filters.typology)}
          placeholder="Tipologías"
          closeMenuOnSelect
          options={[{ label: 'Mostrar todas', value: '' }, ...selectOptions]}
          onChange={(e) => setFilters({ ...filters, typology: e.value })}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </CCol>
      <CCol xs="12" sm="6" md="4" lg="2" className="mb-2 mb-lg-0">
        <CFormInput
          type="number"
          placeholder="Valor Min"
          value={filters.minValue}
          onChange={(e) => setFilters({ ...filters, minValue: parseFloat(e.target.value) })} // Convierte el valor a número
          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
        />
      </CCol>
      <CCol xs="12" sm="6" md="4" lg="2" className="mb-2 mb-lg-0">
        <CFormInput
          type="number"
          placeholder="Valor Max"
          value={filters.maxValue}
          onChange={(e) => setFilters({ ...filters, maxValue: parseFloat(e.target.value) })}
          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
        />
      </CCol>
      <CCol xs="12" sm="6" md="4" lg="2" className="mb-2 mb-lg-0">
        <CFormInput
          type="number"
          placeholder="M2 Min"
          value={filters.m2min}
          onChange={(e) => setFilters({ ...filters, m2min: parseFloat(e.target.value) })}
          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
        />
      </CCol>
      <CCol xs="12" sm="6" md="4" lg="2" className="mb-2 mb-lg-0">
        <CFormInput
          type="number"
          placeholder="M2 Max"
          value={filters.m2max}
          onChange={(e) => setFilters({ ...filters, m2max: parseFloat(e.target.value) })}
          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
        />
      </CCol>
      <CCol
        className="d-flex flex-row align-items-center gap-2 mb-2 mb-lg-0"
        xs="12"
        sm="6"
        md="4"
        lg="2"
      >
        <CFormInput
          type="number"
          placeholder="Etapa"
          value={filters.etapa}
          onChange={(e) => setFilters({ ...filters, etapa: parseFloat(e.target.value) })}
          style={{ borderColor: 'hsl(0, 0%, 80%)' }}
        />
        <div style={{ minWidth: '30px', maxWidth: '30px' }}>
          <UI.CircleButton onClick={clearFilters} active={false} icon="pajamas:clear-all" />
        </div>
      </CCol>
    </CRow>
  )
}

Filters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  stock: PropTypes.array,
}

export default Filters
