import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { UI } from 'src/components'
import PropTypes from 'prop-types'

const ConfirmChangesModal = ({
  showConfirmAlert,
  setShowConfirmAlert,
  handleConfirmChanges,
  loading,
}) => {
  return (
    <CModal
      backdrop="static"
      visible={showConfirmAlert}
      onClose={() => setShowConfirmAlert(false)}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Características</CModalTitle>
      </CModalHeader>
      <CModalBody className="text-center">
        {loading && <UI.Loading />}
        <p className="mb-0">Confirmar los cambios de las características de este proyecto</p>
      </CModalBody>
      <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          <CButton
            color="success"
            className="text-white rounded-5"
            onClick={() => {
              handleConfirmChanges()
            }}
            size="sm"
            disabled={loading}
          >
            Confirmar
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  )
}

ConfirmChangesModal.propTypes = {
  showConfirmAlert: PropTypes.bool.isRequired,
  setShowConfirmAlert: PropTypes.func.isRequired,
  handleConfirmChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default ConfirmChangesModal
