import React, { useEffect, useState } from 'react'
import { CCard, CButton, CCardBody } from '@coreui/react'
import { getIndicators, getSimulation } from 'src/api'
import { cilCalculator } from '@coreui/icons'
import { getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import { useForm } from 'react-hook-form'
import { UI } from 'src/components'
import useToast from 'src/hooks/useToast'
import Result from './Result'
import CIcon from '@coreui/icons-react'
import Form from './Form'
import './styles.scss'

const Body = () => {
  const { Toaster, addToast } = useToast()

  const [interest, setInterest] = useState(5.5)
  const [pieCurrency, setPieCurrency] = useState(true)
  const [result, setResult] = useState(null)
  const [indicators, setIndicators] = useState(null)
  const [loading, setLoading] = useState(false)

  const userEmail = getUserEmail()

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: {
      credit_years: 30,
    },
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)

  const handleSimular = (data) => {
    const formValues = { ...data, anual_interest: interest }
    if (!Object.keys(errors).length) {
      setLoading(true)
      const bodySimulator = {
        sw_propiedad_divisa: formValues['currency_property_value'] ? 'UF' : 'CLP',
        sw_pie_divisa: formValues['currency_pie_value'] ? '%' : 'CLP',
        valor_propiedad: formValues['property_value'],
        valor_pie: formValues['pie_value'],
        uf: indicators?.uf,
        plazo_credito: formValues['credit_years'],
        InteresNominal: formValues['anual_interest'],
        broker_email: userEmail,
      }
      getSimulation(bodySimulator)
        .then((res) => {
          setResult(res.data.result)
          setLoading(false)
        })
        .catch((err) => {
          handlerSendErrorNotification(err)
          setLoading(false)
          addToast({ message: err?.data?.message, color: 'danger' })
        })
    }
  }

  useEffect(() => {
    const cachedIndicators = localStorage.getItem('indicators')

    if (!cachedIndicators) {
      getIndicators()
        .then((res) => {
          if (res?.data?.result) {
            localStorage.setItem('indicators', JSON.stringify(res.data.result))
            setIndicators(res?.data?.result)
          }
        })
        .catch((err) => {
          handlerSendErrorNotification(err)
          addToast({
            message: err?.message || 'Error al obtener valores de los indicadores financieros.',
            color: 'danger',
          })
        })
    } else {
      setIndicators(JSON.parse(cachedIndicators))
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(handleSimular)} style={{ position: 'relative' }}>
      {Toaster}

      {loading && <UI.Loading />}

      <CCard className="mb-2 py-2">
        {!result ? (
          <CCardBody className="d-flex flex-column">
            <Form
              interest={interest}
              setInterest={setInterest}
              pieCurrency={pieCurrency}
              setPieCurrency={setPieCurrency}
              control={control}
              errors={errors}
            />

            <div className="d-flex justify-content-center mt-4">
              <div className="ml-auto">
                <CButton
                  type="submit"
                  color="primary"
                  className="d-flex align-items-center text-white px-4 mx-4"
                >
                  <CIcon icon={cilCalculator} size="lg" className="me-1" />{' '}
                  <span>Calcular mi simulación</span>
                </CButton>
              </div>
            </div>
          </CCardBody>
        ) : (
          <CCardBody className="d-flex flex-column">
            <Result result={result} setResult={setResult} />
          </CCardBody>
        )}
      </CCard>
    </form>
  )
}

export default Body
