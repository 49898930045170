import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import {
  formatInputPrice,
  getUserEmail,
  parseReservationDetail,
  parseReservationStatus,
  handlerSendErrorNotification,
} from 'src/helpers'
import { Controller, useForm } from 'react-hook-form'
import { getReservation, updateReservation } from 'src/api'
import { cilWarning } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import useToast from 'src/hooks/useToast'
import { UI } from '..'

const ReservationActions = ({
  invoiceStatus,
  reservation_id,
  invoiceAmount,
  setInvoiceDetail,
  setResetReservation,
}) => {
  const { control, getValues } = useForm()
  const { Toaster, addToast } = useToast()

  const userEmail = getUserEmail()

  const [disableChangeButton, setDisableChangeButton] = useState(true)
  const [stateOptions, setStateOptions] = useState([])
  const [changesModal, setChangesModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCancelReservation = (reservation_id) => {
    setLoading(true)
    const body = {
      reservation_state: 'cancelled',
      operation_email: userEmail,
    }

    updateReservation(reservation_id, body)
      .then((res) => {
        addToast({
          color: 'warning',
          message: 'Reserva anulada correctamente.',
        })
        setResetReservation(true)
        setCancelModal(false)
        setLoading(false)
      })
      .catch((err) => {
        handlerSendErrorNotification(err)
        addToast({
          color: 'danger',
          message: 'Error al intentar anular la reserva.',
        })
        setLoading(false)
      })
  }

  const handleChangeValues = (reservation_id) => {
    setLoading(true)
    const body = {
      operation_email: userEmail,
    }

    const values = getValues()
    if (values.state && String(values.state) !== String(invoiceStatus)) {
      body.reservation_state = values.state
    } else if (values.amount && Number(values.amount) !== Number(invoiceAmount)) {
      body.amount = Number(values.amount.replaceAll(',', ''))
    }

    if (body.amount <= 0) {
      addToast({ color: 'danger', message: 'El monto de la reserva no puede ser menor a 1' })
      setLoading(false)
    } else {
      updateReservation(reservation_id, body)
        .then(async (res) => {
          addToast({
            color: 'success',
            message: 'Reserva actualizada correctamente.',
          })
          const reservation = await getReservation(reservation_id)
          setResetReservation(true)
          setInvoiceDetail(parseReservationDetail(reservation.data.detail))
          setChangesModal(false)
          setLoading(false)
        })
        .catch((err) => {
          handlerSendErrorNotification(err)
          addToast({
            color: 'danger',
            message:
              err?.response?.data?.detail?.message || 'Error al intentar actualizar la reserva.',
          })
          setLoading(false)
        })
    }
  }

  const handleDisableSaveChanges = () => {
    const values = getValues()
    if (
      String(values.state) !== String(invoiceStatus) ||
      Number(values.amount) !== Number(invoiceAmount)
    ) {
      setDisableChangeButton(false)
    } else {
      setDisableChangeButton(true)
    }
  }

  useEffect(() => {
    const paidOptions = [
      {
        value: 'real_estate',
        description: 'Inmobiliaria',
      },
      {
        value: 'reassigned',
        description: 'Reasignada',
      },
      {
        value: 'refund',
        description: 'Devuelto al cliente',
      },
      {
        value: 'paid',
        description: 'Pagada',
      },
    ]

    const pendingOptions = [
      {
        value: 'pending',
        description: 'Pendiente',
      },
      {
        value: 'paid',
        description: 'Pagada',
      },
    ]

    const reassignedOptions = [
      {
        value: 'real_estate',
        description: 'Inmobiliaria',
      },
      {
        value: 'reassigned',
        description: 'Reasignada',
      },
      {
        value: 'refund',
        description: 'Devuelto al cliente',
      },
    ]

    const allOptions = [
      {
        value: 'pending',
        description: 'Pendiente',
      },
      {
        value: 'paid',
        description: 'Pagada',
      },
      {
        value: 'cancelled',
        description: 'Anulada',
      },
      {
        value: 'real_estate',
        description: 'Inmobiliaria',
      },
      {
        value: 'reassigned',
        description: 'Reasignada',
      },
      {
        value: 'refund',
        description: 'Devuelto al cliente',
      },
      {
        value: 'overdue',
        description: 'Vencida',
      },
    ]

    if (invoiceStatus === 'paid') {
      setStateOptions(paidOptions)
    } else if (invoiceStatus === 'reassigned') {
      setStateOptions(reassignedOptions)
    } else if (invoiceStatus === 'pending') {
      setStateOptions(pendingOptions)
    } else {
      setStateOptions(allOptions)
    }
  }, [invoiceStatus])

  return (
    <React.Fragment>
      {Toaster}
      <CCard className="mb-2">
        <CCardBody className="d-flex flex-column px-4 py-3">
          <h2 className="text-primary">Acciones</h2>
        </CCardBody>
      </CCard>
      <CCard className="mb-2">
        <CCardBody className="d-flex flex-column p-4">
          <div className="d-flex flex-column mb-4 w-100">
            <CFormLabel htmlFor="state" className="fw-semibold">
              Estado de la reserva
            </CFormLabel>
            {invoiceStatus && (
              <Controller
                name="state"
                control={control}
                defaultValue={invoiceStatus}
                render={({ field }) => (
                  <CFormSelect
                    {...field}
                    className="small w-100"
                    disabled={
                      invoiceStatus === 'cancelled' ||
                      invoiceStatus === 'real_estate' ||
                      invoiceStatus === 'overdue' ||
                      invoiceStatus === 'refund' ||
                      invoiceStatus === 'reassigned'
                    }
                    style={{ width: '10rem' }}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      handleDisableSaveChanges()
                    }}
                  >
                    {stateOptions.map((option, optionIndex) => (
                      <option key={optionIndex} value={option.value}>
                        {option.description}
                      </option>
                    ))}
                  </CFormSelect>
                )}
              />
            )}

            <CFormLabel htmlFor="amount" className="fw-semibold mt-2">
              Monto de la reserva
            </CFormLabel>
            <Controller
              name="amount"
              control={control}
              disabled={invoiceStatus !== 'pending'}
              defaultValue={invoiceAmount}
              render={({ field }) => (
                <CFormInput
                  {...field}
                  className="small w-100"
                  style={{ width: '10rem' }}
                  value={formatInputPrice(field.value)}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    handleDisableSaveChanges()
                  }}
                ></CFormInput>
              )}
            />
          </div>

          {/* Cancel modal */}
          <CModal
            backdrop="static"
            visible={cancelModal}
            onClose={() => setCancelModal(false)}
            alignment="center"
          >
            <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
              <CModalTitle>Anulación de la reserva</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center">
              {loading && <UI.Loading />}
              <p className="mb-0">Confirmo la anulación de la reserva con ID:</p>
              <p className="fw-semibold text-primary mb-0">{reservation_id}</p>
            </CModalBody>
            <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
              <div className="d-flex flex-column mx-auto w-75">
                <CButton
                  color="danger"
                  className="text-white rounded-5"
                  onClick={() => {
                    handleCancelReservation(reservation_id)
                  }}
                  size="sm"
                  disabled={loading}
                >
                  <CIcon icon={cilWarning} className="me-1" />
                  Confirmar anulación
                  <CIcon icon={cilWarning} className="ms-1" />
                </CButton>
              </div>
            </CModalFooter>
          </CModal>

          {/* Save changes modal */}
          <CModal
            backdrop="static"
            visible={changesModal}
            onClose={() => setChangesModal(false)}
            alignment="center"
          >
            <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
              <CModalTitle>Modificar la reserva</CModalTitle>
            </CModalHeader>
            <CModalBody className="text-center">
              {loading && <UI.Loading />}
              {getValues()?.state &&
              String(getValues()?.state) !== String(invoiceStatus) &&
              getValues()?.amount &&
              Number(getValues()?.amount) !== Number(invoiceAmount) ? (
                <div>
                  <p className="mb-0">Confirmo el cambio de estado a:</p>
                  <p className="fw-semibold text-primary mb-0">
                    {parseReservationStatus(getValues()?.state)}
                  </p>
                  <p className="mb-0 mt-2">y el monto a:</p>
                  <p className="fw-semibold text-primary mb-0">
                    ${formatInputPrice(getValues()?.amount)}
                  </p>
                </div>
              ) : String(getValues()?.state) !== String(invoiceStatus) ? (
                <div>
                  <p className="mb-0">Confirmo el cambio de estado a:</p>
                  <p className="fw-semibold text-primary mb-0">
                    {parseReservationStatus(getValues()?.state)}
                  </p>
                </div>
              ) : Number(getValues()?.amount) !== Number(invoiceAmount) ? (
                <div>
                  <p className="mb-0">Confirmo el cambio del monto de la reserva a:</p>
                  <p className="fw-semibold text-primary mb-0">
                    ${formatInputPrice(getValues()?.amount)}
                  </p>
                </div>
              ) : null}
            </CModalBody>
            <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
              <div className="d-flex flex-column mx-auto w-75">
                <CButton
                  color="success"
                  className="text-white rounded-5"
                  onClick={() => {
                    handleChangeValues(reservation_id)
                  }}
                  size="sm"
                  disabled={loading}
                >
                  Confirmar cambios
                </CButton>
              </div>
            </CModalFooter>
          </CModal>

          <div className="d-flex justify-content-end gap-2 w-100 mt-4">
            <CButton
              onClick={() => {
                setCancelModal(true)
              }}
              color="danger"
              className="text-white w-50"
              disabled={
                invoiceStatus === 'cancelled' ||
                invoiceStatus === 'refund' ||
                invoiceStatus === 'reassigned' ||
                invoiceStatus === 'real_estate' ||
                invoiceStatus === 'paid' ||
                invoiceStatus === 'overdue'
              }
            >
              <CIcon icon={cilWarning} className="me-1" />
              Anular reserva
            </CButton>
            <CButton
              onClick={() => {
                setChangesModal(true)
              }}
              color="success"
              className="text-white w-50"
              disabled={
                disableChangeButton ||
                invoiceStatus === 'cancelled' ||
                invoiceStatus === 'real_estate' ||
                invoiceStatus === 'refund' ||
                invoiceStatus === 'reassigned'
              }
            >
              Confirmar cambios
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </React.Fragment>
  )
}

ReservationActions.propTypes = {
  invoiceAmount: PropTypes.number.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  reservation_id: PropTypes.string.isRequired,
  setInvoiceDetail: PropTypes.func.isRequired,
  setResetReservation: PropTypes.func.isRequired,
}

export default ReservationActions
