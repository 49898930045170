import React, { useEffect, useRef, useState } from 'react'
import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { UI } from 'src/components'
import Indicators from './Indicators'
import { capitalize, formatRoundUF } from 'src/helpers'
import { showSelects, isMultiSelect, rowSelects, titleValues } from './constants'

const ModalFilters = ({
  visible,
  setVisible,
  filters,
  setFilters,
  selectsArray,
  searchData,
  resetFilters,
  minPrice,
  maxPrice,
}) => {
  const [sliderWidth, setSliderWidth] = useState(0)
  const modalBodyRef = useRef(null)

  const handleSelectChange = (item, selectedOptions) => {
    setFilters({
      ...filters,
      [item.title]: selectedOptions,
    })
  }

  const handlePriceRangeChange = ({ min, max }) => {
    setFilters({
      ...filters,
      venta_min: min,
      venta_max: max,
    })
  }

  // Width Automático para el slider
  const updateSliderWidth = () => {
    if (modalBodyRef.current) {
      const margin = 30 // Espaciado lateral
      const newWidth = modalBodyRef.current.offsetWidth - margin
      setSliderWidth(newWidth)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSliderWidth)
    updateSliderWidth()
    return () => {
      // Limpia el event listener al desmontar el componente
      window.removeEventListener('resize', updateSliderWidth)
    }
  }, [])

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Filtros</CModalTitle>
      </CModalHeader>
      <CModalBody ref={modalBodyRef}>
        <div className="d-flex flex-column gap-2">
          <p className="m-0 text-primary fw-semibold fs-6">Ubicación y Características</p>
          <CRow>
            {selectsArray &&
              selectsArray.map((item, index) => {
                if (!showSelects.includes(item.title)) return null
                const renderMultiSelect = isMultiSelect[item.title] || false
                const selectValue = isMultiSelect ? filters?.[item.title] : [filters?.[item.title]]
                return (
                  <CCol
                    key={index}
                    className="mb-2"
                    xs="12"
                    md={rowSelects.includes(item.title) ? '6' : '12'}
                  >
                    <Select
                      value={selectValue}
                      placeholder={titleValues[item.title] || capitalize(item.title)}
                      options={item.options}
                      components={makeAnimated()}
                      noOptionsMessage={() => null}
                      closeMenuOnSelect={!renderMultiSelect}
                      isMulti={renderMultiSelect}
                      onChange={(selectedOptions) => handleSelectChange(item, selectedOptions)}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      isClearable
                    />
                  </CCol>
                )
              })}
          </CRow>
          <div>
            <p className="m-0 text-primary fw-semibold fs-6">Rango de Precio</p>
            <UI.MultiRangeSlider
              min={minPrice}
              max={maxPrice}
              currentMin={Number(filters.venta_min)}
              currentMax={Number(filters.venta_max)}
              width={sliderWidth}
              indicators={false}
              onChange={({ min, max }) => handlePriceRangeChange({ min, max })}
            />
            <Indicators
              min={formatRoundUF(filters.venta_min)}
              max={formatRoundUF(filters.venta_max)}
              minRightPrefix=" UF"
              maxRightPrefix=" UF"
            />
          </div>
        </div>
      </CModalBody>
      <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          <CButton
            color="link"
            className="mb-2 text-decoration-none"
            size="sm"
            onClick={() => {
              resetFilters()
              setVisible(false)
            }}
          >
            Limpiar filtros
          </CButton>
          <CButton
            onClick={() => {
              searchData()
              setVisible(false)
            }}
            color="primary"
            className="text-white rounded-5"
            size="sm"
          >
            Aplicar filtros
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  )
}

ModalFilters.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  selectsArray: PropTypes.array,
  searchData: PropTypes.func,
  resetFilters: PropTypes.func,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
}

export default ModalFilters
