import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CCard,
  CCardBody,
  CCol,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import useToast from 'src/hooks/useToast'
import SelectClientType from './SelectClientType'
import * as DatosRegister from './DatosRegister'
import * as DatosRegisterLYT from './DatosRegisterLYT'
import * as icon from '@coreui/icons'
import {
  formatCreateClientData,
  formatCreateClientLYTData,
  getUserEmail,
  handlerSendErrorNotification,
} from 'src/helpers'
import { registerClient } from 'src/api'
import PropTypes from 'prop-types'

// Estilos de la card general
const cardStyle = {
  borderTopRightRadius: '0px',
  borderTopLeftRadius: '0px',
  borderTop: 'none',
}

const Register = ({ step, setStep, availableTabs, setAvailableTabs, activeTab, setActiveTab }) => {
  const navigate = useNavigate()
  const { Toaster, addToast } = useToast()

  const [showFinalAlert, setShowFinalAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Estados con los valores de los formularios
  const [personalLYTData, setPersonaLYTData] = useState({})
  const [projectData, setProjectData] = useState({})
  const [personalData, setPersonalData] = useState({})
  const [financialData, setFinancialData] = useState({})
  const [creditData, setCreditData] = useState({})
  const [objectiveData, setObjectiveData] = useState({})

  const [clientType, setClientType] = useState(null)
  // const [tabs, setTabs] = useState([])

  // Si la tab se completo, se permite viajar entre ellas
  const handleTabChange = (tabIndex) => {
    if (tabIndex <= availableTabs) {
      setActiveTab(tabIndex)
    }
  }

  // Si la tab se completo, se setea el estado "availableTabs" con el indice de la tab completa (para permitir el habdleTabChange)
  const handleSetAvailableTabs = () => {
    if (activeTab > availableTabs) {
      setAvailableTabs(activeTab)
    }
  }

  // Armado de body y llamado al servicio de creacion de cliente
  const handleCreateClient = () => {
    setIsLoading(true)
    const userEmail = getUserEmail()

    if (clientType === 'lyt') {
      const clientData = formatCreateClientLYTData(personalLYTData, projectData)

      registerClient(clientData, userEmail, 'lyt')
        .then((res) => {
          addToast({ message: 'Cliente registrado con éxito.', color: 'success' })
          setIsLoading(false)
          setShowFinalAlert(false)
          setTimeout(() => {
            navigate('/clientes')
          }, 2000)
        })
        .catch((err) => {
          console.log(err)
          handlerSendErrorNotification(err)
          addToast({
            message: 'Error interno al agregar cliente. Por favor comunicarse con soporte.',
            color: 'danger',
          })
          setIsLoading(false)
        })
      setIsLoading(false)
    } else {
      const clientData = formatCreateClientData(
        personalData,
        financialData,
        creditData,
        objectiveData,
      )

      registerClient(clientData, userEmail, 'investment')
        .then((res) => {
          addToast({ message: 'Cliente registrado con éxito.', color: 'success' })
          setIsLoading(false)
          setShowFinalAlert(false)
          setTimeout(() => {
            navigate('/clientes')
          }, 2000)
        })
        .catch((err) => {
          console.error(err)
          handlerSendErrorNotification(err)
          addToast({
            message: 'Error interno al agregar cliente. Por favor comunicarse con soporte.',
            color: 'danger',
          })
          setIsLoading(false)
        })
    }
  }

  // Cuando se carga un nuevo valor al estado "activeTab", se llama a la funcion para indicar las tabs completas
  useEffect(() => {
    handleSetAvailableTabs()
  }, [activeTab])

  const tabs =
    clientType === 'lyt'
      ? [
          {
            title: 'Datos personales',
            icon: icon.cilUser,
            component: (
              <DatosRegisterLYT.DatosPersonales
                personalLYTData={personalLYTData}
                setPersonaLYTData={setPersonaLYTData}
                setActiveTab={setActiveTab}
                setShowFinalAlert={setShowFinalAlert}
              />
            ),
          },
          {
            title: 'Datos del proyecto',
            icon: icon.cilUser,
            component: (
              <DatosRegisterLYT.DatosProyecto
                projectData={projectData}
                setProjectData={setProjectData}
                setActiveTab={setActiveTab}
                setShowFinalAlert={setShowFinalAlert}
              />
            ),
          },
        ]
      : [
          {
            title: 'Datos personales',
            icon: icon.cilUser,
            component: (
              <DatosRegister.DatosPersonales
                personalData={personalData}
                setPersonalData={setPersonalData}
                setActiveTab={setActiveTab}
                setShowFinalAlert={setShowFinalAlert}
              />
            ),
          },
          {
            title: 'Datos financieros',
            icon: icon.cilCash,
            component: (
              <DatosRegister.DatosFinancieros
                financialData={financialData}
                setFinancialData={setFinancialData}
                setActiveTab={setActiveTab}
              />
            ),
          },
          {
            title: 'Datos crediticios',
            icon: icon.cilCreditCard,
            component: (
              <DatosRegister.DatosCrediticios
                creditData={creditData}
                setCreditData={setCreditData}
                setActiveTab={setActiveTab}
              />
            ),
          },
          {
            title: 'Datos objetivos',
            icon: icon.cilChartLine,
            component: (
              <DatosRegister.DatosObjetivos
                objectiveData={objectiveData}
                setObjectiveData={setObjectiveData}
                setShowFinalAlert={setShowFinalAlert}
              />
            ),
          },
        ]

  return (
    <CCard className="mb-2">
      {Toaster}

      <CCardBody>
        {step === 1 && <SelectClientType setStep={setStep} setClientType={setClientType} />}
        {step === 2 && (
          <CCol>
            {/* Pestañas */}
            <ul className="nav nav-tabs">
              {tabs.map((item, index) => {
                const isActive = activeTab === index ? 'active text-primary' : 'text-muted'
                return (
                  <li key={item.title} className="nav-item">
                    <h3
                      className={`nav-link ${isActive}`}
                      onClick={() => handleTabChange(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span>
                        <CIcon icon={item.icon} className={`mx-1 ${isActive}`} />
                      </span>
                      {item.title}
                    </h3>
                  </li>
                )
              })}
            </ul>

            {/* Pestaña Activa */}
            <CCard className="mb-2" style={cardStyle}>
              <CCardBody>{tabs[activeTab].component}</CCardBody>
            </CCard>

            {/* Confirmacion de creacion de cliente */}
            <CModal
              visible={showFinalAlert}
              alignment="center"
              onClose={() => setShowFinalAlert(false)}
            >
              <CModalHeader>
                <CModalTitle>Confirmar los datos</CModalTitle>
              </CModalHeader>

              <CModalBody
                className={isLoading ? 'd-flex justify-content-center align-items-center' : ''}
              >
                {isLoading ? (
                  <CSpinner color="primary" alignment="center" style={{ margin: '1rem auto' }} />
                ) : (
                  <p>¿Está seguro que desea crear un nuevo cliente?</p>
                )}
              </CModalBody>
              <CModalFooter>
                <CButton
                  disabled={isLoading}
                  color="secondary"
                  className="text-white"
                  onClick={() => setShowFinalAlert(false)}
                >
                  Cancelar
                </CButton>
                <CButton
                  disabled={isLoading}
                  color="primary"
                  className="text-white"
                  onClick={() => handleCreateClient()}
                >
                  Confirmar
                </CButton>
              </CModalFooter>
            </CModal>
          </CCol>
        )}
      </CCardBody>
    </CCard>
  )
}

Register.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  availableTabs: PropTypes.number.isRequired,
  setAvailableTabs: PropTypes.func.isRequired,
}

export default Register
