import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField, handleOnSubmit } from 'src/helpers'
import { CButton } from '@coreui/react'
import useToast from 'src/hooks/useToast'
import './styles.scss'
import { UI } from 'src/components'

const DatosFinancieros = ({ clientData, id }) => {
  const [editing, setEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [datosFinancieros, setDatosFinancieros] = useState([])
  const [auxDatosFinancieros, setAuxDatosFinancieros] = useState([])

  const [loading, setLoading] = useState(false)

  const { Toaster, addToast } = useToast()

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
  }

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = async (data) => {
    setLoading(true)
    const result = await handleOnSubmit(
      clientData,
      data,
      editing,
      editedData,
      datosFinancieros,
      setDatosFinancieros,
      setEditing,
      setEditedData,
      'financialData',
      id,
      'investment',
    )

    if (result) {
      addToast({
        message: result.data.message,
        color: result.status === 200 ? 'success' : 'danger',
      })
    }
    setLoading(false)
  }

  // Seteo de la informacion del cliente recuperada por servicio
  const setClientData = () => {
    if (clientData) {
      setDatosFinancieros([
        {
          label: 'Lugar de trabajo',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          value: clientData.employment_place || '',
          type: 'text',
          allowNull: true,
        },
        {
          label: 'Contrato',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          value: clientData.contract_type || '',
          type: 'text',
          allowNull: true,
        },
        {
          label: 'Antiguedad en meses',
          value: clientData.seniority || '',
          type: 'number',
          pattern: /^[0-9]+$/, // Números enteros positivos
          allowNull: true,
        },
        {
          label: 'Banco',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          value: clientData.bank || '',
          type: 'text',
          allowNull: true,
        },
        {
          label: 'Tipo de cuenta',
          pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          value: clientData.account_type || '',
          type: 'text',
          allowNull: true,
        },
        {
          label: 'Ingresos líquidos',
          value: clientData.net_income || '',
          type: 'text',
          pattern: /^[0-9,.]{0,50}$/, // 50 dígitos numéricos
          errorMessage: 'Ingresos líquidos inválidos',
          formatPrice: true,
          allowNull: true,
        },
        {
          label: 'Ingresos adicionales',
          value: clientData.additional_income || '',
          type: 'text',
          pattern: /^[0-9,.]{0,50}$/, // 50 dígitos numéricos
          formatPrice: true,
          allowNull: true,
        },
        {
          label: 'Capacidad de ahorro',
          value: clientData.savings_capacity || '',
          type: 'select',
          options: [
            { value: 'Baja', label: 'Baja' },
            { value: 'Media', label: 'Media' },
            { value: 'Alta', label: 'Alta' },
          ],
          allowNull: true,
        },
        {
          label: 'Ahorro',
          value: clientData.savings || '',
          type: 'text',
          pattern: /^[0-9,.]{0,50}$/, // 50 dígitos numéricos
          formatPrice: true,
          allowNull: true,
        },
      ])
    }
  }

  const handleCancelEditing = () => {
    setEditing(false)
    setDatosFinancieros(auxDatosFinancieros)
    auxDatosFinancieros.forEach((item) => {
      const fieldName = item.label?.toLowerCase()
      setValue(fieldName, item.value)
      clearErrors(fieldName)
    })
  }

  useEffect(() => {
    if (editing) {
      // Se guardan en estado auxiliar los datos previos a editar
      setAuxDatosFinancieros(datosFinancieros)
      // Focus en el primer input para ayudar al usuario
      document.getElementsByTagName('input')[0].focus()
    }
  }, [editing])

  useEffect(() => {
    setClientData()
  }, [clientData])

  return (
    <React.Fragment>
      {Toaster}

      {loading && <UI.Loading />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
          {datosFinancieros.map((item, index) =>
            renderField(item, index, control, editing, errors, ' del cliente'),
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>{/* Espacio reservado para el botón */}</div>
          {editing && (
            <div className="ml-auto">
              <CButton
                type="button"
                color="danger"
                className="text-white px-4"
                onClick={() => handleCancelEditing()}
              >
                Cancelar
              </CButton>
              <CButton type="submit" color="success" className="text-white px-4 mx-4">
                Aceptar
              </CButton>
            </div>
          )}
        </div>
      </form>
      {!editing && (
        <CButton
          type="button"
          onClick={() => setEditing(true)}
          color="primary"
          className="text-white px-4 mx-4"
          style={{ alignSelf: 'end' }}
        >
          Editar
        </CButton>
      )}
    </React.Fragment>
  )
}

DatosFinancieros.propTypes = {
  clientData: PropTypes.object,
  id: PropTypes.string,
}

export default DatosFinancieros
