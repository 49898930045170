import React, { Fragment, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { cilChatBubble, cilSend } from '@coreui/icons'
import { CFormTextarea, COffcanvas, CSpinner } from '@coreui/react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { prism as style } from 'react-syntax-highlighter/dist/esm/styles/prism'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CIcon from '@coreui/icons-react'
import useAuth from 'src/hooks/useAuth'
import './styles.css'

const URL_CHAT_BOT = process.env.REACT_APP_URL_CHAT_BOT
const CHAT_BOT_API_KEY = process.env.REACT_APP_CHAT_BOT_API_KEY

const sendMessage = async (messages, user) => {
  if (!user) return
  const response = await fetch(`${URL_CHAT_BOT}/message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      api_key_header: CHAT_BOT_API_KEY,
    },
    body: JSON.stringify(messages.map(({ content, role }) => ({ content, role }))),
  })
  const stream = response.body.pipeThrough(new TextDecoderStream()).getReader()
  return stream
}

const Chat = () => {
  const { user } = useAuth()
  const [visible, setVisible] = useState(false)
  const [userInput, setUserInput] = useState('')

  const botMessage =
    process.env.REACT_APP_BOT_MESSAGE ||
    'Broky es un asistente de IA superinteligente que ayuda a todos los brokers en todo lo que pueda.'

  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: botMessage,
    },
  ])

  const [loading, setLoading] = useState(false)
  const [stop, setStop] = useState(false)

  const chatContentRef = useRef(null)

  useEffect(() => {
    if (chatContentRef.current) {
      const lastMessage = chatContentRef.current.lastChild
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [messages])

  useEffect(() => {
    if (stop) {
      setStop(false)
    }
  }, [stop])

  const clearString = (string = '') => {
    if (string.startsWith('"') && string.endsWith('"')) {
      string = string.replace(/^"|"$/g, '')
    }
    return string
  }

  const handleSendMessage = async () => {
    if (userInput === '' || !user) return
    const newMessage = { role: 'user', content: userInput }
    const newMessages = [...messages, newMessage]
    setStop(false)
    setMessages(newMessages)
    setUserInput('')
    setLoading(true)
    const stream = await sendMessage(newMessages, user)
    let response = ''
    while (true) {
      let { value, done } = await stream.read()
      if (value) {
        response += value
      }
      if (done || stop) break
    }
    response = clearString(response)
    setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: response }])
    setLoading(false)
  }

  const chatWindow = (
    <div className="position-relative">
      <div className="chat-header fs-5">
        Propital Bot
        <span className="close-button" onClick={() => setVisible(false)}>
          ✕
        </span>
      </div>
      <div className="chat-content" ref={chatContentRef}>
        {messages.map((message, index) => {
          const msg = `${message.content.replace(/\\n/g, '<br/>')}`
          return (
            <div key={index}>
              <div className={`${message.role}-message`}>
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code: ({ inline, children, language }) => {
                      const code = String(children)
                      return inline ? (
                        <pre style={{ display: 'inline' }}>{code}</pre>
                      ) : (
                        <SyntaxHighlighter
                          showLineNumbers={true}
                          style={style}
                          useInlineStyles={true}
                          language={language}
                        >
                          {code.replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      )
                    },
                  }}
                >
                  {msg}
                </Markdown>
              </div>
              <div className="clearfix"></div>
              {loading && index === messages.length - 1 && (
                <div className="d-flex flex-column w-full text-center py-1 mt-1">
                  <CSpinner color="primary" size="sm" className="mx-auto" />
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className="chat-controls">
        <CFormTextarea
          type="text"
          placeholder="Escribe un mensaje"
          style={{ paddingRight: '3rem', resize: 'none' }}
          value={userInput}
          disabled={loading}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              handleSendMessage()
            }
          }}
        />
        <CIcon
          onClick={() => handleSendMessage()}
          icon={cilSend}
          className="send-icon me-3"
          size="xl"
        />
      </div>
    </div>
  )

  const chatRoot = document.getElementById('chat-root')

  return createPortal(
    <Fragment>
      {!visible && (
        <div onClick={() => setVisible(!visible)} className="chat-button">
          <CIcon icon={cilChatBubble} size="xl" />
        </div>
      )}
      <COffcanvas
        backdrop={false}
        placement="end"
        scroll={true}
        visible={visible}
        onHide={() => setVisible(false)}
      >
        {chatWindow}
      </COffcanvas>
    </Fragment>,
    chatRoot,
  )
}

export default Chat
