import React, { useEffect, useState } from 'react'
import { getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import { createReservation, getUsersByBroker } from 'src/api'
import {
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CRow,
} from '@coreui/react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { UI } from 'src/components'
import ModalClient from '../Reservas/ModalClient'

const VCReservation = ({
  showModal,
  setShowModal,
  reservationInfo,
  selectedUnit,
  addToast,
  setPersonalData,
}) => {
  const userEmail = getUserEmail()

  const [loading, setLoading] = useState(true)

  const [stepReservation, setStepReservation] = useState(1)
  const [clientSelected, setClientSelected] = useState(null)
  const [allClients, setAllClients] = useState([])
  const [showModalClient, setShowModalClient] = useState(false)

  const handleSelectClient = (selectedItem) => {
    setClientSelected(selectedItem)
  }

  const handleHideModal = () => {
    setShowModal(false)
    setClientSelected(null)
  }

  const handleReservar = () => {
    const client = clientSelected.data

    const body = {
      governmentId: client.identity_document?.replaceAll('.', ''),
      projectId: reservationInfo?.projectId,
      reservationType: 'real_estate',
      clientEmail: client.email,
      clientName: client.first_name + ' ' + client.last_name,
      realEstateName: reservationInfo?.realEstateName,
      unitsList: [{ unitId: String(selectedUnit) }],
      createdBy: 'broker',
      currency: 'clp',
      pacMandateId: '',
      defaultAgent: '',
      brokerEmail: userEmail,
      linkPayment: '',
      sendMail: false,
    }

    setLoading(true)

    createReservation(body)
      .then((res) => {
        addToast({
          message: 'Pre reserva creada correctamente.',
          color: 'success',
        })
        handleHideModal()
        setLoading(false)
      })
      .catch((err) => {
        handlerSendErrorNotification(err)
        const error =
          err.response?.data?.detail?.error?.message || err.response?.data?.detail?.error
        addToast({
          message: error ?? 'Error al crear la pre reserva.',
          color: 'danger',
        })
        setLoading(false)
      })
  }

  const fetchClients = async () => {
    setLoading(true)
    try {
      const params = {
        email: userEmail,
        page_size: 'max',
        page_number: 1,
      }
      const response = await getUsersByBroker(params)
      const parsedClients = response?.data?.results.map((client) => {
        return {
          value: client.uuid,
          data: client,
          label: `${String(client.first_name).toUpperCase()} ${String(
            client.last_name,
          ).toUpperCase()} (${client.identity_document})`,
        }
      })
      setAllClients(parsedClients)
    } catch (error) {
      addToast({ message: 'Error al buscar los clientes', color: 'danger' })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchClients()
  }, [])

  return (
    <CModal
      backdrop="static"
      visible={showModal}
      onClose={() => handleHideModal()}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Pre reservar unidad</CModalTitle>
      </CModalHeader>
      {stepReservation === 1 ? (
        <CModalBody>
          <p>
            Seleccione qué cliente pre reservará esta unidad. Si el cliente no está registrado, por
            favor{' '}
            <span
              className="text-primary"
              onClick={() => setShowModalClient(true)}
              style={{ cursor: 'pointer' }}
            >
              regístrelo
            </span>
            .
          </p>
          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <Select
                placeholder="Clientes"
                options={allClients}
                menuPortalTarget={document.body}
                value={clientSelected}
                closeMenuOnSelect
                components={makeAnimated()}
                isLoading={loading}
                onChange={(selectedItem) => handleSelectClient(selectedItem)}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </CCol>
          </CRow>
        </CModalBody>
      ) : (
        <CModalBody className="d-flex flex-column">
          <p>¿Desea confirmar la pre reserva de las siguiente unidad</p>
          <ul className="d-flex flex-column">
            <li className="text-primary fw-semibold mb-1">{selectedUnit}</li>
          </ul>
          <p>
            Para el cliente{' '}
            <span className="text-primary fw-semibold">{clientSelected?.label}</span>.
          </p>
          <p className="mb-0 mt-2 small">
            <span className="fw-semibold">El cliente tendrá 24hs para realizar el pago</span>
          </p>
          <p className="mb-0 mt-2 small">
            <span className="fw-semibold">Nota:</span> Si el cliente va a pre reservar mas de dos
            unidades por primera vez recomendamos validar cuenta o hcer dos links de transferencia
            por dia
          </p>
          {loading && <UI.Loading />}
        </CModalBody>
      )}
      <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          <CButton
            onClick={() => {
              stepReservation === 1 ? setStepReservation(2) : handleReservar()
            }}
            disabled={!clientSelected || loading}
            color="primary"
            className="text-white rounded-5"
            size="sm"
          >
            {stepReservation === 1 ? 'Pre reservar' : 'Confirmar pre reserva'}
          </CButton>
        </div>
      </CModalFooter>
      {showModalClient && (
        <ModalClient
          showModalClient={showModalClient}
          setShowModalClient={setShowModalClient}
          setPersonalData={setPersonalData}
          addToast={addToast}
        />
      )}
    </CModal>
  )
}

VCReservation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  reservationInfo: PropTypes.object.isRequired,
  selectedUnit: PropTypes.string.isRequired,
  addToast: PropTypes.func.isRequired,
  setPersonalData: PropTypes.func,
}

export default VCReservation
