import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'

const DatosProyecto = ({ projectData, setProjectData, setShowFinalAlert }) => {
  const datosProyecto = [
    {
      label: 'Nombre del proyecto',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,150}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Nombre del proyecto inválido',
    },
    {
      label: 'Cantidad de inmuebles',
      value: '',
      type: 'number',
      pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
      errorMessage: 'Cantidad de inmuebles inválida',
    },
    {
      label: 'Numero de cada unidad y torre',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,50}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Numero de cada unidad y torre inválida',
    },
    {
      label: 'Ubicación del proyecto',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,150}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Ubicación del proyecto inválida',
    },
    {
      label: 'Monto total en UF (incluyendo bono pie)',
      value: '',
      type: 'text',
      pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
      formatPrice: true,
      errorMessage: 'Monto total en UF inválido',
    },
    {
      label: 'Financiamiento por dpto (%)',
      value: '',
      type: 'number',
      pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
      errorMessage: 'Financiamiento por dpto (%) inválido',
    },
    {
      label: 'Pie por dpto (sin considerar bono pie) en UF o CLP',
      value: '',
      type: 'text',
      pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
      formatPrice: true,
      errorMessage: 'Pie por dpto. inválido',
    },
    {
      label: '% Bono pie',
      value: '',
      type: 'number',
      pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
      errorMessage: '% Bono pie inválido',
    },
    {
      label: 'Fecha de escritura (o tentativa)',
      value: '',
      type: 'date',
      errorMessage: 'Fecha de escritura (o tentativa) inválida',
      legalAge: true,
    },
  ]

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: projectData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    reset, // Agrega reset aquí
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()

      setProjectData(formValues)

      // Muestra la alerta final antes de crear el cliente
      setShowFinalAlert(true)
    }
  }

  useEffect(() => {
    reset(projectData)
  }, [projectData, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {datosProyecto.map((item, index) =>
          renderField(item, index, control, true, errors, ' del cliente'),
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton type="submit" color="success" className="text-white px-4 mx-4">
            Agregar cliente
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosProyecto.propTypes = {
  projectData: PropTypes.object.isRequired,
  setProjectData: PropTypes.func.isRequired,
  setShowFinalAlert: PropTypes.func.isRequired,
}

export default DatosProyecto
