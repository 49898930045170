import React, { Fragment, useState } from 'react'
import {
  CButton,
  CCol,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { getPerfilFinanciero } from 'src/api'
import { inputs, initialMaxPrice } from './constants'
import { formatRoundUF } from 'src/helpers'

const ModalCalculate = ({ visible, setVisible, filters, setFilters, searchData }) => {
  const [response, setResponse] = useState({})

  const defaultValues = {
    ingreso_liquido: 1000000,
    ac: 30,
    financiamiento: 80,
    valor_hip: 0,
    valor_con: 0,
    interes_anual: 5,
    valor_uf: 36190,
  }

  const { register, watch } = useForm({
    defaultValues,
  })

  const { ingreso_liquido, ac, financiamiento, valor_hip, valor_con, interes_anual, valor_uf } =
    watch()

  const fetchValues = async () => {
    const params = `ia_catalogo?ingreso_liquido=${ingreso_liquido}&ac=${ac}&financiamiento=${financiamiento}&valor_hip=${valor_hip}&valor_con=${valor_con}&interes_anual=${interes_anual}&valor_uf=${valor_uf}`
    try {
      const { data } = await getPerfilFinanciero(params)
      setFilters({
        ...filters,
        venta_max: data.maxuf,
      })
      return setResponse(data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    setFilters({
      ...filters,
      venta_max: initialMaxPrice,
    })
    setVisible(false)
  }

  return (
    <CModal
      backdrop="static"
      visible={visible}
      onClose={() => setVisible(false)}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Buscar por Perfil Financiero</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>
          Complete el formulario para obtener el valor máximo aproximado de compra a través del
          perfíl financiero.
        </p>
        <CRow>
          {inputs.map((input, index) => (
            <CCol key={index} className="mb-2" md={input.size}>
              <p className="m-0 mb-1 text-truncate text-primary" title={input.text}>
                {input.text}
              </p>
              <CFormInput
                type="number"
                name={input.key}
                onWheel={(e) => e.target.blur()}
                {...register(input.key)}
              />
            </CCol>
          ))}
        </CRow>
      </CModalBody>
      <CModalFooter className="w-100 pt-0" style={{ borderTop: 'none' }}>
        <div className="d-flex flex-column mx-auto w-75">
          {response?.maxuf && (
            <Fragment>
              <p className="m-0 text-muted text-center">
                El tope máximo es de
                <span className="text-primary fw-semibold"> {formatRoundUF(response?.maxuf)} </span>
                UF
              </p>
              <CButton
                onClick={() => {
                  searchData()
                  setVisible(false)
                }}
                color="info"
                className="text-white rounded-5 my-2"
                size="sm"
              >
                Aplicar en Filtros
              </CButton>
            </Fragment>
          )}
          <div className="d-flex flex-row gap-2">
            <CButton
              variant="outline"
              onClick={() => handleCancel()}
              className="rounded-5 w-100"
              color="info"
              size="sm"
            >
              Cancelar
            </CButton>
            <CButton
              onClick={() => fetchValues()}
              className="text-white rounded-5 w-100"
              color="info"
              size="sm"
            >
              {!response?.maxuf ? 'Calcular' : 'Volver a Calcular'}
            </CButton>
          </div>
        </div>
      </CModalFooter>
    </CModal>
  )
}

ModalCalculate.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  searchData: PropTypes.func,
}

export default ModalCalculate
