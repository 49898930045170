import React from 'react'
import { CNavGroup, CNavItem } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import * as icon from '@coreui/icons'

let investmentItems = [
  {
    component: CNavItem,
    name: 'Catálogo',
    to: '/catalogo',
    icon: <CIcon icon={icon.cilViewModule} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Listado',
    to: '/listado',
    icon: <CIcon icon={icon.cilViewStream} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Oportunidades',
    to: '/oportunidades',
    icon: <CIcon icon={icon.cilStar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Cesiones',
    to: '/cesiones',
    icon: <CIcon icon={icon.cilNewspaper} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Mapa Proyectos',
    to: '/mapa',
    icon: <CIcon icon={icon.cilMap} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Pre Reservas',
    to: '/reservas',
    icon: <CIcon icon={icon.cilBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Comisiones',
    to: '/comisiones',
    icon: <CIcon icon={icon.cilDollar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Simulador',
    to: '/simulador',
    icon: <CIcon icon={icon.cilCalculator} customClassName="nav-icon" />,
  },
]

let clientsItems = [
  {
    component: CNavItem,
    name: 'Cartera',
    to: '/clientes',
    icon: <CIcon icon={icon.cilAddressBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Agregar',
    to: '/agregar-cliente',
    icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
  },
]

let brokerageItems = [
  {
    component: CNavItem,
    name: 'Seminuevos',
    to: '/seminuevos',
    icon: <CIcon icon={icon.cilViewModule} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Venta privada',
    to: '/venta-privada',
    icon: <CIcon icon={icon.cilMoney} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Oportunidades',
    to: '/brokerage',
    icon: <CIcon icon={icon.cilStar} customClassName="nav-icon" />,
  },
]

let operationItems = [
  {
    component: CNavItem,
    name: 'Buscador clientes',
    to: '/lista-clientes',
    icon: <CIcon icon={icon.cilSearch} customClassName="nav-icon" />,
  },
]

let headhuntingItems = [
  {
    component: CNavItem,
    name: 'Crear candidato',
    to: '/headhunting/crear',
    icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Listado',
    to: '/headhunting/listado',
    icon: <CIcon icon={icon.cilViewStream} customClassName="nav-icon" />,
  },
]

let _nav = [
  {
    component: CNavItem,
    name: 'Inicio',
    to: '/',
    icon: <CIcon icon={icon.cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Clientes',
    key: 'customers',
    icon: <CIcon icon={icon.cilGroup} customClassName="nav-icon" />,
    items: clientsItems,
  },
  {
    component: CNavGroup,
    name: 'Operaciones',
    key: 'operations',
    icon: <CIcon icon={icon.cilTransfer} customClassName="nav-icon" />,
    items: operationItems,
  },
  {
    component: CNavGroup,
    name: 'Investment',
    key: 'investment',
    icon: <CIcon icon={icon.cilGraph} customClassName="nav-icon" />,
    items: investmentItems,
  },
  {
    component: CNavItem,
    name: 'Terrenos',
    key: 'land',
    to: '/terrenos',
    icon: <CIcon icon={icon.cibGumtree} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Rent',
    key: 'rent',
    icon: <CIcon icon={icon.cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Template',
        to: '/template',
        icon: <CIcon icon={icon.cilPlus} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Corretaje',
    key: 'brokerage',
    icon: <CIcon icon={icon.cilFolderOpen} customClassName="nav-icon" />,
    items: brokerageItems,
  },
  {
    component: CNavItem,
    name: 'EEUU',
    key: 'eeuu',
    to: '/eeuu',
    icon: <CIcon icon={icon.cilHouse} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Legal Tributario',
    key: 'taxlegal',
    to: '/formulario-impuestos',
    icon: <CIcon icon={icon.cilFolderOpen} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Academy',
    key: 'wiki',
    to: '/wiki',
    icon: <CIcon icon={icon.cilInfo} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Headhunting',
    key: 'headhunting',
    icon: <CIcon icon={icon.cilFolderOpen} customClassName="nav-icon" />,
    items: headhuntingItems,
  },
]

export default _nav
