import React from 'react'
import { formatInputPrice } from 'src/helpers'
import PropTypes from 'prop-types'

export default function CompleteReservationDetail({ completeReservation }) {
  return (
    <div className="mb-4 d-flex flex-column gap-2">
      <h4 className="mb-4">Monto restante</h4>
      <div className="d-flex gap-2">
        <p className="m-0 mb-1 fw-semibold">Link de pago: </p>
        <a className="m-0" href={completeReservation.link_payment}>
          payment link
        </a>
      </div>
      <div className="d-flex gap-2">
        <p className="m-0 mb-1 fw-semibold">Estado: </p>
        <p className="m-0">{completeReservation.reservation_state}</p>
      </div>
      <div className="d-flex gap-2">
        <p className="m-0 mb-1 fw-semibold">Fecha: </p>
        <p className="m-0">{completeReservation.created_at}</p>
      </div>
      <h5 className="mt-2">
        Monto: <span className="text-primary">${formatInputPrice(completeReservation.amount)}</span>
      </h5>
    </div>
  )
}

CompleteReservationDetail.propTypes = {
  completeReservation: PropTypes.object.isRequired,
}
