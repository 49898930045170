import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getHomePanels } from 'src/api'
import {
  CCol,
  CPlaceholder,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'

const Project = ({ loading }) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const { data } = await getHomePanels()
      setData(data.result)
    } catch (error) {
      console.error('Error al obtener los datos de la API:', error)
    }
  }

  const RenderTables = () => {
    return data.map((section, index) => (
      <CCol key={index} lg="6" md="12" sm="12" className="mb-4">
        <h5>{section.subtitulo}</h5>
        <h6 className="text-primary">{section.titulo}</h6>
        <CTable align="middle" className="mb-0 border" hover responsive>
          <CTableHead color="light">
            <CTableRow>
              <CTableHeaderCell>Proyecto</CTableHeaderCell>
              <CTableHeaderCell>Inmobiliaria</CTableHeaderCell>
              <CTableHeaderCell>Valor</CTableHeaderCell>
              <CTableHeaderCell>Bono pie</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {section.lista.map((item, idx) => (
              <CTableRow key={idx}>
                <CTableDataCell>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      width: '18rem',
                      display: 'block',
                      overflow: 'auto',
                      height: '1.8rem',
                      cursor: 'pointer', // Agregar cursor: pointer para indicar que es clickable
                    }}
                    onClick={() => navigate(`/catalogo/vista-comercial/${item.idproyecto}`)} // Redireccionar al hacer clic
                  >
                    {item.col_a}
                  </span>
                </CTableDataCell>

                <CTableDataCell>{item.col_b}</CTableDataCell>
                <CTableDataCell className="align-right">
                  {item.col_c} {item.moneda || ''}
                </CTableDataCell>
                <CTableDataCell style={{ width: '2rem' }}>
                  {item.col_d !== 'NO' ? 'SI' : 'NO'}
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </CCol>
    ))
  }

  return !loading ? (
    <div className="row">{RenderTables()}</div>
  ) : (
    <CPlaceholder animation="glow">
      <CPlaceholder xs={12} />
    </CPlaceholder>
  )
}

Project.propTypes = {
  loading: PropTypes.bool,
}

export default Project
