// Project Filters
export const initialMinFee = 1
export const initialMaxFee = 36
export const initialMinPrice = 1
export const initialMaxPrice = 1000000

export const filtersInitialValues = {
  buscar: '',
  regiones: [],
  comunas: [],
  inmobiliarias: [],
  banco: [],
  tipoentrega: [],
  ordenar: '',
  bonopie: '',
  cuotas_min: initialMinFee,
  cuotas_max: initialMaxFee,
  venta_min: initialMinPrice,
  venta_max: initialMaxPrice,
}

export const selectInitialValues = [
  { title: 'regiones', options: [] },
  { title: 'comunas', options: [] },
  { title: 'inmobiliarias', options: [] },
  { title: 'banco', options: [] },
  { title: 'tipoentrega', options: [] },
  { title: 'ordenar', options: [] },
  { title: 'bonopie', options: [] },
]

export const chipsInitialValues = [
  { label: 'Buy Depa', field: 'pro_type_id', value: false },
  { label: 'TOP', field: 'pro_oportunity', value: false },
]

// Modal Filters
export const showSelects = [
  'regiones',
  'comunas',
  'inmobiliarias',
  'tipoentrega',
  'ordenar',
  'bonopie',
]

export const rowSelects = ['tipoentrega', 'bonopie']

export const isMultiSelect = {
  regiones: true,
  comunas: true,
  inmobiliarias: true,
  tipoentrega: true,
}

export const titleValues = {
  tipoentrega: 'Tipo de Entrega',
  ordenar: 'Ordenar por',
  bonopie: 'Bono pie',
}

// Modal Calculate
export const inputs = [
  {
    key: 'ingreso_liquido',
    text: 'Ingreso Líquido',
    size: '12',
  },
  {
    key: 'ac',
    text: 'Años Crédito',
    size: '4',
  },
  {
    key: 'financiamiento',
    text: 'Financiamiento (%)',
    size: '4',
  },
  {
    key: 'valor_hip',
    text: 'Hipotecario Mensual',
    size: '4',
  },
  {
    key: 'valor_con',
    text: 'Consumo Mensual',
    size: '4',
  },
  {
    key: 'interes_anual',
    text: 'Interés Anual (%)',
    size: '4',
  },
  {
    key: 'valor_uf',
    text: 'Valor UF',
    size: '4',
  },
]
