import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { registerClient } from 'src/api'
import { renderField, getUserEmail, handlerSendErrorNotification } from 'src/helpers'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol,
} from '@coreui/react'
import './styles.scss'
import { UI } from 'src/components'

// Se utiliza también en /components/VistaComercial/Reservation.js

const ModalClient = ({ setPersonalData, showModalClient, setShowModalClient, addToast }) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)

  const datosPersonales = [
    {
      label: 'Nombre',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/,
      errorMessage: 'Nombre inválido',
      size: 'calc(50% - 0.5rem)',
    },
    {
      label: 'Apellido',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/,
      errorMessage: 'Apellido inválido',
      size: 'calc(50% - 0.5rem)',
    },
    {
      label: 'RUT',
      value: '',
      type: 'text',
      errorMessage: 'RUT inválido (formato XX.XXX.XXX-X)',
      size: 'calc(50% - 0.5rem)',
    },
    {
      label: 'Email',
      value: '',
      type: 'text',
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: 'Email inválido',
      size: 'calc(50% - 0.5rem)',
    },
    {
      label: 'Telefono',
      value: '',
      type: 'phone',
      errorMessage: 'Debe ingresar un número telefónico válido (EJ: +XX XXX XXX XXX)',
      size: 'calc(50% - 0.5rem)',
    },
  ]

  const formOptions = {
    mode: 'onBlur',
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm(formOptions)

  React.useEffect(() => {
    setIsButtonDisabled(!isValid)
  }, [isValid])

  const handleCreateClient = () => {
    setIsLoading(true)
    const userEmail = getUserEmail()
    const formData = getValues()

    // Limpiar y formatear el RUT
    const cleanedRut = formData.rut?.replaceAll('.', '').replaceAll('-', '').replaceAll(',', '')
    const finalRut =
      cleanedRut.slice(0, cleanedRut.length - 1) +
      '-' +
      cleanedRut.slice(cleanedRut.length - 1, cleanedRut.length)

    const phonePlus =
      formData.phone && !formData.phone.includes('+')
        ? '+' + formData.phone
        : formData.phone || '+569999999'

    // Construir la estructura de datos con campos específicos
    const requestData = {
      user_data: {
        age: 18,
        address: 'none',
        avatar: null,
        birth_date: '1999-01-01',
        city: 'none',
        create_date: new Date(),
        creation_source: 'Backoffice',
        email: formData.email,
        first_name: formData.nombre,
        identity_document: finalRut,
        last_login_date: null,
        last_name: formData.apellido,
        legal_entity: null,
        marital_status: 'Soltero',
        phone: phonePlus,
        profession: 'none',
        street_number: '123',
        update_date: new Date(),
      },
      investment_data: {
        employment_place: null,
        contract_type: null,
        seniority: null,
        bank: null,
        account_type: null,
        net_income: null,
        additional_income: null,
        savings_capacity: null,
        savings: null,
        mortgage_loan: null,
        mortgage_payment_year: null,
        consumer_loan: null,
        consumer_payment_year: null,
        interest_area: null,
        where_you_invest_today: null,
      },
    }

    registerClient(requestData, userEmail, 'investment')
      .then((res) => {
        addToast({ message: 'Cliente registrado con éxito.', color: 'success' })
        setIsLoading(false)
        handleHideModal()
      })
      .catch((err) => {
        console.log(err)
        handlerSendErrorNotification(err)
        addToast({
          message: err?.response?.data?.detail || 'Error al agregar cliente.',
          color: 'danger',
        })
        setIsLoading(false)
      })
  }

  const onSubmit = () => {
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      const formData = getValues()

      const cleanedRut = formData.rut ? formData.rut.replace(/[.-]/g, '') : ''

      if (cleanedRut.length > 1) {
        const finalRut = `${cleanedRut.slice(0, -1)}-${cleanedRut.slice(-1)}`

        const formattedData = {
          user_data: {
            first_name: formData.nombre,
            last_name: formData.apellido,
            identity_document: finalRut,
            phone: formData.telefono,
            email: formData.email,
          },
        }
        setPersonalData(formattedData)
        handleCreateClient()
      }
    }
  }

  const handleHideModal = () => {
    setShowModalClient(false)
  }

  return (
    <CModal
      backdrop="static"
      visible={showModalClient}
      onClose={() => handleHideModal()}
      alignment="center"
    >
      <CModalHeader className="pb-0" style={{ borderBottom: 'none' }}>
        <CModalTitle>Agregar Cliente</CModalTitle>
      </CModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CModalBody className="d-flex flex-column">
          <CRow className="my-2">
            <CCol className="mb-2 mb-lg-0">
              <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
                {datosPersonales.map((item, index) =>
                  renderField(item, index, control, true, errors, ' del cliente'),
                )}
              </div>
            </CCol>
          </CRow>
          <p className="my-0 small">
            <span className="fw-semibold">Nota:</span> Para continuar con el proceso de venta luego
            de la pre-reserva será necesario que complete el perfil del cliente.
          </p>
        </CModalBody>
        <CModalFooter className="w-100" style={{ borderTop: 'none' }}>
          <div className="d-flex flex-column mx-auto w-75">
            <CButton
              onClick={() => handleCreateClient()}
              color="primary"
              type="button"
              className="text-white rounded-5"
              size="sm"
              disabled={isButtonDisabled || isLoading}
            >
              Agregar cliente
              {isLoading && <UI.Loading />}{' '}
            </CButton>
          </div>
        </CModalFooter>
      </form>
    </CModal>
  )
}

ModalClient.propTypes = {
  showModalClient: PropTypes.bool.isRequired,
  setShowModalClient: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  setPersonalData: PropTypes.func,
}

export default ModalClient
