import React, { useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CRow } from '@coreui/react'
import PropTypes from 'prop-types'
import ModalReservation from './ModalReservation'
import ModalClient from './ModalClient'
import { SearchBar } from 'src/components/_UI'
import { useForm } from 'react-hook-form'
import useToast from 'src/hooks/useToast'

const Header = ({ setFilters, setReservationCreated, setPersonalData }) => {
  const { handleSubmit, control, setValue, getValues } = useForm()

  const { Toaster, addToast } = useToast()

  const [showModal, setShowModal] = useState(false)
  const [showModalClient, setShowModalClient] = useState(false)

  const handleSetFilters = (data) => {
    if (!data.searchParam) {
      setValue('searchParam', '')
    }
    if (!data.reservationState) {
      setValue('reservationState', '')
    }
    setFilters(data)
  }

  return (
    <CCard className="mb-2">
      {Toaster}
      <CCardBody>
        <CRow className="my-2">
          <CCol>
            <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
              <div className="d-lg-block">
                <h4 className="card-title mb-0">Pre Reservas</h4>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow className="my-2 d-flex justify-content-between align-items-center">
          <p className="small text-medium-emphasis m-0">
            Consulte la información de todas las pre reservas realizadas por los clientes y brokers.
          </p>
          <CCol xs="12" sm="8" md="8" lg="8" className="mb-lg-0 d-flex gap-2 mb-2">
            <SearchBar
              handleSubmit={handleSubmit}
              control={control}
              getValues={getValues}
              handleSetFilters={handleSetFilters}
              label="Busca por nombre/RUT de cliente o ID de reserva"
            />
          </CCol>
          <CCol xs="12" sm="6" md="4" lg="4" className="mb-lg-0 d-flex flex-column align-items-end">
            <CButton
              onClick={() => setShowModalClient(true)}
              className="text-white mb-2"
              style={{ width: '9.8rem' }}
            >
              Agregar cliente
            </CButton>
            <CButton onClick={() => setShowModal(true)} className="text-white">
              Nueva pre reserva
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>

      {showModal && (
        <ModalReservation
          showModal={showModal}
          setShowModal={setShowModal}
          setReservationCreated={setReservationCreated}
          addToast={addToast}
        />
      )}
      {showModalClient && (
        <ModalClient
          showModalClient={showModalClient}
          setShowModalClient={setShowModalClient}
          setPersonalData={setPersonalData}
          addToast={addToast}
        />
      )}
    </CCard>
  )
}

Header.propTypes = {
  setFilters: PropTypes.func.isRequired,
  setReservationCreated: PropTypes.func.isRequired,
  setPersonalData: PropTypes.func,
}

export default Header
