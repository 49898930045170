import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
  CButton,
} from '@coreui/react'
import PropTypes from 'prop-types'
import React from 'react'

const ConfirmPopup = ({
  title,
  message,
  confirmText = null,
  confirmColor = null,
  cancelText = null,
  cancelColor = null,
  visible,
  onClose,
  isLoading,
  finalAction,
}) => {
  return (
    <CModal visible={visible} alignment="center" onClose={onClose}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>

      <CModalBody className={isLoading ? 'd-flex justify-content-center align-items-center' : ''}>
        {isLoading ? (
          <CSpinner color="primary" alignment="center" style={{ margin: '1rem auto' }} />
        ) : (
          <p>{message}</p>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={isLoading}
          color={cancelColor || 'secondary'}
          className="text-white"
          onClick={onClose}
        >
          {cancelText || 'Cancelar'}
        </CButton>
        <CButton
          disabled={isLoading}
          color={confirmColor || 'primary'}
          className="text-white"
          onClick={finalAction}
        >
          {confirmText || 'Confirmar'}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

ConfirmPopup.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  confirmColor: PropTypes.string,
  cancelText: PropTypes.string,
  cancelColor: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  finalAction: PropTypes.func,
}

export default ConfirmPopup
