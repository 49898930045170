import React from 'react'
import { CForm, CFormInput, CButton } from '@coreui/react'
import { Controller } from 'react-hook-form'
import { UI } from 'src/components'
import PropTypes from 'prop-types'

const ClientsSearch = ({ setSearchTerm, handleSubmit, control }) => {
  const onSubmit = (data) => {
    setSearchTerm(data.searchTerm)
  }

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="searchTerm"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <div className="d-flex align-items-center">
            <CFormInput
              size="sm"
              placeholder="Busca el cliente que necesites"
              {...field}
              onChange={(e) => {
                field.onChange(e)
              }}
            />
            <CButton
              size="sm"
              color="primary"
              type="submit"
              disabled={!field.value}
              className="btn btn-primary btn-sm px-3 text-white rounded-5"
              style={{ whiteSpace: 'nowrap', marginLeft: '1rem' }}
            >
              Buscar
            </CButton>
            <div style={{ minWidth: '30px', maxWidth: '30px', marginLeft: '.5rem' }}>
              <UI.CircleButton
                type="button"
                onClick={() => {
                  setSearchTerm('')
                }}
                icon="pajamas:clear-all"
              />
            </div>
          </div>
        )}
      />
    </CForm>
  )
}

ClientsSearch.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
}

export default ClientsSearch
