import React from 'react'
import PropTypes from 'prop-types'

const Indicators = ({
  min,
  max,
  minleftPrefix = '',
  minRightPrefix = '',
  maxLeftPrefix = '',
  maxRightPrefix = '',
}) => {
  return (
    <div
      className="d-flex flex-row justify-content-between text-muted fs-8 mx-3"
      style={{ marginTop: '-10px' }}
    >
      <div className="text-center">
        <p className="m-0 fw-semibold">Mínimo</p>
        <p className="m-0">
          {minleftPrefix}
          {min}
          {minRightPrefix}
        </p>
      </div>
      <div className="text-center">
        <p className="m-0 fw-semibold">Máximo</p>
        <p className="m-0">
          {maxLeftPrefix}
          {max}
          {maxRightPrefix}
        </p>
      </div>
    </div>
  )
}

Indicators.propTypes = {
  min: PropTypes.any.isRequired,
  max: PropTypes.any.isRequired,
  minleftPrefix: PropTypes.string,
  minRightPrefix: PropTypes.string,
  maxLeftPrefix: PropTypes.string,
  maxRightPrefix: PropTypes.string,
}

export default Indicators
