import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './styles.css'
import { markers } from './constants'
import { getVistaComercial } from 'src/api'
import { CSpinner } from '@coreui/react'
import { useNavigate } from 'react-router-dom'

export const handleIcons = (type) => {
  const icons = {
    marker_black: 'marker_black',
    marker_salmon: 'marker_salmon',
    marker_light_green: 'marker_light_green',
    marker_dark_blue: 'marker_dark_blue',
    marker_green: 'marker_green',
    marker_dark_red: 'marker_dark_red',
    marker_purple: 'marker_purple',
    marker_yellow: 'marker_yellow',
    marker_cyan: 'marker_cyan',
    marker_dark_orange: 'marker_dark_orange',
    marker_orange: 'marker_orange',
    marker_dark_green: 'marker_dark_green',
    marker_blue: 'marker_blue',
    marker_brown: 'marker_brown',
    marker_mulberry: 'marker_mulberry',
    marker_dark_purple: 'marker_dark_purple',
  }
  const icon = L.divIcon({
    className: icons[type] ? icons[type] : icons.default,
    iconSize: [30, 30],
    iconAnchor: [0, 0],
    popupAnchor: [15, 0],
  })
  return icon
}

// El TileLayer es el mapa que se muestra en el fondo
const tileLayerUrl = 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token='

// El token es necesario para poder usar el mapa
// TODO: Propital debería crear una cuenta en Jawg y obtener su propio token para ponerlo en el .env
const JAWG_TOKEN = 'HhYVLFhdkAWpWSPkHI18lN7RcETs22GMa0DLMx5QOsYyDkxY00sj6Ahx5aBMzySf'

const Map = ({ height = '600px', scrollWheelZoom = true }) => {
  const [id, setId] = useState(null)
  const [project, setProject] = useState({})
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const fetchProject = async (id) => {
    setLoading(true)
    try {
      const response = await (await getVistaComercial(id)).data.vistacomercial
      setProject(response)
      return setLoading(false)
    } catch (error) {
      return console.error(error)
    }
  }

  useEffect(() => {
    if (!id) return
    fetchProject(id)
  }, [id])

  return (
    <MapContainer
      style={{
        height,
        width: '100%',
        borderRadius: '8px',
      }}
      zoom={12.5}
      center={[-33.4489, -70.6693]}
      scrollWheelZoom={scrollWheelZoom}
    >
      <TileLayer url={`${tileLayerUrl}${JAWG_TOKEN}`} />
      {markers.map((item, index) => {
        return (
          <Marker
            eventHandlers={{
              click: () => {
                setId(item.id ? item.id : null)
              },
            }}
            key={index}
            icon={handleIcons(item.type)}
            position={[item.lat, item.lng]}
          >
            <Popup>
              {loading ? (
                <div className="d-flex flex-column">
                  <CSpinner color="primary" className="mx-auto" />
                </div>
              ) : (
                <Fragment>
                  {project && (
                    <div className="d-flex flex-column gap-2 text-center">
                      <img
                        className="rounded-2"
                        src={project.image_cover}
                        style={{ height: '90px', width: '100%', objectFit: 'cover' }}
                        alt="Imagen"
                      />
                      <p className="m-0">
                        <strong>Inmobiliaria:</strong>{' '}
                        {project.supplier_name.charAt(0).toUpperCase() +
                          project.supplier_name.slice(1).toLowerCase()}
                      </p>
                      <p className="m-0">
                        <strong>Proyecto:</strong>{' '}
                        {project.nombre_proyecto.charAt(0).toUpperCase() +
                          project.nombre_proyecto.slice(1).toLowerCase()}
                      </p>

                      <div className="link-container">
                        <p
                          className="text-primary text-center fw-bold"
                          style={{ cursor: 'pointer' }}
                          onClick={() => navigate(`/catalogo/vista-comercial/${id}`)}
                        >
                          Vista Comercial
                        </p>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Popup>
          </Marker>
        )
      })}
    </MapContainer>
  )
}

Map.propTypes = {
  height: PropTypes.string,
  scrollWheelZoom: PropTypes.bool,
}

export default Map
