import React from 'react'
import { CCard, CCardBody, CCol, CTooltip } from '@coreui/react'
import { UI } from 'src/components'
import PropTypes from 'prop-types'

const HeaderRegister = ({ step, handleGoBack }) => {
  return (
    <CCard className="mb-2">
      <CCardBody>
        <CCol>
          <div className="d-flex flex-row align-items-center gap-2 flex-wrap flex-lg-nowrap">
            {step !== 1 && (
              <CTooltip content="Volver a seleccionar el tipo de cliente">
                <div>
                  <UI.CircleButton
                    onClick={handleGoBack}
                    buttonClassName="me-2"
                    icon="icon-park-outline:return"
                    height="36px"
                    width="36px"
                  />
                </div>
              </CTooltip>
            )}
            <div className="d-lg-block">
              <h4 className="card-title mb-0">Agregar cliente</h4>
              <p className="small text-medium-emphasis m-0">Complete los datos de la persona</p>
            </div>
          </div>
        </CCol>
      </CCardBody>
    </CCard>
  )
}

HeaderRegister.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
}

export default HeaderRegister
