import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCol,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import useToast from 'src/hooks/useToast'
import * as DatosRegister from './DatosRegister'
import * as icon from '@coreui/icons'
import { createFormatTaxClient, handlerSendErrorNotification } from 'src/helpers'
import { getLegal } from 'src/api'

// Estilos de la card general
const cardStyle = {
  borderTopRightRadius: '0px',
  borderTopLeftRadius: '0px',
  borderTop: 'none',
}

const Register = () => {
  const { Toaster, addToast } = useToast()

  const [activeTab, setActiveTab] = useState(0)
  const [availableTabs, setAvailableTabs] = useState(0)
  const [showFinalAlert, setShowFinalAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Estados con los valores de los formularios
  const [personalData, setPersonalData] = useState({})

  // Tabs con los componentes que cada una debe mostrar
  const tabs = [
    {
      title: 'Datos usuario',
      icon: icon.cilUser,
      component: (
        <DatosRegister.DatosPersonales
          personalData={personalData}
          setPersonalData={setPersonalData}
          setActiveTab={setActiveTab}
          setShowFinalAlert={setShowFinalAlert}
        />
      ),
    },
  ]

  // Si la tab se completo, se permite viajar entre ellas
  const handleTabChange = (tabIndex) => {
    if (tabIndex <= availableTabs) {
      setActiveTab(tabIndex)
    }
  }

  // Si la tab se completo, se setea el estado "availableTabs" con el indice de la tab completa (para permitir el habdleTabChange)
  const handleSetAvailableTabs = () => {
    if (activeTab > availableTabs) {
      setAvailableTabs(activeTab)
    }
  }

  // Armado de body y llamado al servicio de creacion de cliente
  const handleCreateClient = () => {
    setIsLoading(true)
    console.log('Datos del formulario:', personalData)

    const formattedData = createFormatTaxClient(personalData) // Llama a createFormatTaxClient con los datos
    getLegal(formattedData)
      .then((res) => {
        addToast({
          message:
            'Muchas gracias por tu solicitud en breve el equipo de Legal Tributario te contactará',
          color: 'success',
        })
        setIsLoading(false)
        setShowFinalAlert(false)
      })
      .catch((err) => {
        handlerSendErrorNotification(err)
        addToast({
          message: 'Error al enviar la solicitud.',
          color: 'danger',
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    handleSetAvailableTabs()
  }, [activeTab])

  return (
    <CCard className="mb-2">
      {Toaster}

      <CCardBody>
        <CCol>
          {/* Pestañas */}
          <ul className="nav nav-tabs">
            {tabs.map((item, index) => {
              const isActive = activeTab === index ? 'active text-primary' : 'text-muted'
              return (
                <li key={item.title} className="nav-item">
                  <h3
                    className={`nav-link ${isActive}`}
                    onClick={() => handleTabChange(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <span>
                      <CIcon icon={item.icon} className={`mx-1 ${isActive}`} />
                    </span>
                    {item.title}
                  </h3>
                </li>
              )
            })}
          </ul>

          {/* Pestaña Activa */}
          <CCard className="mb-2" style={cardStyle}>
            <CCardBody>{tabs[activeTab].component}</CCardBody>
          </CCard>

          {/* Confirmacion de creacion de cliente */}
          <CModal
            visible={showFinalAlert}
            alignment="center"
            onClose={() => setShowFinalAlert(false)}
          >
            <CModalHeader>
              <CModalTitle>Confirmar los datos</CModalTitle>
            </CModalHeader>

            <CModalBody
              className={isLoading ? 'd-flex justify-content-center align-items-center' : ''}
            >
              {isLoading ? (
                <CSpinner color="primary" alignment="center" style={{ margin: '1rem auto' }} />
              ) : (
                <p>¿Está seguro que los datos ingresados son correctos?</p>
              )}
            </CModalBody>
            <CModalFooter>
              <CButton
                disabled={isLoading}
                color="secondary"
                className="text-white"
                onClick={() => setShowFinalAlert(false)}
              >
                Cancelar
              </CButton>
              <CButton
                disabled={isLoading}
                color="primary"
                className="text-white"
                onClick={() => handleCreateClient()}
              >
                Confirmar
              </CButton>
            </CModalFooter>
          </CModal>
        </CCol>
      </CCardBody>
    </CCard>
  )
}

export default Register
