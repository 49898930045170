import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField, handleOnSubmit } from 'src/helpers'
import { CButton } from '@coreui/react'
import useToast from 'src/hooks/useToast'
import './styles.scss'
import { UI } from 'src/components'

const DatosProyecto = ({ projectData, id }) => {
  const [editing, setEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [datosProyecto, setDatosProyecto] = useState([])
  const [auxDatosProyecto, setAuxDatosProyecto] = useState([])

  const [loading, setLoading] = useState(false)

  const { Toaster, addToast } = useToast()

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
  }

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = async (data) => {
    setLoading(true)
    const result = await handleOnSubmit(
      projectData,
      data,
      editing,
      editedData,
      datosProyecto,
      setDatosProyecto,
      setEditing,
      setEditedData,
      'lytData',
      id,
      'lyt',
    )

    if (result) {
      addToast({
        message: result.data.message,
        color: result.status === 200 ? 'success' : 'danger',
      })
    }
    setLoading(false)
  }

  // Seteo de la informacion del cliente recuperada por servicio
  const setProjectData = () => {
    if (projectData) {
      setDatosProyecto([
        {
          label: 'Nombre del proyecto',
          value: projectData.project_name || null,
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,150}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Nombre del proyecto inválido',
        },
        {
          label: 'Cantidad de inmuebles',
          value: projectData.property_quantity || null,
          type: 'number',
          pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
          errorMessage: 'Cantidad de inmuebles inválida',
        },
        {
          label: 'Numero de cada unidad y torre',
          value: projectData.unit_tower_number || null,
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,50}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Numero de cada unidad y torre inválida',
        },
        {
          label: 'Ubicación del proyecto',
          value: projectData.project_location || null,
          type: 'text',
          pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,150}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
          errorMessage: 'Ubicación del proyecto inválida',
        },
        {
          label: 'Monto total en UF (incluyendo bono pie)',
          value: projectData.total_amount_uf || null,
          type: 'text',
          pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
          formatPrice: true,
          errorMessage: 'Monto total en UF inválido',
        },
        {
          label: 'Financiamiento por dpto (%)',
          value: projectData.apartment_financing || null,
          type: 'number',
          pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
          errorMessage: 'Financiamiento por dpto (%) inválido',
        },
        {
          label: 'Pie por dpto (sin considerar bono pie) en UF o CLP',
          value: projectData.apartment_down_payment_uf || null,
          type: 'text',
          pattern: /^[0-9,]{0,50}$/, // 50 dígitos numéricos
          formatPrice: true,
          errorMessage: 'Pie por dpto. inválido',
        },
        {
          label: '% Bono pie',
          value: projectData.down_payment_bonus || null,
          type: 'number',
          pattern: /^[0-9]{0,50}$/, // 50 dígitos numéricos
          errorMessage: '% Bono pie inválido',
        },
        {
          label: 'Fecha de escritura (o tentativa)',
          value: projectData.structuring_date || null,
          type: 'date',
          errorMessage: 'Fecha de escritura (o tentativa) inválida',
          legalAge: true,
        },
      ])
    }
  }

  const handleCancelEditing = () => {
    setEditing(false)
    setDatosProyecto(auxDatosProyecto)
    auxDatosProyecto.forEach((item) => {
      const fieldName = item.label?.toLowerCase()
      setValue(fieldName, item.value)
      clearErrors(fieldName)
    })
  }

  useEffect(() => {
    setProjectData()
  }, [projectData])

  useEffect(() => {
    if (editing) {
      // Se guardan en estado auxiliar los datos previos a editar
      setAuxDatosProyecto(datosProyecto)
      // Focus en el primer input para ayudar al usuario
      document.getElementsByTagName('input')[0].focus()
    }
  }, [editing])

  return (
    <React.Fragment>
      {Toaster}

      {loading && <UI.Loading />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
          {datosProyecto.map((item, index) =>
            renderField(item, index, control, editing, errors, ' del cliente'),
          )}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>{/* Espacio reservado para el botón */}</div>
          {editing && (
            <div className="ml-auto">
              <CButton
                type="button"
                color="danger"
                className="text-white px-4"
                onClick={() => handleCancelEditing()}
              >
                Cancelar
              </CButton>
              <CButton type="submit" color="success" className="text-white px-4 mx-4">
                Aceptar
              </CButton>
            </div>
          )}
        </div>
      </form>
      {!editing && (
        <CButton
          type="button"
          onClick={() => setEditing(true)}
          color="primary"
          className="text-white px-4 mx-4"
          style={{ alignSelf: 'end' }}
        >
          Editar
        </CButton>
      )}
    </React.Fragment>
  )
}

DatosProyecto.propTypes = {
  projectData: PropTypes.object,
  id: PropTypes.string,
}

export default DatosProyecto
