import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { renderField } from 'src/helpers'
import { CButton } from '@coreui/react'
import './styles.scss'

const DatosPersonales = ({ personalLYTData, setPersonaLYTData, setActiveTab }) => {
  const datosPersonales = [
    {
      label: 'Nombre',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Nombre inválido',
    },
    {
      label: 'Apellido',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Apellido inválido',
    },
    {
      label: 'RUT',
      value: '',
      type: 'text',
      errorMessage: 'RUT inválido (formato XX.XXX.XXX-X)',
    },
    {
      label: 'Pais',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,100}$/, // De 3 a 30 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Pais inválida',
    },
    {
      label: 'Estado Civil',
      value: '',
      type: 'select',
      options: [
        { value: 'Soltero', label: 'Soltero' },
        { value: 'Casado', label: 'Casado' },
        { value: 'Divorciado', label: 'Divorciado' },
        { value: 'Viudo', label: 'Viudo' },
      ],
    },
    {
      label: 'Profesión',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\s']{1,100}$/, // De 3 a 50 caracteres alfabéticos, incluyendo espacios y apóstrofes
      errorMessage: 'Profesión inválida',
    },
    {
      label: 'Domicilio',
      value: '',
      type: 'text',
      pattern: /^[a-zA-ZÀ-ÿ\d\s']{1,100}$/,
      errorMessage: 'Domicilio inválido',
    },
    {
      label: 'Email',
      value: '',
      type: 'text',
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      // pattern: /^[a-zA-Z0-9._%+\-\u00F1]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: 'Email inválido',
    },
    {
      label: 'Teléfono',
      value: '',
      type: 'phone',
      // pattern: /^\d{11}$/, // 11 numeros
      errorMessage: 'Debe ingresar un número telefónico válido (EJ: +XX XXX XXX XXX)',
      allowNull: true,
    },
  ]

  const formOptions = {
    mode: 'onBlur', // Validaciones en el blur
    defaultValues: personalLYTData,
  }

  const {
    control,
    handleSubmit,
    getValues,
    reset, // Agrega reset aquí
    formState: { errors },
  } = useForm(formOptions)

  const onSubmit = () => {
    // Comprueba si hay errores en el formulario
    const formHasErrors = Object.keys(errors).length > 0

    if (!formHasErrors) {
      // Obtiene los valores actuales del formulario
      const formValues = getValues()
      setPersonaLYTData(formValues)

      // Cambia a la siguiente pestaña
      setActiveTab(1)
    }
  }

  useEffect(() => {
    reset(personalLYTData)
  }, [personalLYTData, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-wrap justify-content-start" style={{ gap: '1rem' }}>
        {datosPersonales.map((item, index) =>
          renderField(item, index, control, true, errors, ' del cliente'),
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div></div>
        <div className="ml-auto">
          <CButton type="submit" color="primary" className="text-white px-4 mx-4">
            Siguiente
          </CButton>
        </div>
      </div>
    </form>
  )
}

DatosPersonales.propTypes = {
  personalLYTData: PropTypes.object.isRequired,
  setPersonaLYTData: PropTypes.func.isRequired,
  setShowFinalAlert: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default DatosPersonales
