import {
  axiosInstance,
  clientsAxiosInstance,
  reservationAxiosInstance,
  indicatorsAxiosInstance,
  taxAxiosInstance,
  metricsAxiosInstance,
  simulatorAxiosInstance,
  rbacAxiosInstance,
  alternativeClientsAxiosInstance,
  userProfileAxiosInstance,
  maintainerAxiosInstance,
  flokzuAxiosInstance,
  cesionesAxiosInstance,
  errorNotificationAxiosInstance,
  homeAxiosInstance,
  fracAxiosInstance,
} from './config'

// VISTA COMERCIAL

export const getCatalogo = () => {
  return axiosInstance.get('/catalogo')
}

export const getTop = () => {
  return axiosInstance.get('/catalogo/top10')
}

export const getVpOportunidades = () => {
  return axiosInstance.get('/catalogo/vpoportunidades')
}

export const getSemiNew = () => {
  return axiosInstance.get('/catalogo/brokerage')
}

export const getPrivateSales = () => {
  return axiosInstance.get('/catalogo/vpbrokerage')
}

export const getLand = () => {
  return axiosInstance.get('/catalogo/terrenos')
}

export const getEeuu = () => {
  return axiosInstance.get('/catalogo/eeuu')
}

export const searchProjects = (params) => {
  return axiosInstance.post('/catalogo', params)
}

export const searchSemiNew = (params) => {
  return axiosInstance.post('/catalogo/brokerage', params)
}

export const searchLand = (params) => {
  return axiosInstance.post('/catalogo/terrenos', params)
}

export const searchEeuu = (params) => {
  return axiosInstance.post('/catalogo/eeuu', params)
}

export const searchPrivateSales = (params) => {
  return axiosInstance.post('/catalogo/vpbrokerage', params)
}

export const getComunas = () => {
  return axiosInstance.get('/filtros/comunas')
}

export const getRegiones = () => {
  return axiosInstance.get('/filtros/regiones')
}

export const getComunasEeuu = () => {
  return axiosInstance.get('/filtros/eeuu_comunas')
}

export const getRegionesEeuu = () => {
  return axiosInstance.get('/filtros/eeuu_regiones')
}

export const getPrivateSalesComuna = () => {
  return axiosInstance.get('/filtros/ventaprivada_comunas')
}

export const getPrivateSalesRegion = () => {
  return axiosInstance.get('/filtros/ventaprivada_regiones')
}

export const getTipoAprobacion = () => {
  return axiosInstance.get('/filtros/aprobaciones')
}

export const getTipoEntrega = () => {
  return axiosInstance.get('/filtros/entrega')
}

export const getBaños = () => {
  return axiosInstance.get('/filtros/banos')
}

export const getDormitorios = () => {
  return axiosInstance.get('/filtros/dormitorios')
}

export const getProveedores = () => {
  return axiosInstance.get('/filtros/proveedores')
}

export const getOrdenar = () => {
  return axiosInstance.get('/filtros/ordenar')
}

export const getBonopie = () => {
  return axiosInstance.get('/filtros/bonopie')
}

export const getVistaComercial = (id) => {
  return axiosInstance.get(`/catalogo/vistacomercial/${id}`)
}

export const getStock = (id) => {
  return axiosInstance.get(`/catalogo/stock/${id}`)
}

export const getPerfilFinanciero = (params) => {
  return axiosInstance.get(`/api_calculos_financieros/${params}`)
}

export const getFilterProjects = (inmobiliariaId) => {
  return axiosInstance.get(`filtros/getProjects/${inmobiliariaId}`)
}

export const getFilterUnits = (projectId) => {
  return axiosInstance.get(`catalogo/stock/${projectId}`)
}

// CLIENTES

export const getClientData = (params) => {
  return clientsAxiosInstance.get(`Prod/getUserData`, {
    params,
  })
}

export const getAllUserData = (params) => {
  return clientsAxiosInstance.get('Prod/getAllUsersData', { params })
}

export const updateClient = (body, uid, clientType) => {
  return clientsAxiosInstance.put(
    `Prod/updateUsersAndClients?uuid=${uid}&client_type=${clientType}`,
    body,
  )
}

export const registerClient = (body, userEmail, clientType) => {
  return clientsAxiosInstance.post(
    `Prod/createUserAndClient?email=${userEmail}&client_type=${clientType}`,
    body,
  )
}

export const getUsersByBroker = (params) => {
  return clientsAxiosInstance.get('Prod/getAllUsersByBroker', { params })
}

export const getPDF = (params) => {
  return clientsAxiosInstance.get('Prod/generatePDF', { params })
}

export const getDocs = (params) => {
  return clientsAxiosInstance.get('Prod/getUserDocuments', { params })
}

export const downloadDoc = (params) => {
  return clientsAxiosInstance.get('Prod/downloadDocument', { params })
}

export const deleteDoc = (params) => {
  return clientsAxiosInstance.delete('Prod/deleteDocument', { params })
}

export const postDocs = (id, formData) => {
  return alternativeClientsAxiosInstance.post('clientFolder', formData, {
    params: {
      uuid: id,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// SIMULADOR

export const getSimulation = (body) => {
  return simulatorAxiosInstance.post('/Prod/api/simulateCredit/', body)
}

// LEGAL TRIBUTARIO

export const getLegal = (body) => {
  return taxAxiosInstance.post('/api/tax-legal', body)
}

// RESERVAS

export const getReservations = (params) => {
  return reservationAxiosInstance.get('api/reservations', { params })
}

export const createReservation = (body) => {
  return reservationAxiosInstance.post('api/reservation', body)
}

export const completeReservation = (body) => {
  return reservationAxiosInstance.post('api/reservation-difference', body)
}

export const getReservation = (reservationId) => {
  return reservationAxiosInstance.get(`api/reservation/${reservationId}`)
}

export const updateReservation = (reservationId, body) => {
  return reservationAxiosInstance.put(`api/reservation/${reservationId}`, body)
}

// INDICATORS

export const getIndicators = () => {
  return indicatorsAxiosInstance.get('Prod/api/indicators')
}

// RBAC

export const getRolesAndModules = (params) => {
  return rbacAxiosInstance.get('login', { params })
}

// METAS Y TOP

export const getHomePanels = () => {
  return homeAxiosInstance.get('homepanels')
}

export const getFracPublication = () => {
  return fracAxiosInstance.get('publication/read')
}

export const getRanking = () => {
  return metricsAxiosInstance.get('/Prod/getTablaPrincipal')
}

export const getMetas = () => {
  return metricsAxiosInstance.get('/Prod/getTablaMetas')
}

export const getTablaModulos = () => {
  return metricsAxiosInstance.get('/Prod/getTablaModulos')
}

export const getTablaModulo01 = () => {
  return metricsAxiosInstance.get('/Prod/getTablaModulo01')
}

export const getTablaModulo02 = () => {
  return metricsAxiosInstance.get('/Prod/getTablaModulo02')
}

// USER PROFILE

export const getUserProfile = (params) => {
  return userProfileAxiosInstance.get('/userProfile', { params })
}

export const updateUserProfile = (body, propital_email) => {
  return userProfileAxiosInstance.post(`/userProfile?propital_email=${propital_email}`, body)
}

// MANTENEDORES

export const updateFields = (id, body) => {
  return maintainerAxiosInstance.put(`/api/maintainer/${id}`, body)
}

// FLOKZU

export const getProjectInfo = () => {
  return flokzuAxiosInstance.get('projectInformation')
}

export const getBrokers = () => {
  return flokzuAxiosInstance.get('brokerInformation')
}

// CESIONES

export const getCesiones = (params) => {
  return cesionesAxiosInstance.get('/cesiones', { params })
}

// ERROR NOTIFICATION

export const sendErrorNotification = (body) => {
  return errorNotificationAxiosInstance.post('', body)
}
