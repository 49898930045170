import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import Hotjar from '@hotjar/browser'
import packageJson from '../../package.json'

const userInfoApi = 'https://www.googleapis.com/oauth2/v1/userinfo?access_token='

const useAuth = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const session = JSON.parse(localStorage.getItem('userSession'))

  const redirect = (path) => window.location.replace(path)

  const validateSession = () => {
    const isExpired = session && new Date(session.expirationDate) <= new Date()
    if (!session || isExpired) {
      localStorage.clear()
      return redirect('/#/login')
    }
  }

  const validateVersion = () => {
    const currentVersion = packageJson.version
    const cachedVersion = session?.currentVersion
    if (cachedVersion !== currentVersion && !window.location.href.includes('amplifyapp')) {
      logout()
    }
  }

  useEffect(() => {
    validateSession()
    validateVersion()
  }, [session])

  const getProfile = (response) => {
    axios
      .get(`${userInfoApi}${response.access_token}`, {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
          Accept: 'application/json',
        },
      })
      .then((res) => {
        const userSession = res.data
        const expirationDate = new Date()
        userSession.currentVersion = packageJson.version
        expirationDate.setHours(24, 0, 0, 0)
        userSession.expirationDate = expirationDate
        localStorage.setItem('userSession', JSON.stringify(userSession))
        Hotjar.identify(userSession.id, {
          name: userSession.name,
          email: userSession.email,
        })
        Hotjar.event('login')
        return redirect('/')
      })
      .catch((err) => console.error(err))
  }

  const devLogin = () => {
    let devSession = {
      id: Infinity,
      email: 'user@propital.com',
      name: `Bicho #${Math.floor(Math.random() * 100) + 1}`,
      picture: 'https://i.imgur.com/Z1NlVkr.png',
    }
    const expirationDate = new Date()
    expirationDate.setHours(24, 0, 0, 0)
    devSession.expirationDate = expirationDate
    localStorage.setItem('userSession', JSON.stringify(devSession))
    return redirect('/')
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => getProfile(codeResponse),
    onError: (error) => console.error('Login Failed:', error),
  })

  const logout = () => {
    googleLogout()
    localStorage.clear()
    dispatch({ type: 'set', user: null })
    return redirect('/#/login')
  }

  return {
    user,
    login,
    logout,
    devLogin,
  }
}

export default useAuth
