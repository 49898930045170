import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { handleMarker, addStreetLayer } from './constants'
import 'leaflet/dist/leaflet.css'
import './styles.css'

// El TileLayer es el mapa que se muestra en el fondo
const tileLayerUrl = 'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token='
const streetsLayerUrl = 'https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png'

// El token es necesario para poder usar el mapa
// TODO: Propital debería crear una cuenta en Jawg y obtener su propio token para ponerlo en el .env
const JAWG_TOKEN = 'HhYVLFhdkAWpWSPkHI18lN7RcETs22GMa0DLMx5QOsYyDkxY00sj6Ahx5aBMzySf'

const Map = ({
  center = { lat: -33.4489, lng: -70.6693 },
  markers = [{ lat: -33.4489, lng: -70.6693 }],
  height = '600px',
  popUpContent = <div>Propital</div>,
  scrollWheelZoom = true,
  zoom = 12.5,
}) => {
  const [showStreets, setShowStreets] = useState(false)

  const style = {
    height,
    width: '100%',
    borderRadius: '8px',
    position: 'relative',
  }

  return (
    <MapContainer
      center={[center.lat, center.lng]}
      scrollWheelZoom={scrollWheelZoom}
      style={style}
      zoom={zoom}
    >
      {addStreetLayer(showStreets, setShowStreets)}
      <TileLayer url={`${tileLayerUrl}${JAWG_TOKEN}`} />
      {showStreets && <TileLayer url={`${streetsLayerUrl}`} />}
      {markers &&
        markers.map((marker, index) => (
          <Marker icon={handleMarker()} key={index} position={[marker.lat, marker.lng]}>
            <Popup>{popUpContent}</Popup>
          </Marker>
        ))}
    </MapContainer>
  )
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ),
  height: PropTypes.string,
  popUpContent: PropTypes.node,
  scrollWheelZoom: PropTypes.bool,
  zoom: PropTypes.number,
}

export default Map
